import React, { useState, useEffect } from "react";
import Navbar from "../../component/navBar/navBar";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { TextField } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useUser } from "../../userContext";
import { Box, LinearProgress } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import FilterListIcon from "@mui/icons-material/FilterList";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid } from "@mui/x-data-grid";
import "./style.scss";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


const ApprovalTable = () => {
  const [approvedRequests, setApprovedRequests] = useState([]);
  const [projectNames, setProjectNames] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingPageChange, setLoadingPageChange] = useState(false);
  const rowPerPage = 10;
  const { user } = useUser();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState("");
  const navigate = useNavigate();
  const [transactionExists, setTransactionExists] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  

  useEffect(() => {
    fetchApprovedRequests();
    fetchHeadTransactions();
  }, [currentPage, user]);

  const fetchHeadTransactions = async () => {
    try {
      const headTransactionsResponse = await axios.get(
        `${process.env.REACT_APP_API}/transaction/getHeadTransactions`
      );

      const transactions = headTransactionsResponse.data.reduce(
        (acc, transaction) => ({
          ...acc,
          [transaction.requestID]: transaction.status,
        }),
        {}
      );

      setTransactionExists(transactions);
    } catch (error) {
      console.error("Error fetching head transactions:", error);
      toast.error("Error fetching head transactions");
    }
  };

  const fetchApprovedRequests = async () => {
    try {
      setLoading(true);

      let apiEndpoint;

      const hasPermission19 = user.permissions.includes(19);
      const hasPermission1 = user.permissions.includes(1);

      if (hasPermission19 || hasPermission1) {
        apiEndpoint = `${process.env.REACT_APP_API}/cashRequest/getAllApprovedCashAdvanceRequests`;
      } else {
        apiEndpoint = `${process.env.REACT_APP_API}/cashRequest/getAllApprovedCashAdvanceRequests/${user.id}`;
      }

      const response = await axios.get(apiEndpoint);

      setApprovedRequests(Array.isArray(response.data) ? response.data : []);

      const projectResponse = await axios.get(
        `${process.env.REACT_APP_API}/project/getAllProjects`
      );
      const projects = projectResponse.data.reduce(
        (acc, project) => ({
          ...acc,
          [project.projectID]: project.projectName,
        }),
        {}
      );
      setProjectNames(projects);
    } catch (error) {
      console.error("Error fetching approved requests:", error);
      toast.error("Error fetching approved requests");
    } finally {
      setLoading(false);
    }
  };

  const formatTotalCost = (cost) => {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(cost);
  };

  const handlePageChange = async ({ selected }) => {
    try {
      setLoadingPageChange(true);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setCurrentPage(selected);
    } finally {
      setLoadingPageChange(false);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handlePaymentStatusChange = (event) => {
    setSelectedPaymentStatus(event.target.value);
  };

  const handleViewClick = (requestID) => {
    navigate(`/cashAdvance/approval/viewRequest/${requestID}`);
  };

  
  const handleAddTransaction = (projectID, requestID) => {
    const transactionExistsForRequest = transactionExists[requestID];
  
    if (transactionExistsForRequest) {
      if (transactionExistsForRequest === "Rejected") {
        navigate(`/newTransaction/${projectID}/${requestID}`);
      } else {
        Swal.fire({
          icon: 'info',
          title:"Info!",
          text: 'Transaction already exists for this request',
        });
      }
    } else {
      navigate(`/newTransaction/${projectID}/${requestID}`);
    }
  };
  

  const paymentStatusFilter = (request) => {
    return (
      selectedPaymentStatus === "" ||
      request.paymentStatus.toLowerCase() ===
        selectedPaymentStatus.toLowerCase()
    );
  };

  const filteredRequests = approvedRequests.filter((request) => {
    return (
      ((projectNames[request.projectID] &&
        projectNames[request.projectID]
          .toLowerCase()
          .includes(searchTerm.toLowerCase())) ||
        (request.officerName &&
          request.officerName
            .toLowerCase()
            .includes(searchTerm.toLowerCase())) ||
        (request.reason &&
          request.reason.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (request.totalAmount &&
          request.totalAmount
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase())) ||
        (request.requestStatus &&
          request.requestStatus
            .toLowerCase()
            .includes(searchTerm.toLowerCase())) ||
        (request.paymentStatus &&
          request.paymentStatus
            .toLowerCase()
            .includes(searchTerm.toLowerCase())) ||
        (request.requiredDate &&
          request.requiredDate
            .toLowerCase()
            .includes(searchTerm.toLowerCase())) ||
        (request.requestID &&
          request.requestID
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase()))) &&
      paymentStatusFilter(request) &&
      (!startDate || new Date(request.requiredDate) >= startDate) &&
      (!endDate || new Date(request.requiredDate) <= endDate)
    );
  });

  const handleDateFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDateFilter = () => {
    setAnchorEl(null);
  };

  const createTransactionAccess = user.permissions.some(
    (permission) => permission === 1 || permission === 26
  );

  const offset = currentPage * rowPerPage;
  const paginatedRequests = filteredRequests
    .slice(offset, offset + rowPerPage)
    .map((request) => ({
      id: request.requestID, 
      ...request,
    }));

  const columns = [
    { field: "requestID", headerName: "Request ID", flex: 1 },
    {
      field: "projectID",
      headerName: "Project Name",
      flex: 2,
      renderCell: (params) => (
        <>
        <Tooltip title={projectNames[params.row.projectID] || "Project Name"}>
        {projectNames[params.row.projectID] || "Project Name"}</Tooltip></>
      ),
    },
    { field: "officerName", headerName: "Officer Name", flex: 2 },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      flex: 1.5,
      align: 'right',
      renderCell: (params) => (
        <><Tooltip title={formatTotalCost(params.row.totalAmount / 100)}>{formatTotalCost(params.row.totalAmount / 100)}</Tooltip></>
      ),
    },
    {
      field: "requestStatus",
      headerName: "Request Status",
      flex: 1,
      align:"center",
      renderCell: (params) => (
        <>
          {params.row.requestStatus === "Approval" ? "Approved" : params.row.requestStatus}
        </>
      ),
    },
    {
      field: "paymentStatus",
      headerName: "Payment",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.row.paymentStatus}>
        <span
          className={`${
            params.row.paymentStatus === "Unpaid"
              ? "text-red-500"
              : "text-green-500"
          }`}
        >
          {params.row.paymentStatus}
        </span>
        </Tooltip>
      ),
    },
    {
      field: "requiredDate",
      headerName: "Required Date",
      flex: 1.5,
    },
    {
      field: "viewButton",
      headerName: "",
      flex: 0.5,
      renderCell: (params) => (
        <Tooltip title={"Approved info"}>
          <VisibilityIcon
            className="text-[orange] p-1 cursor-pointer"
            onClick={() => handleViewClick(params.row.requestID)}
            style={{
              width: "20px",
              height: "20px",
            }}
          />
        </Tooltip>
      ),
    },
    {
      field: "addTransactionButton",
      headerName: "",
      flex: 0.5,
      renderCell: (params) => (
        <Tooltip title={"Add transactions"}>
          {createTransactionAccess && (
            <AddIcon
              className={`p-1 ${
                params.row.paymentStatus === "Unpaid"
                  ? "cursor-not-allowed opacity-20"
                  : params.row.paymentStatus === "Paid"
                  ? "cursor-pointer text-blue-700"
                  : ""
              }`}
              onClick={() =>
                params.row.paymentStatus === "Paid" &&
                handleAddTransaction(params.row.projectID, params.row.requestID)
              }
              style={{
                width: "25px",
                height: "25px",
              }}
            />
          )}
        </Tooltip>
      ),
    },
  ];
  const getRowId = (row) => row.id;
  const getRowHeight = () => 39;
  
  

  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div className="userTableContainer w-[97%] mt-5 ml-5 mr-5 ">
        {(loading || loadingPageChange) && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "60%",
              transform: "translate(-50%, -50%)",
            }}
            className="z-50 bg-transparent"
          >
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
            <div className="mt-2">Loading...</div>
          </div>
        )}
        <div className=" shadow-md border-b-2">
          <div className="flex items-center justify-between p-2">
            <TextField
              type="text"
              placeholder="Search..."
              className="border border-gray-300 rounded px-3 py-1 w-[50%]"
              inputProps={{
                style: {
                  height: "23px",
                  padding: "5px",
                  fontSize: "13px",
                },
              }}
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <Tooltip title={"Date filter"}>
              <FilterListIcon
                onClick={handleDateFilterClick}
                className="mb-4 lg:mb-0 relative ml-[35%] cursor-pointer items-end justify-between"
              />
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseDateFilter}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem>
                <div className="p-4 w-72">
                  <div className="mb-4">
                    <label className="block text-xs font-semibold text-gray-700 mb-1">
                      Start Date
                    </label>
                    <input
                      type="text"
                      placeholder="Start Date"
                      className="border border-gray-300 rounded px-3 py-2 w-full focus:outline-none focus:border-blue-500 h-7 text-xs"
                      ref={(startDateInput) => {
                        if (startDateInput) {
                          flatpickr(startDateInput, {
                            onChange: (selectedDates) => {
                              setStartDate(selectedDates[0]);
                            },
                          });
                        }
                      }}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-xs font-semibold text-gray-700 mb-1">
                      End Date
                    </label>
                    <input
                      type="text"
                      placeholder="End Date"
                      className="border border-gray-300 rounded px-3 py-2 w-full focus:outline-none focus:border-blue-500 h-7 text-xs"
                      ref={(endDateInput) => {
                        if (endDateInput) {
                          flatpickr(endDateInput, {
                            onChange: (selectedDates) => {
                              setEndDate(selectedDates[0]);
                            },
                          });
                        }
                      }}
                    />
                  </div>
                  <p className="text-xs text-gray-600">
                    Select the start and end dates for filtering.
                  </p>
                </div>
              </MenuItem>
            </Menu>
            <select
              className="border border-gray-300 rounded px-3 py-1 text-xs"
              value={selectedPaymentStatus}
              onChange={handlePaymentStatusChange}
            >
              <option value="">All </option>
              <option value="Paid">Paid</option>
              <option value="Unpaid">Unpaid</option>
            </select>
          </div>
          <div style={{ height: "100%", width: "100%" }}>
            <DataGrid
              rows={paginatedRequests}
              columns={columns.map((column) => ({
                ...column,
                headerClassName: 'custom-header',
                cellClassName: 'custom-cell',
              }))}
              pageSize={rowPerPage}
              page={currentPage}
              pagination={false}
              pageSizeOptions={[]}
              disablePrevButton={true} 
              disableNextButton={true}
              hideFooter
              getRowId={getRowId}
              getRowHeight={getRowHeight}
             
            />
          </div>
        </div>
        <div className="pagination-container mb-5">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={Math.ceil(filteredRequests.length / rowPerPage)}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
            onPageChange={handlePageChange}
            containerClassName="pagination"
            activeClassName="active"
          />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ApprovalTable;
