import React from "react";
import "./transactionstyle.scss";
import Sidebar from "../../component/sidebar/sidebar";
import RejectedTransactionsTable from "../../component/transaction/rejectedTransactionsTable";

const RejectedTransactions = () => {
  return (
    <div className="transactions">
      <Sidebar />
      <div className="transactionContainer">
        <RejectedTransactionsTable />
      </div>
    </div>
  );
};
export default RejectedTransactions;
