import React, { useState } from "react";
//import "./newCostTypeStyle.scss";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


const NewCostType = (props) => {
  const navigate = useNavigate();
  const[costTypeError,setCostTypeError]=useState("");
  const { onClose, setSuccess, costType } = props;
  const [formData, setFormData] = useState(() => {
    if (costType) {
      return {
        costID: costType.costType || "",
        costType: costType.costType || "",
      };
    }
    return {
      costID: "",
      costType: "",
    };
  });

  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "costType") {
      const allowedCharactersRegex = /^[a-zA-Z0-9., ]*$/;
      if (!allowedCharactersRegex.test(value)) {
        toast.error("Invalid characters.");
        return;
      }
      if (name === "costType") {
        if (value.length < 4) {
          setFormData({
            ...formData,
            [name]: value,
          });
        setCostTypeError(
            `Cost type must be at least ${4} characters long.`
          );
          return;
        } else {
          setCostTypeError("");
        }
      }

      if (value.length > 30) {
        toast.error("Maximum character count exceeded.");
        return;
      }
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setButtonDisabled(true);
      if (formData.costType.length < 4) {
        toast.warning("Cost type must be at least 4 characters long.");
        setButtonDisabled(false);
        return;
      }

      const response = await axios.put(
        `${process.env.REACT_APP_API}/cost/updateCostType/${costType.costID}`,
        formData
      );

      if (response.status === 200) {
        setSuccess(true);
        Swal.fire({
          icon: 'success',
          title:"Success!",
          text: "Cost type updated successfully",
        });
        onClose();
      } else {
        Swal.fire({
          icon: 'error',
          title:"Error!",
          text: "Failed to update cost type",
        });
      }
    } catch (error) {
      console.error("Error updating cost type:", error);
      Swal.fire({
        icon: 'error',
        title:"Error!",
        text: "An error occurred while updating the cost type",
      });
    } finally {
      setButtonDisabled(false);
    }
  };

  return (
    <div className="flex w-full ">
      <div className="w-full p-4">
        <div className="flex">
          <div className="w-1/4"></div>
          <div className="w-3/4">
            <form onSubmit={handleSubmit} className="flex flex-wrap gap-4">
              <div className="w-2/3">
                <TextField
                  id="costType"
                  label="Cost type"
                  variant="outlined"
                  name="costType"
                  required
                  fullWidth
                  value={formData.costType}
                  onChange={handleInputChange}
                />
                {costTypeError && (
                  <span className="errorText text-xs">{costTypeError}</span>
                )}
              </div>
              <div className="w-2/6 flex justify-between">
              <button
                  type="submit"
                  variant="contained"
                  disabled={isButtonDisabled}
                  className="rounded focus:outline-none hover:shadow-md"
                  style={{
                  
                    backgroundColor: isButtonDisabled ? "#808080" : "#111827",
                    color: "white",
                    cursor: isButtonDisabled ? "not-allowed" : "pointer",
                    height:"30px",
                    fontSize:"12px",
                    width:"120px"
                  }}
                >
                  {isButtonDisabled ? "UPDATING..." : "UPDATE"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default NewCostType;
