import React, { useState, useEffect } from "react";
import ProjectChart from "../../component/projectChart/projectChart";
import WarningIcon from "@mui/icons-material/Warning";
import ReactPaginate from "react-paginate";
import { useUser } from "../../userContext";
import { DataGrid } from "@mui/x-data-grid";


export default function ViewProject(props) {
  const { project } = props;
  const [clientName, setClientName] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [additionalTransactions, setAdditionalTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const rowPerPage = 5;
  const { user } = useUser();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/client/getAllClients`)
      .then((response) => response.json())
      .then((clientData) => {
        const matchingClient = clientData.find(
          (client) => client.clientID === project.clientID
        );

        if (matchingClient) {
          setClientName(matchingClient.clientName);
        }
      })
      .catch((error) => {
        console.error("Error fetching client details:", error);
      });
  }, [project.clientID]);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API}/transaction/getAllApprovalTransactionsByProjectID/${project.projectID}`
    )
      .then((response) => response.json())
      .then((transactions) => {
        const sumAmount = transactions.reduce(
          (total, transaction) => total + transaction.totalCost,
          0
        );

        setTotalAmount(sumAmount);
        setAdditionalTransactions(transactions);
      })
      .catch((error) => {
        console.error("Error fetching transactions:", error);
      });
  }, [project.projectID, currentPage, rowPerPage, user]);

  const formatTotalCost = (cost) => {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(cost);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const paginatedData = additionalTransactions.slice(
    currentPage * rowPerPage,
    (currentPage + 1) * rowPerPage
  );

  const additionalTransactionsWithId = paginatedData.map(
    (transaction) => ({
      ...transaction,
      id: transaction.headID, 
    })
  );

  const getRowId = (row) => row.id;
  const getRowHeight = () => 39;

  const isWarning = totalAmount > project.minBudget;
  const isDanger =
    totalAmount > project.maxBudget && totalAmount > project.minBudget;


    const columns=[
      { field: "headID", headerName: "Transaction ID", flex: 0.5 },
      { field: "requestID", headerName: "Request ID", flex: 0.5 },
      {
        field: "createdAt",
        headerName: "Recorded Date",
        flex: 1,
        renderCell: (params) => formatDate(params.value),
      },
      { field: "officerName", headerName: "Officer Name", flex: 1.5 },
      {
        field: "totalCost",
        headerName: "Total Cost LKR",
        flex: 1,
        align:"right",
        renderCell: (params) => formatTotalCost(params.value),
      },
    ]

  return (
    <div
      className={`w-full p-8 bg-gray-100 border border-gray-300 rounded-md `}
    >
      <div className="text-lg font-bold text-[#111827] mb-5 font-serif">
        {project.projectName} project all transactions
      </div>
      <div className="flex items-center justify-between mb-8">
        <div className="w-[100%] ml-10">
          <ProjectChart projectID={project.projectID} />
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300 rounded-md">
          <thead>
            <tr className="bg-gray-200">
              <th className="border px-4 py-2"></th>
              <th className="border px-4 py-2 text-xs font-mono">
                PROJECT DETAILS
              </th>
            </tr>
          </thead>
          <tbody>
            {project.transactions &&
              project.transactions.map((transaction) => (
                <tr key={transaction.transactionID}>
                  <td className="border px-4 py-2">
                    {transaction.transactionID}
                  </td>
                  <td className="border px-4 py-2">{transaction.amount}</td>
                </tr>
              ))}
          </tbody>
          <tfoot>
            <tr>
              <td className="border px-4 py-2 font-semibold text-gray-800 text-xs">
                Project Name
              </td>
              <td className="border px-4 py-2 text-xs">
                {project.projectName}
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 font-semibold text-gray-800 text-xs">
                Project Max Budget
              </td>
              <td className={`border px-4 py-2 text-xs `}>
                LKR {formatTotalCost(project.maxBudget)}
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 font-semibold text-gray-800 text-xs">
                Project Min Budget
              </td>
              <td className={`border px-4 py-2 text-xs `}>
                LKR {formatTotalCost(project.minBudget)}
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 font-semibold text-gray-800 text-xs">
                Client Name
              </td>
              <td className="border px-4 py-2 text-xs">{clientName}</td>
            </tr>
            <tr>
              <td className="border px-4 py-2 font-semibold text-gray-800 text-xs">
                Total Expenses for this Project
              </td>
              <td
                className={`border px-4 py-2 text-xs ${
                  isDanger ? "text-red-600" : isWarning ? "text-yellow-600" : ""
                }`}
              >
                LKR {formatTotalCost(totalAmount)}
              </td>
            </tr>
          </tfoot>
        </table>
        {isWarning && !isDanger && (
          <div className="flex items-center text-yellow-600 mt-4">
            <WarningIcon />
            <span className="ml-2">
              Total transaction amount exceeds the minimum budget.
            </span>
          </div>
        )}
        {isDanger && (
          <div className="flex items-center text-red-600 mt-4">
            <WarningIcon />
            <span className="ml-2">
              Total transaction amount exceeds the maximum budget.
            </span>
          </div>
        )}
      </div>
      <div className="mt-16">
      <DataGrid
        rows={additionalTransactionsWithId}
        columns={columns.map((column) => ({
          ...column,
          headerClassName: "custom-header",
          cellClassName: "custom-cell",
        }))}
        pageSize={rowPerPage}
        page={currentPage}
        pagination={false}
        pageSizeOptions={[]}
        disablePrevButton={true}
        disableNextButton={true}
        hideFooter
        getRowId={getRowId}
        getRowHeight={getRowHeight}
      />
      </div>
      

      <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        breakLabel={"..."}
        pageCount={Math.ceil(additionalTransactions.length / rowPerPage)}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        onPageChange={handlePageChange}
        containerClassName="pagination"
        activeClassName="active"
      />
    </div>
  );
}
