// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home {
  display: flex;
}
.home .homeContainer {
  height: calc(100vh - 0px);
  overflow-y: auto;
  flex: 5 1;
}
.home .homeContainer .widgets, .home .homeContainer .charts {
  display: flex;
  padding: 20px;
  gap: 20px;
}
.home .homeContainer .charts {
  padding: 5px 20px;
}
.home .homeContainer .charts .donutchart {
  box-shadow: 10px 10px 5px -2px rgba(207, 203, 203, 0.75);
  -webkit-box-shadow: 10px 10px 5px -2px rgba(207, 203, 203, 0.75);
  -moz-box-shadow: 10px 10px 5px -2px rgba(207, 203, 203, 0.75);
}
.home .homeContainer .listContainer {
  box-shadow: 10px 10px 5px -2px rgba(207, 203, 203, 0.75);
  -webkit-box-shadow: 10px 10px 5px -2px rgba(207, 203, 203, 0.75);
  -moz-box-shadow: 10px 10px 5px -2px rgba(207, 203, 203, 0.75);
  padding: 20px;
  margin: 20px;
}
.home .homeContainer .listContainer .listTitle {
  margin-bottom: 15px;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/homestyle.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AAAI;EACI,yBAAA;EACA,gBAAA;EAEA,SAAA;AACR;AAAQ;EACI,aAAA;EACA,aAAA;EACA,SAAA;AAEZ;AAAQ;EACI,iBAAA;AAEZ;AADY;EACI,wDAAA;EACJ,gEAAA;EACA,6DAAA;AAGZ;AACQ;EACI,wDAAA;EACA,gEAAA;EACA,6DAAA;EACA,aAAA;EACA,YAAA;AACZ;AACY;EAEI,mBAAA;AAAhB","sourcesContent":[".home{\n    display: flex;\n    .homeContainer{\n        height: calc(100vh - 0px);\n        overflow-y: auto;\n        \n        flex: 5;\n        .widgets,.charts{\n            display: flex;\n            padding: 20px;\n            gap: 20px;\n        }\n        .charts{\n            padding: 5px 20px;\n            .donutchart{\n                box-shadow: 10px 10px 5px -2px rgba(207,203,203,0.75);\n            -webkit-box-shadow: 10px 10px 5px -2px rgba(207,203,203,0.75);\n            -moz-box-shadow: 10px 10px 5px -2px rgba(207,203,203,0.75);\n            \n            }\n        }\n        .listContainer{\n            box-shadow: 10px 10px 5px -2px rgba(207,203,203,0.75);\n            -webkit-box-shadow: 10px 10px 5px -2px rgba(207,203,203,0.75);\n            -moz-box-shadow: 10px 10px 5px -2px rgba(207,203,203,0.75);\n            padding: 20px;\n            margin: 20px;\n            \n            .listTitle{\n                \n                margin-bottom: 15px;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
