import React, { useEffect, useState } from "react";
import Sidebar from "../../component/sidebar/sidebar";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import AsyncSelect from "react-select/async";
import axios from "axios";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


const MAX_DECIMAL_POINTS = 2;

const New = (props) => {
  const navigate = useNavigate();
  const [clientOptions, setClientOptions] = useState([]);
  const [formData, setFormData] = useState({
    projectName: "",
    maxBudget: "",
    minBudget: "",
    client: null,
  });
  const { onClose, setSuccess, rows } = props;
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [projectNameError, setProjectNameError] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "projectName") {
      const allowedCharactersRegex = /^[a-zA-Z0-9./, ]*$/;
      if (!allowedCharactersRegex.test(value)) {
        toast.error("Invalid characters.");
        return;
      }
      if (name === "projectName") {
        if (value.length < 4) {
          setFormData({
            ...formData,
            [name]: value,
          });
          setProjectNameError(
            `Project name must be at least ${4} characters long.`
          );
          return;
        } else {
          setProjectNameError("");
        }
      }

      if (value.length > 30) {
        toast.error("Maximum character count exceeded.");
        return;
      }
    }

    if (name === "maxBudget" || name === "minBudget") {
      const floatValue = parseFloat(value);
      if (floatValue < 0) {
        toast.warning("Budget values cannot be negative");
        return;
      }
      const decimalCount = (value.split(".")[1] || []).length;
      if (decimalCount > MAX_DECIMAL_POINTS) {
        toast.warning(`Maximum ${MAX_DECIMAL_POINTS} decimal points allowed`);
        return;
      }

      if (
        name === "maxBudget" &&
        parseFloat(value) < parseFloat(formData.minBudget)
      ) {
        toast.warning(
          "Max budget should be greater than or equal to Min budget"
        );
        return;
      }
      if (
        name === "minBudget" &&
        parseFloat(value) > parseFloat(formData.maxBudget)
      ) {
        toast.warning("Min budget should be less than or equal to Max budget");
        return;
      }
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const filterClient = (inputValue, clients) => {
    return clients.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadClientOptions = (inputValue, callback) => {
    axios
      .get(`${process.env.REACT_APP_API}/client/getAllClients`)
      .then((response) => {
        const clientData = response.data.map((client) => ({
          value: client.clientID,
          label: client.clientName,
        }));
        callback(filterClient(inputValue, clientData));
      })
      .catch((error) => {
        console.error("Error fetching clients:", error);
      });
  };

  useEffect(() => {
    loadClientOptions("", (options) => {
      setClientOptions(options);
    });
  }, []);

  const handleClientChange = (selectedOption) => {
    setFormData({
      ...formData,
      client: selectedOption,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isButtonDisabled) {
      return;
    }

    setButtonDisabled(true);

    try {
      const dataToSend = {
        projectName: formData.projectName,
        maxBudget: parseFloat(formData.maxBudget),
        minBudget: parseFloat(formData.minBudget),
        client: formData.client ? formData.client.value : null,
      };
      
      if (formData.projectName.length < 4) {
        toast.warning("Project name must be at least 4 characters long.");
        setButtonDisabled(false);
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API}/project/saveProject`,
        dataToSend,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: "Sucess!",
          text:"Project submitted successfully"
        });
        onClose();
        setSuccess();
        setFormData({
          projectName: "",
          maxBudget: "",
          minBudget: "",
          client: null,
        });
        setTimeout(() => {
          window.close();
        }, 2000);
      } else {
        toast.error(`Error: ${response.data.error || "Unknown error"}`);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      Swal.fire({
        icon: 'error',
        title: "Error!",
        text:((error.response.data.error))
      });
      Swal.fire({
        icon: 'error',
        title: "An error occurred while submitting the project",
      });
      // toast.warning(error.response.data.error);
      // toast.error("An error occurred while submitting the project");
    } finally {
      setTimeout(() => {
        setButtonDisabled(false);
      }, 2000);
    }
  };

  return (
    <div className="flex w-full">
      {/* <Sidebar /> */}
      <div className="w-full p-4">
        <div className=" flex justify-end">
          <div className="right w-3/4">
            <form onSubmit={handleSubmit} className="flex flex-wrap gap-4">
              <div className=" w-2/3">
                <TextField
                  id="projectName"
                  label="Project name"
                  variant="outlined"
                  type="text"
                  name="projectName"
                  required
                  fullWidth
                  value={formData.projectName}
                  onChange={handleInputChange}
                />
                {projectNameError && (
                  <span className="errorText text-xs">{projectNameError}</span>
                )}
              </div>
              <div className="w-2/3">
                <TextField
                  id="maxBudget"
                  label="Max budget"
                  variant="outlined"
                  type="number"
                  name="maxBudget"
                  required
                  fullWidth
                  value={formData.maxBudget}
                  onChange={handleInputChange}
                />
              </div>
              <div className="w-2/3">
                <TextField
                  id="minBudget"
                  label="Min budget"
                  variant="outlined"
                  type="number"
                  name="minBudget"
                  required
                  fullWidth
                  value={formData.minBudget}
                  onChange={handleInputChange}
                />
              </div>
              <div className="w-2/3">
                <AsyncSelect
                  required
                  defaultOptions
                  className="selectOption"
                  loadOptions={loadClientOptions}
                  placeholder="Select Client"
                  onChange={handleClientChange}
                  value={formData.client}
                  options={clientOptions}
                />
              </div>
              <div className="w-2/6 ">
              <button
                  type="submit"
                  variant="contained"
                  disabled={isButtonDisabled}
                  className=" rounded focus:outline-none hover:shadow-md"
                  style={{
                  
                    backgroundColor: isButtonDisabled ? "#808080" : "#111827",
                    color: "white",
                    cursor: isButtonDisabled ? "not-allowed" : "pointer",
                    height:"30px",
                    fontSize:"12px",
                    width:"120px"
                  }}
                >
                  {isButtonDisabled ? "SUBMITTING..." : "SUBMIT"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default New;
