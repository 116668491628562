// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list {
  width: 100%;
  display: flex;
}
.list .listContainer {
  flex: 5 1;
  height: calc(100vh - 0px);
  overflow-y: auto;
  box-shadow: 10px 10px 5px -2px rgba(207, 203, 203, 0.75);
  -webkit-box-shadow: 10px 10px 5px -2px rgba(207, 203, 203, 0.75);
  -moz-box-shadow: 10px 10px 5px -2px rgba(207, 203, 203, 0.75);
}`, "",{"version":3,"sources":["webpack://./src/pages/list/liststyle.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;AACJ;AAAI;EACI,SAAA;EACA,yBAAA;EACA,gBAAA;EACA,wDAAA;EACJ,gEAAA;EACA,6DAAA;AAEJ","sourcesContent":[".list{\n    width: 100%;\n    display: flex;\n    .listContainer{\n        flex: 5;\n        height: calc(100vh - 0px);\n        overflow-y: auto;\n        box-shadow: 10px 10px 5px -2px rgba(207,203,203,0.75);\n    -webkit-box-shadow: 10px 10px 5px -2px rgba(207,203,203,0.75);\n    -moz-box-shadow: 10px 10px 5px -2px rgba(207,203,203,0.75);\n    \n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
