import React, { useEffect, useState } from "react";
import {
  Paper,
  TextField,
  Tooltip,
} from "@mui/material";
import ReactPaginate from "react-paginate";
import { Button, Box, LinearProgress } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import { DataGrid } from "@mui/x-data-grid"; // Import DataGrid from @mui/x-data-grid
import { useLocation } from "react-router-dom";
import { useUser } from "../../userContext";
import AddNewOfficerPopup from "../popup/addNewPopup";
import UpdateOfficerPopup from "../popup/updatePopup";
import NewOfficer from "../../pages/new/newOfficer";
import UpdateOfficer from "../../pages/update/officerUpdate";
import { EditRounded } from "@mui/icons-material";
import "./officerTableStyle.scss";

const OfficerTable = () => {
  const columns = [
    { field: "officerName", headerName: "Officer Name", flex: 1 },
    {
      field: "update",
      headerName: "",
      flex: 0.5,
      align: "center",
      renderCell: (params) => (
        <div>
          {updateAccess && (
            <Tooltip title={"Update officer"}>
              <EditRounded
                style={{
                  cursor: "pointer",
                  color: "orange",
                  width: 12,
                  height: 12,
                }}
                onClick={() => handleUpdateOfficerPopup(params.row.officerID)}
              />
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  const [rows, rowchange] = useState([]);
  const [page, pagechange] = useState(0);
  const [rowperpage, rowperpagechange] = useState(10);
  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchNotFound, setSearchNotFound] = useState(false);
  const [openAddNewPopup, setOpenAddNewPopup] = useState(false);
  const { user, setUser } = useUser();
  const [success, setSuccess] = useState("");
  const [openUpdatePopup, setOpenUpdatePopup] = useState(false);
  const [selectedOfficer, setSelectedOfficer] = useState(null);

  const handleAddOfficer = () => {
    setOpenAddNewPopup(true);
  };
  const handleClosePopup = () => {
    setOpenAddNewPopup(false);
  };

  useEffect(() => {
    const fetchOfficers = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API}/officer/getAllOfficers`,
          { params: { page, pageSize: rowperpage } }
        );
        const officers = await response.json();
        rowchange(officers);
        setTimeout(() => {
          setLoading(false);
        }, 1500);
        setSuccess("Officer details submitted successfully");
      } catch (error) {
        console.error("Error fetching officers:", error);
        setLoading(false);
      }
    };

    fetchOfficers();
  }, [page, rowperpage, success]);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const successMessage = searchParams.get("success");

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
    }
  }, [successMessage]);

  useEffect(() => {
    if (searchQuery === "") {
      setSearchNotFound(false);
    }
  }, [searchQuery]);

  const handlechangepage = (selected) => {
    setLoadingText("Loading...");
    setLoadingPage(true);
    setTimeout(() => {
      pagechange(selected.selected);
      setLoadingPage(false);
      setLoadingText("");
    }, 1500);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    pagechange(0);
    setSearchNotFound(false);
  };

  const filteredRows = rows.filter((row) => {
    const values = Object.values(row).map((value) =>
      String(value).toLowerCase()
    );
    return values.some((value) => value.includes(searchQuery.toLowerCase()));
  });

  const offset = page * rowperpage;
  const rowPerPage = rowperpage;

  const displayRows = filteredRows
    .slice(offset, offset + rowPerPage)
    .map((row, index) => ({
      ...row,
      id: index + 1,
    }));

  const handleUpdateOfficerPopup = (officerID) => {
    const officerToUpdate = rows.find((row) => row.officerID === officerID);
    setSelectedOfficer(officerToUpdate);
    setOpenUpdatePopup(true);
  };

  const handleUpdateOfficerClosePopup = () => {
    setOpenUpdatePopup(false);
  };

  const officerButtonAccess = user.permissions.some(
    (permission) => permission === 1 || permission === 8
  );

  const updateAccess = user.permissions.some(
    (permission) => permission === 1 || permission === 10
  );

  return (
    <div className="officerTable">
      <div className="flex flex-col items-center justify-between text-xl font-bold text-gray-500 border-b-4 shadow-md tableTitle lg:flex-row rounded-5">
        <span className="p-1 font-serif">All Officers</span>
      </div>
      <div className="w-full max-w-screen-xl overscroll-x-none mx-auto">
        {loading && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "60%",
              transform: "translate(-50%, -50%)",
            }}
            className="bg-transparent z-50"
          >
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
            <div className="mt-2">Loading...</div>
          </div>
        )}
        {loadingPage && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "60%",
              transform: "translate(-50%, -50%)",
            }}
            className="bg-transparent z-50"
          >
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
            <div>Loading...</div>
          </div>
        )}
        <Paper sx={{ width: "100%" }}>
          <div>
            <div className="flex items-center justify-between p-2">
              <TextField
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={handleSearch}
                className="border border-gray-300 rounded px-3 py-1 w-[50%]"
                inputProps={{ style: { padding: "5px", fontSize: "13px" } }}
              />
              {officerButtonAccess && (
                <Button
                  variant="outlined"
                  onClick={handleAddOfficer}
                  style={{ height: "30px", fontSize: "12px" }}
                >
                  <AddIcon /> Add Officer
                </Button>
              )}
            </div>
          </div>
          <div style={{ height: "100%", width: "100%" }}>
            <DataGrid
              rows={displayRows}
              columns={columns.map((column) => ({
                ...column,
                headerClassName: "custom-header",
                cellClassName: "custom-cell",
              }))}
              pageSize={rowperpage}
              page={page}
              pagination={false}
              pageSizeOptions={[]}
              disablePrevButton={true}
              disableNextButton={true}
              hideFooter
              getRowId={(row) => row.id}
              getRowHeight={() => 39}
            />
          </div>
        </Paper>
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={Math.ceil(filteredRows.length / rowperpage)}
          pageRangeDisplayed={2}
          marginPagesDisplayed={1}
          onPageChange={handlechangepage}
          containerClassName="pagination"
          activeClassName="active"
        />
      </div>
      <ToastContainer />
      <AddNewOfficerPopup
        open={openAddNewPopup}
        setOpen={setOpenAddNewPopup}
        title="New Officer"
      >
        <NewOfficer onClose={handleClosePopup} setSuccess={setSuccess} />
      </AddNewOfficerPopup>
      <UpdateOfficerPopup
        open={openUpdatePopup}
        setOpen={setOpenUpdatePopup}
        title="Update Officer"
      >
        <UpdateOfficer
          onClose={handleUpdateOfficerClosePopup}
          officer={selectedOfficer}
          setSuccess={setSuccess}
        />
      </UpdateOfficerPopup>
    </div>
  );
};

export default OfficerTable;
