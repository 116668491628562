import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../../component/sidebar/sidebar";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import NewUser from "../../pages/user/createNewUser";
import AddNewPopup from "../../component/popup/addNewPopup";
import { ToastContainer } from "react-toastify";
import "./userStyle.scss";
import ReactPaginate from "react-paginate";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { useUser } from "../../userContext";
import Tooltip from "@mui/material/Tooltip";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import PermissionUpdatePopup from "../../component/popup/updatePopup";
import UpdatePermission from "../update/permissionUpdate";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { DataGrid } from "@mui/x-data-grid";

const User = () => {
  const [openPopup, setOpenPopup] = useState(false);
  const [rowsData, setRowsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [success, setSuccess] = useState("");
  const { user } = useUser();
  const [openUpdatePopup, setOpenUpdatePopup] = useState("");
  const [selectedUserPermissions, setSelectedUserPermissions] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [visiblePasswordRowId, setVisiblePasswordRowId] = useState(null);

  useEffect(() => {
    fetchUserTableData();
  }, [success,currentPage, user]);

  const handleAddUser = () => {
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleStatusChange = (params) => {
    const { userName, status } = params.row;

    if (userName === user.username) {
      return;
    }

    const newStatus = status ? "Inactive" : "Active";

    axios
      .put(`${process.env.REACT_APP_API}/user/updateUserStatus/${userName}`, {
        newStatus: newStatus,
      })
      .then(() => {
        const updatedRows = rowsData.map((row) => {
          if (row.userName === userName) {
            return {
              ...row,
              status: !status,
            };
          }
          return row;
        });
        setRowsData(updatedRows);
      })
      .catch((error) => {
        console.error("Error updating user status:", error);
      });
  };

  const fetchUserTableData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/user/allUsers`
      );
      const formattedData = response.data.map((item, index) => ({
        id: index + 1,
        userID: item.userID,
        userName: item.username,
        officerName: item.officerName,
        password: item.password,
        role: item.role,
        status: item.status === "Active",
      }));
      setRowsData(formattedData);
      setTimeout(() => {
        setIsLoading(false);
      }, 1500);
      setSuccess("User added successfully.");
    } catch (error) {
      console.error("Error fetching user data:", error);
      setIsLoading(false);
    }
  };

  const handlePageChange = (params) => {
    setCurrentPage(params.page - 1);
    setIsLoading(true);

    setTimeout(() => {
      setCurrentPage(params.page - 1);
      setIsLoading(false);
    }, 1500);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(0);
  };

  const handleStatusFilter = (event) => {
    setStatusFilter(event.target.value);
    setCurrentPage(0);
  };

  const handleUpdatePermissionOpenPopup = (row) => {
    setSelectedUserId(row.userID);
    setSelectedUserPermissions(row.permissions);
    setOpenUpdatePopup(true);
  };

  const handleUpdatePermissionClosePopup = () => {
    setOpenUpdatePopup(false);
  };

  const filteredRows = rowsData.filter((row) => {
    const matchTerm = row.userName.toLowerCase().includes(searchTerm.toLowerCase());
    const matchOfficer = row.officerName.toLowerCase().includes(searchTerm.toLowerCase());
    const matchStatus = row.status === (statusFilter === "Active");
    return (matchTerm || matchOfficer) && (statusFilter === "" || matchStatus);
  });

  const paginatedData = filteredRows.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

  const getRowId = (row) => row.id;
  const getRowHeight = () => 39;

  const columns = [
    { field: "userName", headerName: "User Name", flex: 1 },
    { field: "officerName", headerName: "Officer Name", flex: 1 },
    // {
    //   field: "password",
    //   headerName: "Password",
    //   flex: 1,
    //   renderCell: (params) => (
    //     <div style={{ position: "relative" }}>
    //       <Tooltip
    //         title={
    //           visiblePasswordRowId === params.id
    //             ? "Hide password"
    //             : "View password"
    //         }
    //       >
    //         {visiblePasswordRowId === params.id ? (
    //           <VisibilityOffIcon
    //             style={{
    //               cursor: "pointer",
    //               width: 15,
    //               height: 15,
    //               color: "gray",
    //               marginLeft: "70px",
    //             }}
    //             onClick={() => handleTogglePasswordVisibility(params.id)}
    //           />
    //         ) : (
    //           <VisibilityIcon
    //             style={{
    //               cursor: "pointer",
    //               width: 15,
    //               height: 15,
    //               color: "blue",
    //               marginLeft: "70px",
    //             }}
    //             onClick={() => handleTogglePasswordVisibility(params.id)}
    //           />
    //         )}
    //       </Tooltip>
    //       {visiblePasswordRowId === params.id && (
    //         <span className="text-xs absolute ml-2 bottom-0">
    //           {params.row.password}
    //         </span>
    //       )}
    //     </div>
    //   ),
    // },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      renderCell: (params) => (
        <FormControlLabel
          control={
            <Tooltip title={"Change status"}>
              <Switch
                checked={params.row.status}
                onChange={() => handleStatusChange(params)}
                disabled={params.row.userName === user.username}
              />
            </Tooltip>
          }
          label={
            <span
              style={{
                position: "absolute",
              }}
              className="text-xs"
            >
              {params.row.status ? "Active" : "Inactive"}
            </span>
          }
        />
      ),
    },
    {
      field: "actions",
      headerName: "Permissions",
      flex: 0.5,
      align:"center",
      renderCell: (params) => (
        <Tooltip title={"Update permissions"}>
          {params.row.userName === user.username ? (
            <ControlPointIcon
              style={{
                cursor: "not-allowed",
                color: "gray",
                width: 14,
                height: 14,
              }}
            />
          ) : (
            <ControlPointIcon
              style={{
                cursor: "pointer",
                color: "orange",
                width: 14,
                height: 14,
              }}
              onClick={() => handleUpdatePermissionOpenPopup(params.row)}
            />
          )}
        </Tooltip>
      ),
    },
  ];

  return (
    <div className="user">
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "60%",
            transform: "translate(-50%, -50%)",
          }}
          className="bg-transparent z-50"
        >
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
          <div className="mt-2">Loading...</div>
        </div>
      )}
      <Sidebar />
      <div className="userContainer">
        <div className="flex flex-col items-center justify-between text-xl font-bold text-gray-500 border-b-4 shadow-md userTitle lg:flex-row rounded-5">
          <span className="p-1 font-serif">Users Profile</span>
        </div>
        <div className="userTableContainer w-[96.5%] max-w-screen-xl overscroll-x-none mx-auto  ml-5">
          <div className=" shadow-md rounded-md">
            <div className="flex items-center justify-between p-2">
              <TextField
                type="text"
                value={searchTerm}
                onChange={handleSearch}
                placeholder="Search..."
                className="border border-gray-300 rounded px-3 py-1 w-[50%]"
                inputProps={{
                  style: {
                    height: "23px",
                    padding: "5px",
                    fontSize: "13px",
                  },
                }}
              />
              <select
                value={statusFilter}
                onChange={handleStatusFilter}
                className="ml-52 border border-gray-300 rounded px-3 py-1 text-xs"
              >
                <option value="">All</option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={handleAddUser}
                style={{ height: "30px", fontSize: "12px" }}
              >
                Add New User
              </Button>
            </div>

            <div style={{ height: "100%", width: "100%" }}>
            <DataGrid
              rows={paginatedData}
              columns={columns.map((column) => ({
                ...column,
                headerClassName: 'custom-header',
                cellClassName: 'custom-cell',
              }))}
              pageSize={itemsPerPage}
              page={currentPage}
              pagination={false}
              pageSizeOptions={[]}
              disablePrevButton={true} 
              disableNextButton={true}
              hideFooter
              getRowId={getRowId}
              getRowHeight={getRowHeight}
             
            />
          </div>
          </div>

          <div className="pagination-container">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={Math.ceil(filteredRows.length / itemsPerPage)}
              pageRangeDisplayed={2}
              marginPagesDisplayed={1}
              onPageChange={handlePageChange}
              containerClassName="pagination"
              activeClassName="active"
            />
          </div>
        </div>
      </div>
      <ToastContainer />
      <AddNewPopup open={openPopup} setOpen={setOpenPopup} title="New User">
        <NewUser onClose={handleClosePopup} setSuccess={setSuccess} rowsData={rowsData} />
      </AddNewPopup>
      <PermissionUpdatePopup
        open={openUpdatePopup}
        setOpen={setOpenUpdatePopup}
        title={"Update Permissions"}
      >
        <UpdatePermission
          onClose={() => handleUpdatePermissionClosePopup()}
          userID={selectedUserId}
          setSuccess={setSuccess}
        />
      </PermissionUpdatePopup>
    </div>
  );
};

export default User;
