import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ReactPaginate from "react-paginate";
import "./costTypeStyle.scss";
import { useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import { toast, ToastContainer } from "react-toastify";
import NewCostType from "../../pages/new/newCostType";
import AddNewCostTypePopup from "../popup/addNewPopup";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { useUser } from "../../userContext";
import LinearProgress from "@mui/material/LinearProgress";
import { EditRounded } from "@mui/icons-material";
import UpdateCostTypePopup from "../popup/updatePopup";
import UpdateCostType from "../../pages/update/costTypeUpdate";
import AddMealPopup from "../popup/addNewPopup";
import AddTransportTypePopup from "../popup/addNewPopup";
import Tooltip from "@mui/material/Tooltip";
import AddnewTransportType from "../../pages/new/newTransportType";
import NewMeal from "../../pages/new/newMealType";
import MealTypeUpdatePopup from "../popup/updatePopup";
import UpdateMeal from "../../pages/update/updateMealType";
import TransportUpdatePopup from "../popup/updatePopup";
import UpdateTransport from "../../pages/update/updateTransportType";

const CostType = () => {
  const columns = [
    { id: "costType", name: "Cost Type" },
    { id: "update", name: "" },
  ];
  const typesColumns = [
    { id: "transportType", name: "Transport Type" },
    { id: "pricePerKm", name: "Price per KM (LKR)" },
    { id: "update", name: "" },
  ];
  const mealColumns = [
    { id: "mealType", name: "Meal" },
    { id: "price", name: "Price (LKR)" },
    { id: "update", name: "" },
  ];

  const [rows, rowchange] = useState([]);
  const [page, pagechange] = useState(0);
  const [rowperpage, rowperpagechange] = useState(10);
  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchNotFound, setSearchNotFound] = useState(false);
  const [openAddNewPopup, setOpenAddNewPopup] = useState(false);
  const { user, setUser } = useUser();
  const [success, setSuccess] = useState("");
  const [openUpdatePopup, setOpenUpdatePopup] = useState(false);
  const [selectedCostType, setSelectedCostType] = useState(null);
  const [openAddNewTransportPopup, setOpenAddNewTransportPopup] =
    useState(false);
  const [openMealPopup, setOpenMealPopup] = useState(false);
  const [transportTypes, setTransportTypes] = useState([]);
  const [mealTypes, setMealTypes] = useState([]);
  const [selectedMealType, setSelectedMealType] = useState(null);
  const [openMealUpdatePopup, setOpenMealUpdatePopup] = useState(false);
  const [openTransportUpdatePopup, setOpenTransportUpdatePopup] =
    useState(false);
  const [selectedTransport, setSelectedTransport] = useState(null);

  const handleClosePopup = () => {
    setOpenAddNewPopup(false);
  };

  const handleAddCostType = () => {
    setOpenAddNewPopup(true);
  };
  const handleAddTransportType = () => {
    setOpenAddNewTransportPopup(true);
  };
  const closeAddTransportType = () => {
    setOpenAddNewTransportPopup(false);
  };
  const handleAddMeal = () => {
    setOpenMealPopup(true);
  };
  const closeAddMeal = () => {
    setOpenMealPopup(false);
  };

  useEffect(() => {
    const fetchCostTypes = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API}/cost/getAllCostTypes`
        );
        const costTypes = await response.json();
        rowchange(costTypes);
        setTimeout(() => {
          setLoading(false);
        }, 1500);
        setSuccess("Cost type added successfully");
      } catch (error) {
        console.error("Error fetching cost types:", error);
        setLoading(false);
      }
    };

    fetchCostTypes();
  }, [success]);

  useEffect(() => {
    const fetchTransportTypes = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/cost/getAllTransportTypes`
        );
        const transportTypes = response.data;
        setTransportTypes(transportTypes);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching transport types:", error);
        setLoading(false);
      }
    };

    fetchTransportTypes();
  }, [success]);

  useEffect(() => {
    const fetchMealTypes = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/cost/getAllMealTypes`
        );
        const mealTypes = response.data;
        setMealTypes(mealTypes);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching transport types:", error);
        setLoading(false);
      }
    };

    fetchMealTypes();
  }, [success]);
 

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const successMessage = searchParams.get("success");

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
    }
  }, [successMessage]);

  useEffect(() => {
    if (searchQuery === "") {
      setSearchNotFound(false);
    }
  }, [searchQuery]);

  const handlechangepage = (selected) => {
    setLoadingText("Loading...");
    pagechange(selected);
    setLoadingPage(true);
    setTimeout(() => {
      pagechange(selected.selected);
      setLoadingPage(false);
      setLoadingText("");
    }, 1500);
  };

  const handleRowsPerPage = (event) => {
    rowperpagechange(+event.target.value);
    pagechange(0);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    pagechange(0);
    setSearchNotFound(false);
    if (event.target.value === "") {
    }
  };

  const filteredRows = rows.filter((row) => {
    const values = Object.values(row).map((value) =>
      String(value).toLowerCase()
    );
    return values.some((value) => value.includes(searchQuery.toLowerCase()));
  });

  const displayRows = searchQuery ? filteredRows : rows;

  const handleUpdateCostTypePopup = (costID) => {
    const costTypeToUpdate = rows.find((row) => row.costID == costID);
    setSelectedCostType(costTypeToUpdate);
    setOpenUpdatePopup(true);
  };
  const handleUpdateCostTypeClosePopup = () => {
    setOpenUpdatePopup(false);
  };
  const handleUpdateMealTypePopup = (typeID) => {
    const mealTypeToUpdate = mealTypes.find((row) => row.typeID == typeID);
    setSelectedMealType(mealTypeToUpdate);
    setOpenMealUpdatePopup(true);
  };

  const handleUpdateMealTypeClosePopup = () => {
    setOpenMealUpdatePopup(false);
  };
  const handleUpdateTransportPopup = (typeID) => {
    const transportToUpdate = transportTypes.find(
      (row) => row.typeID == typeID
    );
    setSelectedTransport(transportToUpdate);
    setOpenTransportUpdatePopup(true);
  };

  const handleUpdateTransportClosePopup = () => {
    setOpenTransportUpdatePopup(false);
  };
  const costTypeButtonAccess = user.permissions.some(
    (permission) => permission === 1 || permission === 11
  );
  const updateAccess = user.permissions.some(
    (permission) => permission === 1 || permission === 13
  );
  const addTransportAccess = user.permissions.some(
    (permission) => permission === 1 || permission === 14
  );
  const updateTransportAccess = user.permissions.some(
    (permission) => permission === 1 || permission === 15
  );
  const addMealAccess = user.permissions.some(
    (permission) => permission === 1 || permission === 16
  );
  const updateMealAccess = user.permissions.some(
    (permission) => permission === 1 || permission === 17
  );

  const formatPrice = (cost) => {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(cost);
  };

  return (
    <div className="costTable ">
      <div className="flex flex-col items-center justify-between text-xl font-bold text-gray-500 border-b-4 shadow-md tableTitle lg:flex-row rounded-5">
        <span className="p-1 font-serif">Cost Types</span>
      </div>
      <div style={{ textAlign: "center" }} className="table w-[100%]">
        {loading && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "60%",
              transform: "translate(-50%, -50%)",
            }}
            className="bg-transparent z-50"
          >
            <LinearProgress />

            <div className="mt-2">Loading...</div>
          </div>
        )}
        {loadingPage && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "60%",
              transform: "translate(-50%, -50%)",
            }}
            className="bg-transparent z-50"
          >
            <LinearProgress />

            <div>Loading...</div>
          </div>
        )}
        <div className="flex">
          <div className="flex-1">
            <Paper sx={{ width: "100%" }}>
              <div>
                <div className="flex items-center justify-between p-2 ">
                  {/* <TextField
                    type="text"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearch}
                    className="border border-gray-300 rounded px-3 py-1 w-[50%]"
                    inputProps={{ style: { padding: "5px" } }}
                  /> */}
                  {costTypeButtonAccess && (
                    <Button
                      variant="outlined"
                      startIcon={<AddIcon />}
                      onClick={handleAddCostType}
                      style={{ height: "30px", fontSize: "12px" }}
                    >
                      Add Cost Type
                    </Button>
                  )}
                </div>
              </div>
              <TableContainer sx={{ maxHeight: "800px" }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          style={{
                            backgroundColor: "#111827",
                            color: "#6f6f74",
                            lineHeight: 0.5,
                            fontFamily:"monospace",
                          }}
                          key={column.id}
                          className="p-2"
                        >
                          {column.name}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <TableCell
                          colSpan={columns.length}
                          align="center"
                          style={{
                            backgroundColor: "transparent",
                            position: "relative",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              bottom: "50%",
                              left: "50%",
                              transform: "translate(-50%, 50%)",
                            }}
                          ></div>
                        </TableCell>
                      </TableRow>
                    ) : displayRows.length === 0 ? (
                      <TableRow>
                        <TableCell
                          colSpan={columns.length}
                          align="center"
                          style={{
                            backgroundColor: "transparent",
                            position: "relative",
                            fontSize:"12px"
                          }}
                        >
                          {/* Search not found */}
                        </TableCell>
                      </TableRow>
                    ) : (
                      displayRows
                        .slice(page * rowperpage, (page + 1) * rowperpage)
                        .map((row, index) => (
                          <TableRow
                            key={row.ProjectID}
                            className="hover:bg-slate-100"
                          >
                            {columns.map((column) => (
                              <TableCell
                                key={column.id}
                                style={{
                                  lineHeight: 0.5,
                                  fontSize:"12px",
                                  textAlign:
                                    column.id === "update" ? "center" : "left",
                                }}
                                className="text-gray-500"
                              >
                                {column.id === "update"
                                  ? updateAccess && (
                                      <Tooltip title={"Update cost type"}>
                                        <EditRounded
                                          style={{
                                            cursor: "pointer",
                                            color: "orange",
                                            width: 12,
                                            height: 12,
                                          }}
                                          onClick={() => {
                                            handleUpdateCostTypePopup(
                                              row.costID
                                            );
                                          }}
                                        />
                                      </Tooltip>
                                    )
                                  : row[column.id]}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
          <div className="flex-1 ml-2 ">
            <Paper sx={{ width: "110%" }}>
              <div>
                <div className="flex items-center justify-between p-2 ">
                  {addTransportAccess && (
                    <Button
                      variant="outlined"
                      startIcon={<AddIcon />}
                      onClick={handleAddTransportType}
                      style={{ height: "30px", fontSize: "12px" }}
                    >
                      ADD TRANSPORT PRICE
                    </Button>
                  )}
                </div>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {typesColumns.map((column) => (
                          <TableCell
                            style={{
                              backgroundColor: "#111827",
                              color: "#6f6f74",
                              lineHeight: 0.5,
                              fontFamily:"monospace",
                              textAlign:
                                column.id === "transportType"
                                  ? "left"
                                  : "right",
                            }}
                            key={column.id}
                            className="p-2"
                          >
                            {column.name}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {transportTypes.map((item) => (
                        <TableRow key={item.typeID} style={{ lineHeight: 0.5, }}className="hover:bg-slate-200">
                          <TableCell style={{ lineHeight: 0.5,fontSize:"12px",lineHeight: 0.5, }}>
                            {item.type}
                          </TableCell>
                          <TableCell
                            style={{ textAlign: "right", lineHeight: 0.5,fontSize:"12px" }}
                          >
                            {formatPrice(item.pricePerMile / 100)}
                          </TableCell>
                          <TableCell style={{lineHeight: 0.5,}}>
                            {updateTransportAccess && (
                              <Tooltip title={"Update transport type"}>
                                <EditRounded
                                  style={{
                                    cursor: "pointer",
                                    color: "orange",
                                    width: 12,
                                    height: 12,
                                  }}
                                  onClick={() => {
                                    handleUpdateTransportPopup(item.typeID);
                                  }}
                                />
                              </Tooltip>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Paper>
          </div>
          <div className="flex-1 ml-10">
            <Paper sx={{ width: "100%" }}>
              <div>
                <div className="flex items-center justify-between p-2 ">
                  {addMealAccess && (
                    <Button
                      variant="outlined"
                      startIcon={<AddIcon />}
                      onClick={handleAddMeal}
                      style={{ height: "30px", fontSize: "12px" }}
                    >
                      ADD Meal price
                    </Button>
                  )}
                </div>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {mealColumns.map((column) => (
                          <TableCell
                            style={{
                              backgroundColor: "#111827",
                              color: "#6f6f74",
                              lineHeight: 0.5,
                              fontFamily:"monospace",
                              textAlign:
                                column.id === "mealType" ? "left" : "right",
                            }}
                            key={column.id}
                            className="p-2"
                          >
                            {column.name}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {mealTypes.map((item) => (
                        <TableRow key={item.typeID}className="hover:bg-slate-200">
                          <TableCell style={{fontSize:"12px",lineHeight:0.5}}>{item.type}</TableCell>
                          <TableCell style={{ textAlign: "right",fontSize:"12px",lineHeight:0.5 }}>
                            {formatPrice(item.price / 100)}
                          </TableCell>
                          <TableCell style={{lineHeight:0.5}}>
                            {updateMealAccess && (
                              <Tooltip title={"Update meal type"}>
                                <EditRounded
                                  style={{
                                    cursor: "pointer",
                                    color: "orange",
                                    width: 12,
                                    height: 12,
                                  }}
                                  onClick={() => {
                                    handleUpdateMealTypePopup(item.typeID);
                                  }}
                                />
                              </Tooltip>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Paper>
          </div>
        </div>

        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={Math.ceil(displayRows.length / rowperpage)}
          pageRangeDisplayed={2}
          marginPagesDisplayed={1}
          onPageChange={handlechangepage}
          containerClassName="pagination"
          activeClassName="active"
        />
      </div>
      <ToastContainer />
      <AddNewCostTypePopup
        open={openAddNewPopup}
        setOpen={setOpenAddNewPopup}
        title="New Cost Type"
      >
        <NewCostType onClose={handleClosePopup} setSuccess={setSuccess} />{" "}
      </AddNewCostTypePopup>
      <UpdateCostTypePopup
        open={openUpdatePopup}
        setOpen={setOpenUpdatePopup}
        title="Update Cost Type"
      >
        <UpdateCostType
          onClose={handleUpdateCostTypeClosePopup}
          costType={selectedCostType}
          setSuccess={setSuccess}
        />
      </UpdateCostTypePopup>
      <AddTransportTypePopup
        open={openAddNewTransportPopup}
        setOpen={setOpenAddNewTransportPopup}
        title="Add Transport Types"
      >
        <AddnewTransportType
          onClose={closeAddTransportType}
          setSuccess={setSuccess}
        />
      </AddTransportTypePopup>
      <AddMealPopup
        open={openMealPopup}
        setOpen={setOpenMealPopup}
        title="Add Meal Types"
      >
        <NewMeal onClose={closeAddMeal} setSuccess={setSuccess} />
      </AddMealPopup>
      <MealTypeUpdatePopup
        open={openMealUpdatePopup}
        setOpen={setOpenMealUpdatePopup}
        title="Update Meal Type"
      >
        <UpdateMeal
          onClose={handleUpdateMealTypeClosePopup}
          mealType={selectedMealType}
          setSuccess={setSuccess}
        />
      </MealTypeUpdatePopup>
      <TransportUpdatePopup
        open={openTransportUpdatePopup}
        setOpen={setOpenTransportUpdatePopup}
        title="Update Transport Type"
      >
        <UpdateTransport
          onClose={handleUpdateTransportClosePopup}
          transport={selectedTransport}
          setSuccess={setSuccess}
        />
      </TransportUpdatePopup>
    </div>
  );
};

export default CostType;
