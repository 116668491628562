// import "./styles.css";
import React, { useState, useEffect } from "react";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid } from "recharts";
import axios from "axios";

const getPath = (x, y, width, height) => {
  return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3}
  ${x + width / 2}, ${y}
  C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}
  Z`;
};

const TriangleBar = (props) => {
  const { fill, x, y, width, height } = props;

  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};

export default function App({ projectID }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const lastYearAndCurrentYearResponse = await axios.get(
          `${process.env.REACT_APP_API}/transaction/approvalCostTypeTotal/${projectID}`
        );

        const lastYearData = lastYearAndCurrentYearResponse.data.map((item) => ({
          costType: item.costTypeName,
          total: item.totalAmount
        }));

        setData(lastYearData);
      } catch (error) {
        console.error("Error fetching total transactions:", error);
      }
    };

    fetchData();
  }, [projectID]);

  const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'red', 'pink'];

  return (
    <BarChart
      width={760}
      height={300}
      data={data}
      margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="costType" />
      <YAxis />
      <Bar
        dataKey="total"
        fill="#8884d8"
        shape={<TriangleBar />}
        label={{ position: 'top' }}
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
        ))}
      </Bar>
    </BarChart>
  );
}
