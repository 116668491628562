import React, { useState, useEffect } from "react";
import Navbar from "../../component/navBar/navBar";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { TextField, Tooltip } from "@mui/material";
import { useUser } from "../../userContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteOutlineTwoToneIcon from "@mui/icons-material/DeleteOutlineTwoTone";
import { RemoveRedEyeRounded } from "@mui/icons-material";
import ViewRequestPopup from "../../component/popup/viewPopup";
import ViewRequest from "../cashAdvance/rejectedInformation";
import { Box, LinearProgress } from "@mui/material";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import FilterListIcon from "@mui/icons-material/FilterList";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid } from "@mui/x-data-grid";
import "./style.scss";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


const RejectedTable = () => {
  const [rejectedRequests, setRejectedRequests] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [projectNames, setProjectNames] = useState({});
  const [rowPerPage] = useState(10);
  const { user } = useUser();
  const [searchTerm, setSearchTerm] = useState("");
  const [permissionToDelete, setPermissionToDelete] = useState(false);
  const [requestToDelete, setRequestToDelete] = useState(null);
  const [openViewPopup, setOpenViewPopup] = useState(false);
  const [selectedRequestView, setSelectedRequestView] = useState(null);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    fetchRejectedRequests();
    fetchProjectNames();
  }, [currentPage, user]);

  const fetchRejectedRequests = async () => {
    try {
      setLoading(true);

      let apiEndpoint;

      const hasPermission21 = user.permissions.includes(21);
      const hasPermission1 = user.permissions.includes(1);

      if (hasPermission21 || hasPermission1) {
        apiEndpoint = `${process.env.REACT_APP_API}/cashRequest/getAllRejectedCashAdvanceRequests`;
      } else {
        apiEndpoint = `${process.env.REACT_APP_API}/cashRequest/getAllRejectedCashAdvanceRequests/${user.id}`;
      }

      const response = await axios.get(apiEndpoint);
      setRejectedRequests(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error("Error fetching rejected requests:", error);
      toast.error("Error fetching rejected requests");
    } finally {
      setLoading(false);
    }
  };

  const fetchProjectNames = async () => {
    try {
      const projectsResponse = await axios.get(
        `${process.env.REACT_APP_API}/project/getAllProjects`
      );

      const projects = projectsResponse.data.reduce(
        (acc, project) => ({
          ...acc,
          [project.projectID]: project.projectName,
        }),
        {}
      );

      setProjectNames(projects);
    } catch (error) {
      console.error("Error fetching project names:", error);
      toast.error("Error fetching project names");
    }
  };


  const showConfirmation = (requestID) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRequest(requestID);
      }
    });
  };
  

  const deleteRequest = async (requestID) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API}/cashRequest/deleteCashAdvanceRequest/${requestID}`
      );
  
      const updatedRequests = rejectedRequests.filter(
        (request) => request.requestID !== requestID
      );
      setRejectedRequests(updatedRequests);
  
      Swal.fire({
        title: 'Deleted!',
        text: 'Request has been deleted.',
        icon: 'success',
        timer: 2000,
        showConfirmButton: false,
      });
    } catch (error) {
      console.error('Error deleting request:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error delete request',
      });
      
    }
  };
  
  const formatTotalCost = (cost) => {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(cost);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredRequests = rejectedRequests.filter((request) => {
    const isMatchingProject =
      projectNames[request.projectID] &&
      projectNames[request.projectID]
        .toLowerCase()
        .includes(searchTerm.toLowerCase());

    const isMatchingOfficer =
      request.officerName &&
      request.officerName.toLowerCase().includes(searchTerm.toLowerCase());

    const isMatchingReason =
      request.reason &&
      request.reason.toLowerCase().includes(searchTerm.toLowerCase());

    const isMatchingTotalAmount =
      request.totalAmount &&
      request.totalAmount
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase());

    const isMatchingRequestStatus =
      request.requestStatus &&
      request.requestStatus.toLowerCase().includes(searchTerm.toLowerCase());

    const isMatchingRequestID =
      request.requestID &&
      request.requestID
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase());

    const isMatchingRequiredDate =
      request.requiredDate &&
      request.requiredDate.toLowerCase().includes(searchTerm.toLowerCase());

    const isWithinDateRange =
      (!startDate || new Date(request.requiredDate) >= startDate) &&
      (!endDate || new Date(request.requiredDate) <= endDate);

    return (
      (isMatchingProject ||
        isMatchingOfficer ||
        isMatchingReason ||
        isMatchingTotalAmount ||
        isMatchingRequestStatus ||
        isMatchingRequestID ||
        isMatchingRequiredDate) &&
      isWithinDateRange
    );
  });

  const handleDateFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDateFilter = () => {
    setAnchorEl(null);
  };

  const deleteAccess = user.permissions.some(
    (permission) => permission === 1 || permission === 24
  );

  const handleViewRejectRequestOpenPopup = (requestID) => {
    const requestToView = rejectedRequests.find(
      (request) => request.requestID === requestID
    );
    setSelectedRequestView(requestToView);
    setOpenViewPopup(true);
  };

  const handleViewRequestClosePopup = () => {
    setOpenViewPopup(false);
  };

  const columns = [
    { field: "requestID", headerName: "Request ID", flex: 1 },
    {
      field: "projectID",
      headerName: "Project Name",
      flex: 2,
      valueGetter: (params) =>
        projectNames[params.row.projectID] || "Project Name",
    },
    { field: "officerName", headerName: "Officer Name", flex: 2 },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      flex: 1.5,
      align: "right",
      renderCell: (params) => (
        <>
          <Tooltip title={formatTotalCost(params.row.totalAmount / 100)}>
            {formatTotalCost(params.row.totalAmount / 100)}
          </Tooltip>
        </>
      ),
    },
    { field: "requiredDate", headerName: "Required Date", flex: 1.5 },
    { field: "requestStatus", headerName: "Request Status", flex: 1 },
    {
      field: "action",
      headerName: "",
      flex: 0.5,
      renderCell: (params) => (
        <Tooltip title={"Rejected info"}>
          <RemoveRedEyeRounded
            className="p-1"
            style={{
              cursor: "pointer",
              color: "orange",
              width: "20px",
              height: "20px",
            }}
            onClick={() =>
              handleViewRejectRequestOpenPopup(params.row.requestID)
            }
          />
        </Tooltip>
      ),
    },
    {
      field: "deleteAction",
      headerName: "",
      flex: 0.5,
      renderCell: (params) => (
        <Tooltip title={"Delete"}>
          {deleteAccess && (
          <DeleteOutlineTwoToneIcon
            onClick={() => showConfirmation(params.row.requestID)}
            className="text-red-500 cursor-pointer"
            style={{
              width: "18px",
              height: "18px",
            }}
          />
          )}
        </Tooltip>
      ),
    },
  ];
  const getRowId = (row) => row.id;
  const getRowHeight = () => 39;

  const offset = currentPage * rowPerPage;
  const paginatedRequests = filteredRequests
    .slice(offset, offset + rowPerPage)
    .map((request) => ({
      id: request.requestID,
      ...request,
    }));
  return (
    <div>
      <div>
        <Navbar />
      </div>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "60%",
            transform: "translate(-50%, -50%)",
          }}
          className="z-50 bg-transparent"
        >
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
          <div className="mt-2">Loading...</div>
        </div>
      )}

      <div className="userTableContainer w-[95%] mt-5 ml-5 ">
        <div className=" shadow-md border-b-2">
          <div className="flex items-center justify-between p-2">
            <TextField
              type="text"
              placeholder="Search..."
              className="border border-gray-300 rounded px-3 py-1 w-[50%]"
              inputProps={{
                style: {
                  height: "23px",
                  padding: "5px",
                  fontSize: "13px",
                },
              }}
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <Tooltip title={"Date filter"}>
              <FilterListIcon
                onClick={handleDateFilterClick}
                className="mb-4 lg:mb-0 relative ml-[35%] cursor-pointer items-end justify-between"
              />
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseDateFilter}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem>
                <div className="p-4 w-72">
                  <div className="mb-4">
                    <label className="block text-xs font-semibold text-gray-700 mb-1">
                      Start Date
                    </label>
                    <input
                      type="text"
                      placeholder="Start Date"
                      className="border border-gray-300 rounded px-3 py-2 w-full focus:outline-none focus:border-blue-500 h-7 text-xs"
                      ref={(startDateInput) => {
                        if (startDateInput) {
                          flatpickr(startDateInput, {
                            onChange: (selectedDates) => {
                              setStartDate(selectedDates[0]);
                            },
                          });
                        }
                      }}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-xs font-semibold text-gray-700 mb-1">
                      End Date
                    </label>
                    <input
                      type="text"
                      placeholder="End Date"
                      className="border border-gray-300 rounded px-3 py-2 w-full focus:outline-none focus:border-blue-500 h-7 text-xs"
                      ref={(endDateInput) => {
                        if (endDateInput) {
                          flatpickr(endDateInput, {
                            onChange: (selectedDates) => {
                              setEndDate(selectedDates[0]);
                            },
                          });
                        }
                      }}
                    />
                  </div>
                  <p className="text-xs text-gray-600">
                    Select the start and end dates for filtering.
                  </p>
                </div>
              </MenuItem>
            </Menu>
          </div>
          <div className=" shadow-md ">
            <div style={{ height: "100%", width: "100%" }}>
              <DataGrid
                rows={paginatedRequests}
                columns={columns.map((column) => ({
                  ...column,
                  headerClassName: "custom-header",
                  cellClassName: "custom-cell",
                }))}
                pageSize={rowPerPage}
                page={currentPage}
                pagination={false}
                pageSizeOptions={[]}
                disablePrevButton={true}
                disableNextButton={true}
                hideFooter
                getRowId={getRowId}
                getRowHeight={getRowHeight}
              />
            </div>
          </div>
        </div>
        <div className="pagination-container">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={Math.ceil(rejectedRequests.length / rowPerPage)}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
            onPageChange={(selected) => setCurrentPage(selected.selected)}
            containerClassName="pagination"
            activeClassName="active"
          />
        </div>
      </div>
      <ToastContainer />
      <ViewRequestPopup
        open={openViewPopup}
        setOpen={setOpenViewPopup}
        title="Rejected Request Informations"
      >
        <ViewRequest
          onClose={handleViewRequestClosePopup}
          request={selectedRequestView}
        />
      </ViewRequestPopup>
    </div>
  );
};

export default RejectedTable;
