import React from "react";
import "./transactionstyle.scss";
import Sidebar from "../../component/sidebar/sidebar";
import PendingTransactionsTable from "../../component/transaction/pendingTransactionsTable";

const PendingTransactions = () => {
  return (
    <div className="transactions">
      <Sidebar />
      <div className="transactionContainer">
        <PendingTransactionsTable />
      </div>
    </div>
  );
};
export default PendingTransactions;
