import React, { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import TextField from "@mui/material/TextField";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


const UpdateTransportType = (props) => {
  const { onClose, setSuccess,transport } = props;
  const [transportTypeError, setTransportTypeError] = useState("");
  const [formData, setFormData] = useState(() => {
    if (transport) {
      return {
        typeID: transport.typeID || "",
        type:transport.type||"",
        pricePerMile: transport.pricePerMile/100 || "",
      };
    }
    return {
      type: "",
      pricePerMile: "",
    };
  });
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const MAX_DECIMAL_POINTS = 2;

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "transportTypes") {
      const allowedCharactersRegex = /^[a-zA-Z0-9., ]*$/;
      if (!allowedCharactersRegex.test(value)) {
        toast.error("Invalid characters.");
        return;
      }
      if (name === "transportTypes") {
        if (value.length < 3) {
          setFormData({
            ...formData,
            [name]: value,
          });
          setTransportTypeError(
            `Transport type must be at least ${3} characters long.`
          );
          return;
        } else {
          setTransportTypeError("");
        }
      }

      if (value.length > 30) {
        toast.error("Maximum character count exceeded.");
        return;
      }
    }
    if (name === "pricePerMile") {
      const floatValue = parseFloat(value);
      if (floatValue < 0) {
        toast.warning("Price values cannot be negative");
        return;
      }
      const decimalCount = (value.split(".")[1] || []).length;
      if (decimalCount > MAX_DECIMAL_POINTS) {
        toast.warning(`Maximum ${MAX_DECIMAL_POINTS} decimal points allowed`);
        return;
      }
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const submitForm = async (e) => {
    e.preventDefault();

    try {
      setButtonDisabled(true);
      const dataToSend = {
        type: formData.type,
        pricePerMile: parseFloat(formData.pricePerMile),
      };

      const response = await axios.put(
        `${process.env.REACT_APP_API}/cost/updateTransportType/${transport.typeID}`,
        dataToSend
      );
      Swal.fire({
        icon: 'success',
        title:"Success!",
        text: "Transport updated successfully",
      });
      onClose();
      setSuccess();

    } catch (error) {
      console.error("Error submitting form:", error);
      Swal.fire({
        icon: 'error',
        title:"Error!",
        text: "An error occurred while updating the transport",
      });
    } finally {
      setTimeout(() => {
        setButtonDisabled(false);
      }, 2000);
    }
  };
 
  return (
    <div className="items-center grid grid-cols-1">
      <form onSubmit={submitForm} className="ml-[30%] ">
        <div className="mt-5 w-2/3">
          <TextField
            id="type"
            type="text"
            label="Transport type"
            name="type"
            required
            value={formData.type}
            onChange={handleChange}
            style={{
              width: "300px",
            }}
          />
        </div>
        {transportTypeError && (
          <span className="errorText text-xs">{transportTypeError}</span>
        )}
        <div className="mt-5 w-2/3">
          <TextField
            id="pricePerMile"
            type="number"
            name="pricePerMile"
            label="Price per KM"
            required
            value={formData.pricePerMile}
            onChange={handleChange}
            style={{
              width: "300px",
            }}
          />
        </div>
        <div className="mt-5">
          <button
            type="submit"
            style={{
              backgroundColor: isButtonDisabled ? "#808080" : "#111827",
              color: "white",
              cursor: isButtonDisabled ? "not-allowed" : "pointer",
              height: "30px",
              fontSize: "12px",
              width: "120px",
            }}
            className="rounded focus:outline-none hover:shadow-md"
          >
            {isButtonDisabled ? "UPDATING..." : "UPDATE"}
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default UpdateTransportType;
