// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-header {
  background-color: #111827;
  color: white;
  font-family: serif;
  font-size: 12px;
  text-align: center;
}

.custom-cell {
  padding: 5px;
  font-size: 12px;
}

.custom-header-cell {
  height: 20px;
  line-height: 20px;
}

/* Add these styles to reduce the header height */
.custom-datagrid .MuiDataGrid-colCell {
  height: 20px;
  line-height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/cashAdvance/style.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;AACJ;;AAGE;EACE,YAAA;EACA,eAAA;AAAJ;;AAEE;EACE,YAAA;EACA,iBAAA;AACJ;;AACE,iDAAA;AACF;EACI,YAAA;EACA,iBAAA;AAEJ","sourcesContent":[".custom-header {\n    background-color: #111827;\n    color: white; \n    font-family: serif;\n    font-size: 12px;\n    text-align: center;\n\n  }\n  \n  .custom-cell {\n    padding: 5px;\n    font-size: 12px;\n  }\n  .custom-header-cell {\n    height: 20px; // Adjust this value as needed\n    line-height: 20px; // Adjust this value as needed\n  }\n  /* Add these styles to reduce the header height */\n.custom-datagrid .MuiDataGrid-colCell {\n    height: 20px; // Adjust this value as needed\n    line-height: 20px; // Adjust this value as needed\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
