import React from "react";

import UserRestriction from "../UserRestriction";

export default function MainLayout({ children }) {
  return (
    <UserRestriction>
      <div>{children}</div>
    </UserRestriction>
  );
}
