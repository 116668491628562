import React from "react";
import "./homestyle.scss";

import Sidebar from "../../component/sidebar/sidebar";
import Widget from "../../component/widget/widget";
import Featured from "../../component/featured/featured";
import Chart from "../../component/chart/chart";
import Table from "../../component/table/table";
import { useUser } from "../../userContext";

const Home = () => {
  const { user } = useUser();

  console.log(user);
  return (
    <div className="bg-gray-100 home">
      <Sidebar className="sidebar" />
      <div className="homeContainer">
        <div className="widgets">
          <Widget type="projects" />
          <Widget type="transactions" />
          <Widget type="clients" />
          <Widget type="officers" />
        </div>
        <div className="charts">
          <Featured />
          <Chart />
        </div>
        <div className="bg-white rounded-lg listContainer">
          <div className="bg-white listTitle text-sm font-bold text-gray-400 font-mono">SUMMARY OF PROJECT BUDGET</div>
          <Table />
        </div>
      </div>
    </div>
  );
};
export default Home;
