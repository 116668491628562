import React, { useEffect, useState } from "react";
import axios from "axios";
import Checkbox from "@mui/material/Checkbox";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteOutlineTwoToneIcon from "@mui/icons-material/DeleteOutlineTwoTone";
import Tooltip from "@mui/material/Tooltip";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import "./style.scss"


const UpdatePermission = (props) => {
  const { onClose, setSuccess, userID,isAdminPermission  } = props;
  const [userPermissions, setUserPermissions] = useState([]);
  const [allPermissionTypes, setAllPermissionTypes] = useState([]);
  const [selectedActions, setSelectedActions] = useState([]);
  const [hiddenPermissionId] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [permissionToDelete, setPermissionToDelete] = useState(null);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [isAnyPermissionSelected, setIsAnyPermissionSelected] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const permissionsResponse = await axios.get(
          `${process.env.REACT_APP_API}/permissions/getPermissions/${userID}`
        );
        const userPermissions = permissionsResponse.data;

        const allPermissionsResponse = await axios.get(
          `${process.env.REACT_APP_API}/permissions/getAllPermissions`
        );
        const allPermissionTypes = allPermissionsResponse.data;

        const updatedPermissions = userPermissions.map((userPermission) => {
          const matchedPermission = allPermissionTypes.find(
            (permission) =>
              permission.permissionID === userPermission.permissionID
          );
          return {
            ...userPermission,
            permissionType: matchedPermission
              ? matchedPermission.permissionType
              : "Unknown",
          };
        });

        setUserPermissions(updatedPermissions);
        setAllPermissionTypes(allPermissionTypes);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [userID]);

  const handleDeletePermission = (permissionID) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        container: 'my-swal', 
      },
      backdrop: 'swal2-backdrop-show',
    }).then((result) => {
      if (result.isConfirmed) {
        confirmDelete(permissionID);
      }
    });
  };
  
  const confirmDelete = async (permissionID) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API}/permissions/deletePermission/${permissionID}/${userID}`
      );
  
      setUserPermissions((prevPermissions) =>
        prevPermissions.filter(
          (permission) => permission.permissionID !== permissionID
        )
      );
    } catch (error) {
      console.error("Error deleting permission:", error);
      Swal.fire({
        icon: 'error',
        title: 'An error occurred while deleting the permission',
      });
    }
  };
  

  const cancelDelete = () => {
    setPermissionToDelete(null);
  };

  const handleActionCheckboxChange = (action) => {
    if (action.id === hiddenPermissionId) {
      return null;
    }
  
    setErrorText("");
  
    let updatedSelectedActions;
  
    if (selectedActions.includes(action.id)) {
      updatedSelectedActions = selectedActions.filter(
        (selectedAction) => selectedAction !== action.id
      );
    } else {
      updatedSelectedActions = [...selectedActions, action.id];
    }
  
    setIsAnyPermissionSelected(updatedSelectedActions.length > 0);
  
    setSelectedActions(updatedSelectedActions);
  };
  

  const renderActionCheckbox = (action) => {
    if (action.id === hiddenPermissionId) {
      return null;
    }
    const isAlreadyAdded = userPermissions.some(
      (permission) => permission.permissionID === action.id
    );
    
    
    return (
      <div key={action.id} className="flex items-center mb-2">
        <Checkbox
          type="checkbox"
          id={action.id}
          checked={isAlreadyAdded || selectedActions.includes(action.id)}
          onChange={() => handleActionCheckboxChange(action)}
          disabled={isAlreadyAdded}
          style={{
            width: "10px",
            height: "10px",
            marginRight: "6px",
            position: "relative",
          }}
        />
        <label
          htmlFor={action.id}
          className={`ml-2 text-left text-xs ${
            isAlreadyAdded ? "text-gray-500" : ""
          }`}
        >
          {action.label}
        </label>
      </div>
    );
  };

  const handleUpdate = async () => {
    setButtonDisabled(true);
    try {
      setButtonDisabled(true);

      const permissions = {
        permissions: selectedActions,
      };

      await axios.post(
        `${process.env.REACT_APP_API}/permissions/saveUserPermission/${userID}`,
        permissions
      );

      setSuccess(true);
      onClose();
      Swal.fire({
        icon: 'success',
        title:"Success!",
        text: "Permissions updated successfully!",
      });
      
    } catch (error) {
      console.error("Error updating permissions:", error);
      Swal.fire({
        icon: 'error',
        title:"Error!",
        text: "An error occurred while updating the user",
      });
    } finally {
      setTimeout(() => {
        setButtonDisabled(false);
      }, 2000);
    }
  };

  const handleCancel = () => {
    setSelectedActions([]);
  };

  return (
    <div className="flex">
      <div className="max-w mx-auto mt-8 p-6 bg-white rounded-md shadow-md  flex-1 border-r-2">

        <h3 className="text-sm font-semibold mb-2">Remove Permissions</h3>
        <ul>
          {userPermissions.map((permission) => (
            <li
              key={permission.permissionID}
              className="flex items-center justify-between mb-2 text-sm"
            >
              <span className="text-gray-700">{permission.permissionType}</span>
              {!isAdminPermission && ( 
              <Tooltip title={"Remove permission"}>
                <DeleteOutlineTwoToneIcon
                  onClick={() =>
                    handleDeletePermission(permission.permissionID)
                  }
                  className="text-red-500 hover:text-red-700 focus:outline-none cursor-pointer"
                  style={{ width: 18, height: 18 }}
                />
              </Tooltip>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className="max-w mx-auto mt-8 p-6 bg-white rounded-md shadow-md flex-2 border-l-2 ">
        <p className="text-sm font-semibold mb-2">Update Permissions</p>
        <div className="grid grid-cols-3 gap-4">
          {allPermissionTypes.map(
            (action) =>
              !permissionToDelete &&
              renderActionCheckbox({
                id: action.permissionID,
                label: action.permissionType,
              })
          )}
        </div>
        <div className="mt-6">
          <button
            onClick={handleUpdate}
            fullWidth
            disabled={!isAnyPermissionSelected || isButtonDisabled}
            style={{
              backgroundColor: (!isAnyPermissionSelected || isButtonDisabled) ? "#808080" : "#111827",
              color: "white",
              cursor: isButtonDisabled ? "not-allowed" : "pointer",
              height: "30px",
              fontSize: "12px",
              width: "120px",
            }}
            className="rounded focus:outline-none hover:shadow-md"
          >
            {isLoading ? "UPDATING..." : "UPDATE"}
          </button>
          <button
            onClick={handleCancel}
            className="bg-gray-300 text-gray-700 px-4  rounded focus:outline-none ml-4"
            style={{
              height: "30px",
              fontSize: "12px",
              width: "120px",
            }}
          >
            CANCEL
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default UpdatePermission;
