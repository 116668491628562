import React from "react";
import "./clientstyle.scss";
import Sidebar from "../../component/sidebar/sidebar";
import ClientTable from "../../component/client/clienttable";

const Clients = () => {
  return (
    <div className="clients">
      <Sidebar />
      <div className="clientsContainer">
        <ClientTable />
      </div>
    </div>
  );
};
export default Clients;
