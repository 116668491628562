import { createContext, useState, useContext, useEffect } from "react";
import axios from "axios";
import Loading from "./component/Loading/Loading";

const UserContext = createContext();

function UserProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(`${process.env.REACT_APP_API}/user/me`, {
          withCredentials: true,
        });
        setUser(result.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };

    fetchData();
  }, []);


  return (
    <UserContext.Provider value={{ user, setUser }}>
      {loading ? <Loading /> : <>{children}</>}
    </UserContext.Provider>
  );
}
export default UserProvider;

export function useUser() {
  return useContext(UserContext);
}
