import React, { useEffect, useState } from "react";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import AsyncSelect from "react-select/async";
import axios from "axios";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


const MAX_DECIMAL_POINTS = 2;

const UpdateProject = (props) => {
  const { onClose, project,setSuccess } = props;
  const navigate = useNavigate();
  const [clientOptions, setClientOptions] = useState([]);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [projectNameError, setProjectNameError] = useState("");
  const [formData, setFormData] = useState(() => {
    if (project) {
      return {
        projectID:project.projectID||"",
        projectName: project.projectName || "",
        maxBudget: project.maxBudget || "",
        minBudget: project.minBudget || "",
        clientID: project.clientID || null,
      };
    }

    return {
      projectID:"",
      projectName: "",
      maxBudget: "",
      minBudget: "",
      client: null,
    };
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "projectName") {
      const allowedCharactersRegex = /^[a-zA-Z0-9., ]*$/;
      if (!allowedCharactersRegex.test(value)) {
        toast.error("Invalid characters.");
        return;
      }
  
      if (value.length > 30) {
        toast.error("Maximum character count exceeded.");
        return;
      }
    }
    if (name === "projectName") {
      if (value.length < 4) {
        setFormData({
          ...formData,
          [name]: value,
        });
        setProjectNameError(
          `Project name must be at least ${4} characters long.`
        );
        return;
      } else {
        setProjectNameError("");
      }
    }

    if (value.trim() === '') {
      setFormData({
        ...formData,
        [name]: value,
      });
      return;
    }

    if (name === "maxBudget" || name === "minBudget") {
      const floatValue = parseFloat(value);
    if (floatValue < 0) {
      toast.warning("Budget values cannot be negative");
      return;
    }
      const decimalCount = (value.split(".")[1] || []).length;
      if (decimalCount > MAX_DECIMAL_POINTS) {
        toast.warning(`Maximum ${MAX_DECIMAL_POINTS} decimal points allowed`);
        return;
      }

      if (
        name === "maxBudget" &&
        parseFloat(value) < parseFloat(formData.minBudget)
      ) {
        toast.warning("Max budget should be greater than or equal to Min budget");
        return;
      }
      if (
        name === "minBudget" &&
        parseFloat(value) > parseFloat(formData.maxBudget)
      ) {
        toast.warning("Min budget should be less than or equal to Max budget");
        return;
      }
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const filterClient = (inputValue, clients) => {
    return clients.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadClientOptions = (inputValue, callback) => {
    axios
      .get(`${process.env.REACT_APP_API}/client/getAllClients`)
      .then((response) => {
        const clientData = response.data.map((client) => ({
          value: client.clientID,
          label: client.clientName,
        }));
        callback(filterClient(inputValue, clientData));
      })
      .catch((error) => {
        console.error("Error fetching clients:", error);
      });
  };

  useEffect(() => {
    loadClientOptions("", (options) => {
      setClientOptions(options);
    });
  }, []);
  useEffect(() => {
    loadClientOptions("", (options) => {
      setClientOptions(options);
    });
  }, []);

  useEffect(() => {
    if (project && project.clientID) {
      axios
        .get(`${process.env.REACT_APP_API}/client/getClient/${project.clientID}`)
        .then((response) => {
          const selectedClient = {
            value: response.data.clientID,
            label: response.data.clientName,
          };
          setFormData((prevFormData) => ({
            ...prevFormData,
            client: selectedClient,
          }));
        })
        .catch((error) => {
          console.error("Error fetching client information:", error);
        });
    }
  }, [project]);

  const handleClientChange = (selectedOption) => {
    setFormData({
      ...formData,
      client: selectedOption,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const dataToSend = {
        projectName: formData.projectName,
        maxBudget: parseFloat(formData.maxBudget),
        minBudget: parseFloat(formData.minBudget),
        client: formData.client ? formData.client.value : null,
      };
      if (formData.projectName.length < 4) {
        toast.warning("Project name must be at least 4 characters long.");
        setButtonDisabled(false);
        return;
      }

      const response = await axios.put(
        `${process.env.REACT_APP_API}/project/updateProject/${project.projectID}`,
        dataToSend,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setSuccess(true);
        Swal.fire({
          icon: 'success',
          title:"Success!",
          text: "Project updated successfully",
        });
        onClose();
        setFormData({
          projectName: "",
          maxBudget: "",
          minBudget: "",
          client: null,
        });
        setTimeout(() => {
          window.close();
        }, 2000);
      } else {
        Swal.fire({
          icon: 'error',
          title:"Error!",
          text:(`Error: ${response.data.error || "Unknown error"}`),
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
      Swal.fire({
        icon: 'error',
        title:"Error!",
        text: "An error occurred while updating the project",
      });
    }
  };

  return (
    <div className="flex w-full">
      {/* <Sidebar /> */}
      <div className="w-full p-4">
        <div className="flex justify-end ">
          <div className="w-3/4 right">
            <form onSubmit={handleSubmit} className="flex flex-wrap gap-4">
              <div className="w-2/3 ">
                <TextField
                  id="projectName"
                  label="Project name"
                  variant="outlined"
                  type="text"
                  name="projectName"
                  required
                  fullWidth
                  value={formData.projectName}
                  onChange={handleInputChange}
                />
                {projectNameError && (
                  <span className="errorText text-xs">{projectNameError}</span>
                )}
              </div>
              <div className="w-2/3 formInput">
                <TextField
                  id="maxBudget"
                  label="Max budget"
                  variant="outlined"
                  type="number"
                  name="maxBudget"
                  required
                  fullWidth
                  value={formData.maxBudget}
                  onChange={handleInputChange}
                />
              </div>
              <div className="w-2/3 formInput">
                <TextField
                  id="minBudget"
                  label="Min budget"
                  variant="outlined"
                  type="number"
                  name="minBudget"
                  required
                  fullWidth
                  value={formData.minBudget}
                  onChange={handleInputChange}
                />
              </div>
              <div className="w-2/3 formInput">
                <AsyncSelect
                  required
                  defaultOptions
                  className="selectOption"
                  loadOptions={loadClientOptions}
                  placeholder="Select a client"
                  onChange={handleClientChange}
                  value={formData.client}
                  options={clientOptions}
                />
              </div>
              <div className="w-2/6 formInput ">
              <button
                  type="submit"
                  variant="contained"
                  disabled={isButtonDisabled}
                  className="rounded focus:outline-none hover:shadow-md"
                  style={{
                  
                    backgroundColor: isButtonDisabled ? "#808080" : "#111827",
                    color: "white",
                    cursor: isButtonDisabled ? "not-allowed" : "pointer",
                    height:"30px",
                    fontSize:"12px",
                    width:"120px"
                  }}
                >
                  {isButtonDisabled ? "UPDATING..." : "UPDATE"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateProject;
