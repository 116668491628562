import React, { useState, useEffect } from "react";
import "./chart.scss";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import axios from "axios";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";


const Chart = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const lastYearAndCurrentYearResponse = await axios.get(
          `${process.env.REACT_APP_API}/transaction/totalTransactions`
        );
        

        const lastYearData = lastYearAndCurrentYearResponse.data;
      
        setData(lastYearData);
        setTimeout(() => {
          setLoading(false);
        }, 1500);
        
      } catch (error) {
        console.error("Error fetching total transactions:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="chart bg-white rounded-lg">
      {loading && (
        <div
          style={{
            position: "absolute",
            top: "32%",
            left: "72%",
            transform: "translate(-50%, -50%)",
          }}
          className="z-50 bg-transparent"
        >
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
          <div className="mt-2">Loading...</div>
        </div>
      )}
      <div className="text-sm font-bold text-gray-400 title font-mono">LAST & CURRENT YEAR TRANSACTIONS</div>
      {!loading &&(
      <ResponsiveContainer aspect={2 / 1} width="100%">
        <AreaChart data={data}>
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis dataKey="month" />
          
          <Tooltip />
          <Legend />
          <Area
            type="monotone"
            dataKey="lastYear"
            stackId="1"
            stroke="#8884d8"
            fill="#8884d8"
          />
          <Area
            type="monotone"
            dataKey="currentYear"
            stackId="1"
            stroke="#82ca9d"
            fill="#82ca9d"
          />
        </AreaChart>
      </ResponsiveContainer>
      )}
    </div>
  );
};

export default Chart;
