import React, { useEffect, useState } from "react";
import axios from "axios";
import { Tooltip, Button } from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FileViewPopup from "../popup/viewPopup";
import FileView from "../../pages/view/fileView";

const RejectedInformation = (props) => {
  const { transaction } = props;
  const [rejectedDetails, setRejectedDetails] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [openViewPopup, setOpenViewPopup] = useState(false);
  const [selectedFileView, setSelectedFileView] = useState(null);

  useEffect(() => {
    const fetchRejectedDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API}/transaction/getRejectedReasonByTransactionID/${transaction.headID}`
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setRejectedDetails(data);
      } catch (error) {
        console.error("Error fetching rejected details:", error);
      }
    };

    if (transaction) {
      fetchRejectedDetails();
    }
  }, [transaction]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const transactionsData = await axios.get(
          `${process.env.REACT_APP_API}/transaction/getTransactionsById/${transaction.headID}`
        );

        const projectsData = await axios.get(
          `${process.env.REACT_APP_API}/project/getAllProjects`
        );
        const projectNamesMap = {};
        projectsData.data.forEach((project) => {
          projectNamesMap[project.projectID] = project.projectName;
        });

        const costTypesData = await axios.get(
          `${process.env.REACT_APP_API}/cost/getAllCostTypes`
        );
        const costTypeNamesMap = {};
        costTypesData.data.forEach((costType) => {
          costTypeNamesMap[costType.costID] = costType.costType;
        });

        const updatedTransactions = transactionsData.data.map(
          (transaction) => ({
            ...transaction,
            projectName: projectNamesMap[transaction.projectID] || "",
            costTypeName: costTypeNamesMap[transaction.costTypesID] || "",
          })
        );

        setTransactions(updatedTransactions);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [transaction.headID]);

  const handleViewFileOpenPopup = (transactionID) => {
    const selectedTransaction = transactions.find(
      (transaction) => transaction.transactionID === transactionID
    );

    setOpenViewPopup(true);
    setSelectedFileView(selectedTransaction);
  };

  const handleViewFileClosePopup = () => {
    setOpenViewPopup(false);
  };

  const calculateTotalAmount = () => {
    let totalAmount = 0;
    if (Array.isArray(transactions)) {
      transactions.forEach((transactionItem) => {
        totalAmount += parseFloat(transactionItem.amount);
      });
    }
    return totalAmount;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  const formatTotalCost = (cost) => {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(cost);
  };

  return (
    <div>
      {rejectedDetails ? (
        <table className="mb-5 w-full border-collapse text-sm border">
          <tbody>
            <tr>
              <td className="p-2 text-start text-sm bg-[#111827] text-white w-[200px]">
                Head Transaction ID
              </td>
              <td>
                <span className="p-2 text-xs text-gray-600">{rejectedDetails.transactionID}</span>
              </td>
            </tr>
            <tr>
              <td className="p-2 text-start text-sm bg-[#111827] text-white w-[200px]">
                Requested Officer Name
              </td>
              <td>
                <span className="p-2 text-xs text-gray-600">{transaction.officerName}</span>
              </td>
            </tr>
            <tr>
              <td className="p-2 text-start text-sm bg-[#111827] text-white w-[200px]">
                Reject Officer Name
              </td>
              <td>
                {" "}
                <span className="p-2 text-xs text-gray-600">
                  {rejectedDetails.officerName}
                </span>{" "}
              </td>
            </tr>
            <tr>
              <td className="p-2 text-start text-sm bg-[#111827] text-white w-[200px]">
                Rejected Date
              </td>
              <td>
                <span className="p-2 text-xs text-gray-600">
                  {formatDate(rejectedDetails.createdAt)}
                </span>
              </td>
            </tr>
            <tr>
              <td className="p-2 text-start text-sm bg-[#111827] text-white w-[200px]">
                Reference No
              </td>
              <td>
                <span className="p-2 whitespace-pre-line row-span-2 text-xs text-gray-600">
                  {transaction.refferenceNo}
                </span>{" "}
              </td>
            </tr>
            <tr>
              <td className="p-2 text-start text-sm bg-[#111827] text-white w-[200px]">
                Reason for Reject
              </td>
              <td>
                <span className="p-2 whitespace-pre-line row-span-2 text-xs text-gray-600">
                  {rejectedDetails.reason}
                </span>{" "}
              </td>
            </tr>
            
          </tbody>
        </table>
      ) : (
        <div>No rejected details available</div>
      )}

      <div className="p-4">
        <div className="items-center justify-between mt-5">
          <table className="min-w-full divide-y divide-gray-200 mb-4 border bg-white shadow-md">
            <thead className="bg-[#111827] text-gray-400 text-sm p-2">
              <tr>
                <th className="font-mono">Transaction ID</th>
                <th className="text-right font-mono">Amount</th>
                <th className="font-mono">Cost Type Name</th>
                <th className="font-mono">Documents</th>
              </tr>
            </thead>
            <tbody className="text-sm text-gray-600">
              {Array.isArray(transactions) && transactions.length > 0 ? (
                transactions.map((transactionItem) => (
                  <tr key={transactionItem.transactionID} className="border">
                    <td className="p-1 text-center">
                      {transactionItem.transactionID}
                    </td>
                    <td className="p-1 text-right">
                      {formatTotalCost(transactionItem.amount)}
                    </td>
                    <td className="p-1 text-center">
                      <div className="">{transactionItem.costTypeName}</div>
                    </td>
                    <td className="p-1">
                      {transactionItem.receipts && (
                        <div className="flex items-center justify-center p-1 text-2xl">
                          <Tooltip title={"Transaction documents"}>
                            <Button
                              startIcon={<InsertDriveFileIcon />}
                              variant="outlined"
                              onClick={() =>
                                handleViewFileOpenPopup(
                                  transactionItem.transactionID
                                )
                              }
                              style={{ height: "28px" }}
                            >
                              {transactionItem.receipts.length}
                            </Button>
                          </Tooltip>
                        </div>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="text-center py-4">
                    No transactions available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="m-5 ">
          <hr className="w-[25%] border-black ml-[76.5%]" />

          <p className="flex items-center justify-end p-3 text-2xl text-gray-600">
            TOTAL {formatTotalCost(calculateTotalAmount())}
          </p>
          <hr className="w-[25%] border-black ml-[76.5%]" />

          <hr className="w-[25%] border-black mt-1 ml-[76.5%]" />
        </div>
      </div>
      <FileViewPopup
        open={openViewPopup}
        setOpen={setOpenViewPopup}
        title="Transaction Documents"
      >
        <FileView
          onClose={handleViewFileClosePopup}
          transaction={selectedFileView}
        />
      </FileViewPopup>
    </div>
  );
};

export default RejectedInformation;
