import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { useUser } from "../../userContext";

const defaultTheme = createTheme();

export default function SignIn() {
  const { setUser } = useUser();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
  
    const maxUsernameLength = 20;
    const maxPasswordLength = 16;
  
    if (value === "") {
      if (name === "username") {
        setUsername("");
      } else if (name === "password") {
        setPassword("");
      }
      return;
    }
  
    if (name === "username" && value.length > maxUsernameLength) {
      const truncatedValue = value.slice(0, maxUsernameLength);
      setUsername(truncatedValue);
      toast.warning(`Username must be at most ${maxUsernameLength} characters long.`);
      return;
    }
  
    if ((name === "password") && value.length > maxPasswordLength) {
      const truncatedValue = value.slice(0, maxPasswordLength);
      setPassword(truncatedValue);
      toast.warning(`Password must be at most ${maxPasswordLength} characters long.`);
      return;
    }
  
    if (name === "username" && !/^[a-zA-Z0-9@#$,. ]+$/.test(value)) {
      toast.error("Invalid characters in username.");
      return;
    }
  
    if (name === "password" && !/^[a-zA-Z0-9@#$,.]+$/.test(value)) {
      toast.error("Invalid characters in password.");
      return;
    }
  
    if (name === "username") {
      setUsername(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      if (username === "" || password === "") {
        toast.warning("All fields are required!");
        return;
      }

      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API}/user/login`,
        {
          username: username,
          password: password,
        },
        { withCredentials: true }
      );

      setTimeout(() => {
        setLoading(false);
        if (response.data.message === "Invalid credentials") {
          toast.error("Invalid username or password. Please try again.");
          return;
        }
        if (response.data.message === "Account Deactivated") {
          toast.warning("Account Deactivated! Cannot log in.");
          return;
        }
        if (response.data.message === "Success") {
        const userPermissions = response.data.data.permissions;

        const hasPermission1 = userPermissions.includes(1);
        const hasPermission33 = userPermissions.includes(33);

        if (hasPermission1 || hasPermission33) {
          toast.success("Login Success.");
          setUser(response.data.data);
          navigate("/dashboard");
        } else {
          toast.success("Login Success.");
          setUser(response.data.data);
          navigate("/cashAdvance/approval");
        }
      }
      }, 1500);
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "#111827" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              value={username}
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={handleChange}
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: "#2196f3",
                color: "#ffffff",
              }}
              disabled={loading}
            >
              {loading ? "Loading..." : "Sign In"}
            </Button>
          </Box>
        </Box>
      </Container>
      <ToastContainer />
    </ThemeProvider>
  );
}
