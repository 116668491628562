import React, { useState, useEffect } from "react";
import TransactionNavBar from "../../component/navBar/transactionNavBar";
import { TextField } from "@mui/material";
import ReactPaginate from "react-paginate";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../userContext";
import { Box, LinearProgress } from "@mui/material";
import ViewTransactionPopup from "../popup/viewPopup";
import ViewTransaction from "./rejectedTransactionView";
import DeleteOutlineTwoToneIcon from "@mui/icons-material/DeleteOutlineTwoTone";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import FilterListIcon from "@mui/icons-material/FilterList";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid } from "@mui/x-data-grid";
import "./style.scss";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


const RejectedTransactionTable = () => {
  const navigate = useNavigate();
  const [rejectedTransactios, setRejectedTransactions] = useState([]);
  const [projects, setProjects] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingPageChange, setLoadingPageChange] = useState(false);
  const { user } = useUser();
  const [searchTerm, setSearchTerm] = useState("");
  const rowPerPage = 10;
  const [openViewPopup, setOpenViewPopup] = useState(false);
  const [selectedTransactionView, setSelectedTransactionView] = useState(null);
  const [permissionToDelete, setPermissionToDelete] = useState(false);
  const [requestToDelete, setRequestToDelete] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    fetchRejectedTransactions();
  }, [currentPage, user]);

  const fetchRejectedTransactions = async () => {
    try {
      setLoading(true);

      let apiEndpoint;

      const hasPermission29 = user.permissions.includes(29);
      const hasPermission1 = user.permissions.includes(1);

      if (hasPermission29 || hasPermission1) {
        apiEndpoint = `${process.env.REACT_APP_API}/transaction/getAllRejectedTransactions`;
      } else {
        apiEndpoint = `${process.env.REACT_APP_API}/transaction/getAllRejectedTransactionsByOfficerName/${user.officerName}`;
      }

      const response = await fetch(apiEndpoint);
      const data = await response.json();

      if (Array.isArray(data)) {
        setRejectedTransactions(data);
      } else {
        console.error("Invalid data received:", data);
      }
    } catch (error) {
      console.error("Error fetching pending requests:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchProjects = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API}/project/getAllProjects`
      );
      const data = await response.json();

      if (Array.isArray(data)) {
        setProjects(data);
      } else {
        console.error("Invalid data received:", data);
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const getProjectNameById = (headID) => {
    const transaction = rejectedTransactios.find(
      (transaction) => transaction.headID === headID
    );

    if (
      transaction &&
      Array.isArray(transaction.transactions) &&
      transaction.transactions.length > 0
    ) {
      const projectId = transaction.transactions[0].projectID;
      const project = projects.find(
        (project) => project.projectID === projectId
      );

      return project ? project.projectName : "";
    }

    return "";
  };

  const formatTotalCost = (cost) => {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(cost);
  };

  const handlePageChange = async ({ selected }) => {
    try {
      setLoadingPageChange(true);

      await new Promise((resolve) => setTimeout(resolve, 1000));

      setCurrentPage(selected);
    } finally {
      setLoadingPageChange(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  const handleViewClick = (headID) => {
    navigate(`/transactions/pending/transactionView/${headID}`);
  };

  const handleViewRejectTransactionOpenPopup = (headID) => {
    const transactionToView = rejectedTransactios.find(
      (transaction) => transaction.headID === headID
    );
    setSelectedTransactionView(transactionToView);
    setOpenViewPopup(true);
  };

  const handleViewTransactionClosePopup = () => {
    setOpenViewPopup(false);
  };

  const showConfirmation = (headID) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRequest(headID);
      }
    });
  };
  

  const deleteRequest = async (headID) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API}/transaction/deleteTransactionsByHeadID/${headID}`
      );
  
      const updatedTransactions = rejectedTransactios.filter(
        (transaction) => transaction.headID !== headID
      );
      setRejectedTransactions(updatedTransactions);
  
      Swal.fire({
        title: 'Deleted!',
        text: 'Your transaction has been deleted.',
        icon: 'success',
        timer: 2000,
        showConfirmButton: false,
      });
      setPermissionToDelete(false);
    } catch (error) {
      console.error('Error deleting transaction:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error delete transaction',
      });
    }
  };
  

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleDateFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDateFilter = () => {
    setAnchorEl(null);
  };

  const filteredRequests = rejectedTransactios.filter((transaction) => {
    const project = getProjectNameById(transaction.headID)?.toLowerCase();
    const searchLowerCase = searchTerm.toLowerCase();
    const transactionDate = formatDate(transaction.createdAt);
    const isAfterStartDate =
      !startDate || transactionDate >= formatDate(startDate);
    const isBeforeEndDate = !endDate || transactionDate <= formatDate(endDate);

    const matchesSearchTerm =
      transaction.headID.toString().includes(searchTerm) ||
      transaction.requestID.toString().includes(searchTerm) ||
      (project && project.includes(searchTerm)) ||
      transaction.officerName.toLowerCase().includes(searchTerm) ||
      (transaction.totalCost &&
        transaction.totalCost.toString().includes(searchTerm)) ||
      transaction.status.toLowerCase().includes(searchTerm) ||
      (transaction.createdAt &&
        formatDate(transaction.createdAt)
          .toLowerCase()
          .includes(searchLowerCase)) ||
      (transaction.referenceNo && transaction.referenceNo.includes(searchTerm));
    return matchesSearchTerm && isAfterStartDate && isBeforeEndDate;
  });

  const deleteTransactionAccess = user.permissions.some(
    (permission) => permission === 1 || permission === 32
  );

  const offset = currentPage * rowPerPage;
  const paginatedTransactions = filteredRequests
    .slice(offset, offset + rowPerPage)
    .map((request) => ({
      id: request.headID,
      ...request,
    }));

  const columns = [
    {
      field: "headID",
      headerName: "Head ID",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.row.headID.toString()}>
          {params.row.headID}
        </Tooltip>
      ),
    },
    {
      field: "requestID",
      headerName: "Request ID",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.row.requestID.toString()}>
          {params.row.requestID}
        </Tooltip>
      ),
    },
    {
      field: "projectName",
      headerName: "Project Name",
      flex: 2,
      renderCell: (params) => (
        <Tooltip title={getProjectNameById(params.row.headID)}>
          {getProjectNameById(params.row.headID)}
        </Tooltip>
      ),
    },
    {
      field: "officerName",
      headerName: "Officer Name",
      flex: 2,
      renderCell: (params) => (
        <Tooltip title={params.row.officerName}>
          {params.row.officerName}
        </Tooltip>
      ),
    },
    {
      field: "totalCost",
      headerName: "Total Cost",
      flex: 1.5,
      align: "right",
      renderCell: (params) => (
        <Tooltip title={formatTotalCost(params.row.totalCost)}>
          {params.row.totalCost ? formatTotalCost(params.row.totalCost) : ""}
        </Tooltip>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.row.status}>{params.row.status}</Tooltip>
      ),
    },
    {
      field: "createdAt",
      headerName: "Recorded Date",
      flex: 1.5,
      renderCell: (params) => (
        <Tooltip title={formatDate(params.row.createdAt)}>
          {formatDate(params.row.createdAt)}
        </Tooltip>
      ),
    },

    {
      field: "action",
      headerName: "",
      flex: 0.5,
      renderCell: (params) => (
        <Tooltip title={"Rejected transaction info"}>
          <VisibilityIcon
            className="text-[orange] p-1 cursor-pointer"
            onClick={() =>
              handleViewRejectTransactionOpenPopup(params.row.headID)
            }
            style={{
              height: "20px",
              width: "20px",
            }}
          />
        </Tooltip>
      ),
    },
    {
      field: "delete",
      headerName: "",
      flex: 0.5,
      renderCell: (params) => (
        <Tooltip title={"Delete"}>
          {deleteTransactionAccess && (
            <DeleteOutlineTwoToneIcon
              onClick={() => showConfirmation(params.row.headID)}
              className="text-red-500 cursor-pointer"
              style={{
                width: "20px",
                height: "20px",
              }}
            />
          )}
        </Tooltip>
      ),
    },
  ];

  const getRowId = (row) => row.id;
  const getRowHeight = () => 39;

  return (
    <div>
      <div>
        <TransactionNavBar />
      </div>
      <div className="userTableContainer w-[95%] mt-5 ml-5 ">
        {(loading || loadingPageChange) && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "60%",
              transform: "translate(-50%, -50%)",
            }}
            className="z-50 bg-transparent"
          >
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
            <div className="mt-2">Loading...</div>
          </div>
        )}
        <div className=" shadow-md border-b-2">
          <div className="flex items-center justify-between p-2">
            <TextField
              type="text"
              placeholder="Search..."
              className="border border-gray-300 rounded px-3 py-1 w-[50%]"
              inputProps={{
                style: {
                  height: "23px",
                  padding: "5px",
                  fontSize: "13px",
                },
              }}
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <Tooltip title={"Date filter"}>
              <FilterListIcon
                onClick={handleDateFilterClick}
                className="mb-4 lg:mb-0 relative -ml-7 cursor-pointer"
              />
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseDateFilter}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem>
                <div className="p-4 w-72">
                  <div className="mb-4">
                    <label className="block text-xs font-semibold text-gray-700 mb-1">
                      Start Date
                    </label>
                    <input
                      type="text"
                      placeholder="Start Date"
                      className="border border-gray-300 rounded px-3 py-2 w-full focus:outline-none focus:border-blue-500 h-7 text-xs"
                      ref={(startDateInput) => {
                        if (startDateInput) {
                          flatpickr(startDateInput, {
                            onChange: (selectedDates) => {
                              setStartDate(selectedDates[0]);
                            },
                          });
                        }
                      }}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-xs font-semibold text-gray-700 mb-1">
                      End Date
                    </label>
                    <input
                      type="text"
                      placeholder="End Date"
                      className="border border-gray-300 rounded px-3 py-2 w-full focus:outline-none focus:border-blue-500 h-7 text-xs"
                      ref={(endDateInput) => {
                        if (endDateInput) {
                          flatpickr(endDateInput, {
                            onChange: (selectedDates) => {
                              setEndDate(selectedDates[0]);
                            },
                          });
                        }
                      }}
                    />
                  </div>

                  <p className="text-xs text-gray-600">
                    Select the start and end dates for filtering.
                  </p>
                </div>
              </MenuItem>
            </Menu>
          </div>
          <div style={{ height: "100%", width: "100%" }}>
            <DataGrid
              rows={paginatedTransactions}
              columns={columns.map((column) => ({
                ...column,
                headerClassName: "custom-header",
                cellClassName: "custom-cell",
              }))}
              pageSize={rowPerPage}
              page={currentPage}
              pagination={false}
              pageSizeOptions={[]}
              disablePrevButton={true}
              disableNextButton={true}
              hideFooter
              getRowId={getRowId}
              getRowHeight={getRowHeight}
            />
          </div>
        </div>
        <div className="pagination-container">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={Math.ceil(filteredRequests.length / rowPerPage)}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
            onPageChange={handlePageChange}
            containerClassName="pagination"
            activeClassName="active"
          />
        </div>
      </div>
      <ToastContainer />
      <ViewTransactionPopup
        open={openViewPopup}
        setOpen={setOpenViewPopup}
        title="Rejected Transaction Informations"
      >
        <ViewTransaction
          onClose={handleViewTransactionClosePopup}
          transaction={selectedTransactionView}
        />
      </ViewTransactionPopup>
    </div>
  );
};

export default RejectedTransactionTable;
