import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import AsyncSelect from "react-select/async";
import axios from "axios";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CancelIcon from "@mui/icons-material/Cancel";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../userContext";
import Tooltip from "@mui/material/Tooltip";
import { useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


const customStyles = {
  control: (provided) => ({
    ...provided,
    border: "none",
  }),
};

const NewTransaction = () => {
  const { projectID, requestID } = useParams();
  const [data, setData] = useState([
    {
      projectName: parseInt(projectID, 10),
      costType: "",
      amount: "",
      fileCounts: 0,
    },
  ]);

  useEffect(() => {
    loadProjectOptions("", setProjectOptions);
    loadCostOptions("", setCostOptions);
    loadOfficerOptions("", setOfficerOptions);
  }, []);

  const [projectOptions, setProjectOptions] = useState([]);
  const [costOptions, setCostOptions] = useState([]);
  const [officerOptions, setOfficerOptions] = useState([]);
  const [newTotalAmount, setTotalAmount] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [completedRows, setCompletedRows] = useState([]);
  const [isSaveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [fileCount, setFileCount] = useState(0);
  const [allFiles, setAllFiles] = useState([[]]);
  const navigate = useNavigate();
  const { user } = useUser();
  const [selectedOfficer, setSelectedOfficer] = useState("");
  const [referenceNo, setReferenceNo] = useState("");
  const [dateValidationError, setDateValidationError] = useState("");
  const [projectName, setProjectName] = useState("");

  const handleInputChange = (e, rowIndex, columnKey) => {
    const { value } = e;

    const updatedData = [...data];
    updatedData[rowIndex][columnKey] = value;

    if (columnKey === "amount") {
      const floatValue = parseFloat(value);

      if (floatValue < 0) {
        toast.warning("Amount cannot be negative");
        return;
      }
      updatedData[rowIndex][columnKey] = parseFloat(value);
      const newTotalAmount = updatedData.reduce(
        (total, row) => total + (parseFloat(row.amount) || 0),
        0
      );
      setTotalAmount(newTotalAmount);
    }

    setData(updatedData);

    const row = updatedData[rowIndex];

    if (Object.values(row).every((value) => value !== "")) {
      setCompletedRows(updatedData);
    }
  };

  const handleDateChange = (date) => {
    const currentDate = new Date();

    if (date > currentDate) {
      setSelectedDate(currentDate);
      setDateValidationError("Selected date cannot be a future date.");
    } else {
      setSelectedDate(date);
      setDateValidationError("");
    }
    //setSelectedDate(date);
  };

  const handleOfficerChange = (selectedOption) => {
    setSelectedOfficer(selectedOption);
  };

  const [referenceNoError, setReferenceNoError] = useState("");

  const handleReferenceNoChange = (e) => {
    const inputValue = e.target.value;
    const isValidReferenceNo = /^[a-zA-Z0-9,. ]+$/.test(inputValue);

    if (isValidReferenceNo || inputValue === "") {
      setReferenceNo(inputValue);
      setReferenceNoError("");
    } else {
      setReferenceNoError("Invalid characters in reference no.");
    }
  };

  const handleFileChange = (e, rowIndex) => {
    const selectedFiles = e.target.files;
  
    if (selectedFiles.length === 0) {
      toast.error("No file selected");
      return;
    }
  
    const updatedAllFiles = [...allFiles];
    const allowedFileTypes = ["pdf", "png", "jpg", "jpeg"];
  
    const updatedFiles = Array.from(selectedFiles).filter((file) => {
      const fileNameParts = file.name.split(".");
      const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();
      if (!allowedFileTypes.includes(fileExtension)) {
        toast.error("File type not allowed");
        return false;
      }
      return true;
    });
  
    updatedAllFiles[rowIndex] = updatedAllFiles[rowIndex]
      ? [...updatedAllFiles[rowIndex], ...updatedFiles]
      : updatedFiles;
  
    const updatedData = [...data];
    updatedData[rowIndex].fileCounts = updatedAllFiles[rowIndex].length;
  
    setAllFiles(updatedAllFiles);
    setCompletedRows(updatedData);
  };
  

  const updateArrayAtIndex = (index, newArray) => {
    setAllFiles((prevAllFiles) => {
      return prevAllFiles.map((arr, i) => (i === index ? newArray : arr));
    });
  };

  const handleFileDelete = (rowIndex, fileIndex) => {
    const updatedfileData = [...allFiles];
    const updatedRowData = [...data];
    updatedfileData[rowIndex].splice(fileIndex, 1);
    updatedRowData[rowIndex].fileCounts = updatedfileData[rowIndex].length;
    setCompletedRows(updatedRowData);
    setFileCount(updatedfileData[rowIndex].length);
    setAllFiles(updatedfileData);
  };

  const loadProjectOptions = (inputValue, callback) => {
    axios
      .get(`${process.env.REACT_APP_API}/project/getAllProjects`)
      .then((response) => {
        const projects = response.data.map((project) => ({
          value: project.projectID,
          label: project.projectName,
        }));
        callback(filterProject(inputValue, projects));
      })
      .catch((error) => {
        
      });
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/project/getAllProjects`)
      .then((response) => {
        const projects = response.data;

        const matchingProject = projects.find(
          (project) => project.projectID == projectID
        );

        
        if (matchingProject) {
          setProjectName(matchingProject.projectName);
        } else {
          console.error(
            `Debug - No project found with projectID: ${projectID}`
          );
        }
      })
      .catch((error) => {
        console.error("Debug - Error fetching projects:", error);
      });

    loadProjectOptions("", setProjectOptions);
    loadCostOptions("", setCostOptions);
    loadOfficerOptions("", setOfficerOptions);
  }, [projectID]);

  const loadCostOptions = (inputValue, callback) => {
    axios
      .get(`${process.env.REACT_APP_API}/cost/getAllCostTypes`)
      .then((response) => {
        const costTypes = response.data.map((costType) => ({
          value: costType.costID,
          label: costType.costType,
        }));
        callback(filterCost(inputValue, costTypes));
      })
      .catch((error) => {
        
      });
  };

  const loadOfficerOptions = (inputValue, callback) => {
    axios
      .get(`${process.env.REACT_APP_API}/officer/getAllOfficers`)
      .then((response) => {
        const officers = response.data.map((officer) => ({
          value: officer.officerID,
          label: officer.officerName,
        }));
        callback(filterOfficer(inputValue, officers));
      })
      .catch((error) => {
        
      });
  };

  const filterProject = (inputValue, projects) => {
    return projects.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const filterCost = (inputValue, costTypes) => {
    return costTypes.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const filterOfficer = (inputValue, officers) => {
    return officers.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const addNewRow = () => {
    setData([
      ...data,
      {
        projectName: parseInt(projectID, 10),
        costType: "",
        amount: "",
        fileCounts: 0,
      },
    ]);
    setAllFiles([...allFiles, []]);
  };

  const saveAllData = () => {
    setSaveButtonDisabled(true);

    const formData = new FormData();

    const requiredFieldsFilled = data.every(
      (row) =>
        row.projectName && row.costType && (row.amount || row.amount === 0.0)
    );

    if (!requiredFieldsFilled) {
      toast.error("Please fill in all the required fields.");
      setSaveButtonDisabled(false);
      return;
    }
    if (!user.officerName) {
      toast.error("Please select an officer.");
      setSaveButtonDisabled(false);
      return;
    }

    allFiles.forEach((files) => {
      files.forEach((file) => {
        formData.append(`file`, file);
      });
    });

    formData.append("totalCost", newTotalAmount);
    // formData.append("date", selectedDate.toISOString());
    formData.append("transactions", JSON.stringify(completedRows));
    formData.append("officer", user.officerName);
    formData.append("requestID", requestID);
    formData.append("referenceNo", referenceNo);
    formData.append("userId", user.username);
    
    if (completedRows.length > 0) {
      axios
        .post(
          `${process.env.REACT_APP_API}/transaction/saveTransaction`,
          formData
        )
        .then((response) => {
          Swal.fire({
            title: 'Success!',
            text: 'Transaction saved successfully.',
            icon: 'success',
            timer: 3000,
            showConfirmButton: false
          })
          setData([
            {
              projectName: parseInt(projectID, 10),
              costType: "",
              amount: "",
              fileCounts: 0,
            },
          ]);
          setTotalAmount(0);
          setSaveButtonDisabled(false);
          setSelectedDate(new Date());
          setAllFiles([[]]);

          setTimeout(() => {
            navigate("/transactions/pending");
          }, 3000);
        })
        .catch((error) => {
          console.error("Error saving data:", error);
          Swal.fire({
            title: 'Error!',
            text: 'Error sending transaction.',
            icon: 'error',
            timer: 3000,
            showConfirmButton: false
          })
          setSaveButtonDisabled(false);
        });
    }
  };

  const formatTotalAmount = (amount) => {
    return amount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const handleCancel = () => {
    if (data.length > 1) {
      setData(data.slice(0, -1));
      setAllFiles(allFiles.slice(0, -1));
    }
  };

  return (
    <div className="min-h-screen bg-gray-200">
      <div className="bg-[#111827] text-white p-4 h-14 fixed top-0 w-full z-10 flex">
        <Link to={"/cashAdvance/approval"}>
          <Tooltip title={"Back"}>
            <ArrowBackIcon className="ml-5 " />
          </Tooltip>
        </Link>
        <div className="ml-5 ">
          <h1 className="m-0 text-lg text-white font-serif">
            Add Transactions for Request ID: {requestID}
          </h1>
        </div>
      </div>

      <div className="flex items-center pt-20 m-5 ml-9 justify-items-start">
        <div className=" ">
          <input
            type="text"
            value={referenceNo}
            onChange={handleReferenceNoChange}
            className={`text-center border-gray-200 h-9 ${
              referenceNoError ? "border-red-500" : ""
            }`}
            placeholder="Reference no."
          />
          {referenceNoError && (
            <p className="text-red-500 text-xs absolute text-center">
              {referenceNoError}
            </p>
          )}
        </div>
      </div>

      <table className="p-10 mt-5 ml-9 bg-white border items-center">
        <thead className="border ">
          <tr className="text-sm text-white  h-10 bg-[#111827]">
            <th className="w-1/5 font-mono">Project Name</th>
            <th className="w-1/5 font-mono">Cost Type</th>
            <th className="font-mono">Amount (LKR)</th>
            <th className="font-mono">File</th>
          </tr>
        </thead>
        <tbody className="border">
          {data.map((row, rowIndex) => (
            <tr key={rowIndex} className="border" style={{ lineHeight: 1 }}>
              <td className="border text-center text-gray-500">
                {projectName}
              </td>

              <td className="border">
                <AsyncSelect
                  required
                  defaultOptions
                  className="selectOption h-[75%]"
                  loadOptions={loadCostOptions}
                  onChange={(selectedOption) =>
                    handleInputChange(selectedOption, rowIndex, "costType")
                  }
                  value={costOptions.find(
                    (option) => option.value === row.costType
                  )}
                  styles={customStyles}
                />
              </td>
              <td className="border">
                <input
                  type="number"
                  value={row.amount === "" ? "0.00" : row.amount}
                  onChange={(e) =>
                    handleInputChange(e.target, rowIndex, "amount")
                  }
                  className="w-full h-full p-2 text-right border-white"
                />
              </td>

              <td className="border">
                <div>
                  <label
                    htmlFor={`file-input-${rowIndex}`}
                    className="block w-full p-2 text-sm text-center text-gray-400 border border-dashed rounded-md cursor-pointer h-30"
                  >
                    Drag and drop files here, or click to select files
                    <input
                      type="file"
                      id={`file-input-${rowIndex}`}
                      multiple
                      onChange={(e) => handleFileChange(e, rowIndex)}
                      className="w-full h-full opacity-0"
                    />
                  </label>
                  {allFiles.length > 0 && (
                    <div className="w-2/3">
                      <h3 className="text-sm text-gray-400">Selected Files:</h3>
                      <ul className="list-disc list-inside">
                        {allFiles[rowIndex].map((file, fileIndex) => (
                          <li
                            key={fileIndex}
                            className="flex items-center my-2 text-xs"
                          >
                            {file.name}
                            <div className="flex flex-col items-start justify-start w-2 h-2">
                              <CancelIcon
                                onClick={() =>
                                  handleFileDelete(rowIndex, fileIndex)
                                }
                                className="ml-10 text-red-500 cursor-pointer"
                                style={{ fontSize: 16 }}
                              />
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="m-5 ml-9">
        <Button
          variant="outlined"
          onClick={addNewRow}
          startIcon={<AddIcon />}
          style={{ height: "30px", fontSize: "12px" }}
        >
          new line
        </Button>
      </div>
      <div className="m-5 ">
        <hr className="w-[35%] border-black ml-[64%]" />

        <p className="flex items-center justify-end p-5 text-3xl">
          TOTAL {formatTotalAmount(newTotalAmount)}
        </p>
        <hr className="w-[35%] border-black ml-[64%]" />

        <hr className="w-[35%] border-black mt-1 ml-[64%]" />
      </div>
      <div className="flex items-center justify-end mr-8 pb-10">
        <button
          variant="contained"
          onClick={saveAllData}
          disabled={isSaveButtonDisabled}
          style={{
            backgroundColor: isSaveButtonDisabled ? "#808080" : "#111827",
            color: "white",
            cursor: isSaveButtonDisabled ? "not-allowed" : "pointer",
            marginRight: "10px",
            height: "30px",
            fontSize: "12px",
            width: "100px",
          }}
          className="rounded focus:outline-none hover:shadow-md"
        >
          {isSaveButtonDisabled ? "SAVING..." : "SAVE ALL"}
        </button>
        <Button
          variant="outlined"
          onClick={handleCancel}
          style={{ height: "30px", fontSize: "12px" }}
        >
          Cancel
        </Button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default NewTransaction;
