import React from "react";

import Sidebar from "../../component/sidebar/sidebar";
import RejectedTable from "../cashAdvance/rejectedRequest";
import "./clientstyle.scss";

const Cash = () => {
  return (
    <div className="clients">
      <Sidebar />
      <div className="clientsContainer">
        <RejectedTable />
      </div>
    </div>
  );
};
export default Cash;
