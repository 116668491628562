import React, { useState, useEffect, useContext } from "react";
import "./sidebarstyle.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import ClassOutlinedIcon from "@mui/icons-material/ClassOutlined";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { useUser } from "../../userContext";


const Sidebar = () => {
  const navigate = useNavigate();
  const { user, setUser } = useUser();
  const [activeTab, setActiveTab] = useState({});
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;

    const tabMapping = {
      "/dashboard": "dashboard",
      "/projects": "projects",
      "/cashAdvance/approval": "cashAdvance",
      "/cashAdvance/pending": "cashAdvance",
      "/cashAdvance/rejected": "cashAdvance",
      "/transactions/approval": "transactions",
      "/transactions/pending": "transactions",
      "/transactions/rejected": "transactions",
      "/clients": "clients",
      "/officers": "officers",
      "/costType": "costType",
      "/user": "user",
    };

    setActiveTab(tabMapping[currentPath] || "dashboard");
  }, [location.pathname]);

  const handleLogout = async () => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API}/user/logout`,
        {},
        { withCredentials: true }
      );
      if (result.data.message === "Success") {
        navigate("/");
      }
    } catch (error) {
    
    }
  };

  const isAdmin = user.permissions.some((permission) => permission === 1);

  const dashboardView = user.permissions.some(
    (permission) => permission === 1 || permission === 33
  );

  return (
    <div className="lg:w-1/5 md:w-1/4 sm:w-1/3 w-full min-h-screen bg-[#111827] text-gray-200">
      <div className="pt-5 top px-5 ">
        <Link
          style={{ textDecoration: "none" }}
          className=" text-2xl font-bold inline-block relative overflow-hidden "
        >
          <span className="logo font-serif">
            <span className="text-[red]">S</span>pend{" "}
            <span className="text-[yellow]">W</span>ise
          </span>
          <span className="absolute top-0 left-0 w-full h-full bg-[#111827] opacity-80 animate-typing"></span>
        </Link>
      </div>
      <hr className="my-2 border-t border-gray-900" />
      <div className="p-5 center">
        <ul>
          <p className="mb-2 text-sm font-bold text-gray-500">MAIN</p>
          {dashboardView && (
            <Link
              to={"/dashboard"}
              style={{ textDecoration: "none" }}
              onClick={() => setActiveTab("dashboard")}
            >
              <li
                className={`flex items-center p-2 space-x-2 rounded-lg cursor-pointer hover:bg-gray-700 ${
                  activeTab === "dashboard" ? "bg-gray-700" : ""
                }`}
              >
                <DashboardIcon className="w-4 h-4 text-sky-500" />
                <span>Dashboard</span>
              </li>
            </Link>
          )}
          <Link
            to={"/projects"}
            style={{ textDecoration: "none" }}
            onClick={() => setActiveTab("projects")}
          >
            <li
              className={`flex items-center p-2 space-x-2 rounded-lg cursor-pointer hover:bg-gray-700 ${
                activeTab === "projects" ? "bg-gray-700" : ""
              }`}
            >
              <AccountTreeIcon className="w-4 h-4 text-emerald-500" />
              <span>Projects</span>
            </li>
          </Link>
          <Link
            to={"/cashAdvance/approval"}
            style={{ textDecoration: "none" }}
            onClick={() => setActiveTab("cashAdvance")}
          >
            <li
              className={`flex items-center p-2 space-x-2 rounded-lg cursor-pointer hover:bg-gray-700 ${
                activeTab === "cashAdvance" ? "bg-gray-700" : ""
              }`}
            >
              <RequestQuoteIcon className="w-4 h-4 text-red-600" />
              <span>Cash Advance</span>
            </li>
          </Link>
          <Link
            to={"/transactions/approval"}
            style={{ textDecoration: "none" }}
            onClick={() => setActiveTab("transactions")}
          >
            <li
              className={`flex items-center p-2 space-x-2 rounded-lg cursor-pointer hover:bg-gray-700 ${
                activeTab === "transactions" ? "bg-gray-700" : ""
              }`}
            >
              <AccountBalanceIcon className="w-4 h-4 text-pink-500" />
              <span>Transactions</span>
            </li>
          </Link>
          <Link
            to={"/clients"}
            style={{ textDecoration: "none" }}
            onClick={() => setActiveTab("clients")}
          >
            <li
              className={`flex items-center p-2 space-x-2 rounded-lg cursor-pointer hover:bg-gray-700 ${
                activeTab === "clients" ? "bg-gray-700" : ""
              }`}
            >
              <GroupIcon className="w-4 h-4 text-orange-500" />
              <span>Clients</span>
            </li>
          </Link>
          <Link
            to={"/officers"}
            style={{ textDecoration: "none" }}
            onClick={() => setActiveTab("officers")}
          >
            <li
              className={`flex items-center p-2 space-x-2 rounded-lg cursor-pointer hover:bg-gray-700 ${
                activeTab === "officers" ? "bg-gray-700" : ""
              }`}
            >
              <PersonIcon className="w-4 h-4 text-sky-500" />
              <span>Officers</span>
            </li>
          </Link>
          <Link
            to={"/costType"}
            style={{ textDecoration: "none" }}
            onClick={() => setActiveTab("costType")}
          >
            <li
              className={`flex items-center p-2 space-x-2 rounded-lg cursor-pointer hover:bg-gray-700 ${
                activeTab === "costType" ? "bg-gray-700" : ""
              }`}
            >
              <ClassOutlinedIcon className="w-4 h-4 text-emerald-500" />
              <span>Cost Types</span>
            </li>
          </Link>
          <p className="mt-4 mb-2 text-sm font-bold text-gray-500">USER</p>
          {isAdmin && (
            <Link
              to={"/user"}
              style={{ textDecoration: "none" }}
              onClick={() => setActiveTab("user")}
            >
              <li
                className={`flex items-center p-2 space-x-2 rounded-lg cursor-pointer hover:bg-gray-700 ${
                  activeTab === "user" ? "bg-gray-700" : ""
                }`}
              >
                <GroupAddIcon className="w-4 h-4" />
                <span>Users</span>
              </li>
            </Link>
          )}
          <li
            onClick={handleLogout}
            className="flex items-center p-2 space-x-2 rounded-lg cursor-pointer hover:bg-gray-700"
          >
            <LogoutOutlinedIcon className="w-4 h-4" />
            <span>LogOut</span>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default Sidebar;
