// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-header {
  background-color: #111827;
  color: white;
  font-family: "monospace", monospace !important;
  font-size: 12px;
  height: 40px !important;
}
.custom-header :hover {
  color: white;
}

.custom-cell {
  padding: 5px;
  font-size: 12px;
}

.custom-header-cell {
  height: 20px;
  line-height: 20px;
}

.your-custom-popup-class {
  z-index: 10000 !important; /* Adjust the z-index as needed */
}

.your-custom-input-class {
  z-index: 10001 !important; /* Make sure it's higher than the popup z-index */
}`, "",{"version":3,"sources":["webpack://./src/component/transaction/style.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,YAAA;EACA,8CAAA;EACA,eAAA;EACA,uBAAA;AACJ;AAAI;EACE,YAAA;AAEN;;AAEE;EACE,YAAA;EACA,eAAA;AACJ;;AACE;EACE,YAAA;EACA,iBAAA;AAEJ;;AAAE;EACE,yBAAA,EAAA,iCAAA;AAGJ;;AAAE;EACE,yBAAA,EAAA,iDAAA;AAGJ","sourcesContent":[".custom-header {\n    background-color: #111827;\n    color: white; \n    font-family: 'monospace', monospace !important;\n    font-size: 12px;\n    height: 40px !important;\n    :hover{\n      color: white;\n    }\n  }\n  \n  .custom-cell {\n    padding: 5px;\n    font-size: 12px; \n  }\n  .custom-header-cell {\n    height: 20px; \n    line-height: 20px; \n  }\n  .your-custom-popup-class {\n    z-index: 10000 !important; /* Adjust the z-index as needed */\n  }\n  \n  .your-custom-input-class {\n    z-index: 10001 !important; /* Make sure it's higher than the popup z-index */\n  }\n  \n\n   "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
