import React from "react";

import Sidebar from "../../component/sidebar/sidebar";
import CashAdvance from "../cashAdvance/approvalRequest";
import "./clientstyle.scss";

const Cash = () => {
  return (
    <div className="clients">
      <Sidebar />
      <div className="clientsContainer">
        <CashAdvance />
      </div>
    </div>
  );
};
export default Cash;
