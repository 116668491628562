import React from "react";

import Sidebar from "../../component/sidebar/sidebar";
import PendingRequest from "../cashAdvance/pendingRequest";
import "./clientstyle.scss";

const Cash = () => {
  return (
    <div className="clients">
      <Sidebar />
      <div className="clientsContainer">
        <PendingRequest />
      </div>
    </div>
  );
};
export default Cash;
