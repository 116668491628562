// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-swal {
  z-index: 99999;
}

.swal2-backdrop-show {
  z-index: 99998;
}`, "",{"version":3,"sources":["webpack://./src/pages/update/style.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ;;AAEE;EACE,cAAA;AACJ","sourcesContent":[".my-swal {\n    z-index: 99999; \n  }\n  \n  .swal2-backdrop-show {\n    z-index: 99998; \n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
