import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useUser } from "../../userContext";
import { Tooltip, Button } from "@mui/material";
import FileViewPopup from "../popup/viewPopup";
import FileView from "../../pages/view/fileView";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

import axios from "axios";

const ApprovedTransactionView = (props) => {
  const { transaction } = props;
  const { requestID } = useParams();
  const [requestDetails, setRequestDetails] = useState(null);
  const [projectName, setProjectName] = useState(null);
  const { user } = useUser();
  const [transactions, setTransactions] = useState([]);
  const [openViewPopup, setOpenViewPopup] = useState(false);
  const [selectedFileView, setSelectedFileView] = useState(null);

  useEffect(() => {
    const fetchRequestDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API}/cashRequest/getCashAdvanceRequest/${transaction.requestID}`
        );
        const data = await response.json();
        setRequestDetails(data);
        const projectResponse = await fetch(
          `${process.env.REACT_APP_API}/project/getAllProjects`
        );
        const projectData = await projectResponse.json();
        const project = projectData.find(
          (project) => project.projectID === data.projectID
        );
        setProjectName(project ? project.projectName : null);
      } catch (error) {
        console.error("Error fetching request details:", error);
      }
    };

    fetchRequestDetails();
  }, [requestID]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const transactionsData = await axios.get(
          `${process.env.REACT_APP_API}/transaction/getTransactionsById/${transaction.headID}`
        );

        const projectsData = await axios.get(
          `${process.env.REACT_APP_API}/project/getAllProjects`
        );
        const projectNamesMap = {};
        projectsData.data.forEach((project) => {
          projectNamesMap[project.projectID] = project.projectName;
        });

        const costTypesData = await axios.get(
          `${process.env.REACT_APP_API}/cost/getAllCostTypes`
        );
        const costTypeNamesMap = {};
        costTypesData.data.forEach((costType) => {
          costTypeNamesMap[costType.costID] = costType.costType;
        });

        const updatedTransactions = transactionsData.data.map(
          (transaction) => ({
            ...transaction,
            projectName: projectNamesMap[transaction.projectID] || "",
            costTypeName: costTypeNamesMap[transaction.costTypesID] || "",
          })
        );

        setTransactions(updatedTransactions);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [transaction.headID]);

  const formatTotalCost = (cost) => {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(cost);
  };
  const calculateTotalAmount = () => {
    let totalAmount = 0;
    transactions.forEach((transaction) => {
      totalAmount += parseFloat(transaction.amount);
    });
    return totalAmount;
  };

  const handleViewFileOpenPopup = (transactionID) => {
    const selectedTransaction = transactions.find(
      (transaction) => transaction.transactionID === transactionID
    );

    setOpenViewPopup(true);
    setSelectedFileView(selectedTransaction);
  };

  const handleViewFileClosePopup = () => {
    setOpenViewPopup(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  const requestTotal = requestDetails ? requestDetails.totalAmount / 100 : 0;

  const transactionTotal = calculateTotalAmount();

  const difference = Math.abs(requestTotal - transactionTotal);

  const isRequestMoreThanTransaction = requestTotal > transactionTotal;

  const paymentMessage = isRequestMoreThanTransaction
    ? `Officer ${
        transaction.officerName
      } needs to pay company LKR ${formatTotalCost(difference)}`
    : `Company needs to pay officer LKR ${formatTotalCost(difference)}`;

  return (
    <div className=" bg-gray-200 ">
      <div className="text-center bg-orange-400">
        <p className=" items-center justify-end p-2 text-sm text-white">
          {paymentMessage}
        </p>
      </div>

      <div className="flex">
        {requestDetails && (
          <>
            <div className="p-4 flex-1">
              <p className="text-sm font-semibold">Request Details</p>
              <table className="min-w-[100%] divide-y divide-gray-200 mb-4 mt-3 shadow-xl border border-t-4 border-t-[#111827] bg-gray-50">
                <thead></thead>
                <tbody className=" text-xs">
                  <tr className="">
                    <td className=" w-[30%] p-1 font-semibold">Request ID</td>
                    <td className=" p-1 text-gray-600">
                      {requestDetails.requestID}
                    </td>
                  </tr>
                  <tr className="">
                    <td className=" w-[30%] p-1 font-semibold">Project Name</td>
                    <td className=" p-1 text-gray-600">{projectName}</td>
                  </tr>
                  <tr>
                    <td className=" w-[30%] p-1 font-semibold">Officer Name</td>
                    <td className=" p-1 text-gray-600">
                      {requestDetails.officerName}
                    </td>
                  </tr>
                  <tr>
                    <td className=" w-[30%] p-1 font-semibold">
                      Projected Date for Expenses
                    </td>
                    <td className=" p-1 text-gray-600">
                      {requestDetails.startDate} to {requestDetails.endDate}
                    </td>
                  </tr>
                  <tr>
                    <td className=" w-[30%] p-1 font-semibold">
                      Required Date
                    </td>
                    <td className=" p-1 text-gray-600">
                      {requestDetails.requiredDate}
                    </td>
                  </tr>
                  <tr className="">
                    <td className=" w-[30%] p-1 font-semibold">
                      Reason for Request
                    </td>
                    <td className=" p-1 text-gray-600">
                      {requestDetails.reason}
                    </td>
                  </tr>

                  <tr className="">
                    <td className=" w-[30%] p-1 font-semibold">Total Amount</td>
                    <td className=" p-1 text-gray-600">
                      {formatTotalCost(requestDetails.totalAmount / 100)} LKR
                    </td>
                  </tr>
                  <tr className="">
                    <td className=" w-[30%] p-1 font-semibold">
                      Request Status
                    </td>
                    <td className=" text-green-500 p-1">
                    {requestDetails.requestStatus === "Approval" ? "Approved" : requestDetails.requestStatus}
                    </td>
                  </tr>
                  <tr className="">
                    <td className=" w-[30%] p-1 font-semibold">
                      Payment Status
                    </td>
                    <td className="text-green-500 p-1">
                      {requestDetails.paymentStatus}
                    </td>
                  </tr>
                </tbody>
              </table>

              <h3 className="text-xs font-semibold mt-4">Transport </h3>
              <table className="min-w-full divide-y divide-gray-200 mb-4 border bg-white">
                <thead className="bg-[#111827] text-gray-400 text-xs p-2">
                  <tr className="border">
                    <th className="border font-mono">Transport type</th>
                    <th className="border font-mono">Km</th>
                    <th className="border font-mono">Total</th>
                  </tr>
                </thead>
                <tbody className="text-xs text-gray-600">
                  {requestDetails.TransportCosts &&
                    requestDetails.TransportCosts.map((transport) => (
                      <tr key={transport.transportId}>
                        <td className="border text-center">{transport.type}</td>
                        <td className="border text-center">
                          {transport.miles}
                        </td>
                        <td className="border  text-center">
                          {formatTotalCost(transport.amount / 100)}
                        </td>
                      </tr>
                    ))}
                  <tr>
                    <td></td>
                    <td></td>
                    <td>
                      <div className="text-xs mt-5 text-end mb-5 mr-5">
                        {/* <hr className="w-[80%] border-black ml-[20%] " /> */}
                        <p className="">Total</p>{" "}
                        {formatTotalCost(requestDetails.transportTotal / 100)}
                        {/* <hr className="w-[80%] border-black ml-[20%]" />
                      <hr className="w-[80%] border-black ml-[20%] mt-1" /> */}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <h3 className="text-xs font-semibold mt-4">Accommodation </h3>
              <table className="min-w-full divide-y divide-gray-200 mb-4 border bg-white">
                <thead className="bg-[#111827] text-gray-400 text-xs">
                  <tr>
                    <th className="border font-mono">Date</th>
                    <th className="border font-mono">No of Heads</th>
                    <th className="border font-mono">Amount Per Night</th>
                    <th className="border font-mono">Total Per Day</th>
                  </tr>
                </thead>
                <tbody className="text-xs text-gray-600">
                  {requestDetails.AccomadationCosts &&
                    requestDetails.AccomadationCosts.map((accommodation) => (
                      <tr key={accommodation.accomadationID}>
                        <td className="border  text-center">
                          {accommodation.date}
                        </td>
                        <td className="border  text-center">
                          {accommodation.noOfHeads}
                        </td>
                        <td className="border  text-center">
                          {formatTotalCost(accommodation.amountPerNight / 100)}
                        </td>
                        <td className="border  text-center">
                          {formatTotalCost(accommodation.amount / 100)}
                        </td>
                      </tr>
                    ))}
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <div className="text-xs mt-5 text-end mb-5 mr-5">
                        {/* <hr className="w-[150%] border-black ml-[10%] " /> */}
                        <p className="">Total</p>{" "}
                        {formatTotalCost(
                          requestDetails.accomadationTotal / 100
                        )}
                        {/* <hr className="w-[150%] border-black ml-[10%]" />
                      <hr className="w-[150%] border-black ml-[10%] mt-1" /> */}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <h3 className="text-xs font-semibold mt-4">Meal</h3>
              <table className="min-w-full divide-y divide-gray-200 mb-4 border bg-white">
                <thead className="bg-[#111827] text-gray-400 text-xs">
                  <tr>
                    <th className="border font-mono">Date</th>
                    <th className="border font-mono">No of Heads</th>
                    <th className="border font-mono">Total Per Day</th>
                  </tr>
                </thead>
                <tbody className="text-xs text-gray-600">
                  {requestDetails.MealCosts &&
                    requestDetails.MealCosts.map((meal) => (
                      <tr key={meal.mealID}>
                        <td className="border  text-center">{meal.date}</td>
                        <td className="border  text-center">
                          {meal.noOfHeads}
                        </td>
                        <td className="border  text-center">
                          {formatTotalCost(meal.amount / 100)}
                        </td>
                      </tr>
                    ))}
                  <tr>
                    <td></td>
                    <td></td>
                    <td>
                      <div className="text-xs mt-5 text-end mb-5 mr-5">
                        {/* <hr className="w-[35%] border-black ml-[65%] " /> */}
                        <p className="">Total</p>{" "}
                        {formatTotalCost(requestDetails.mealTotal / 100)}
                        {/* <hr className="w-[35%] border-black ml-[65%]" />
                      <hr className="w-[35%] border-black ml-[65%] mt-1" /> */}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <h3 className="text-xs font-semibold mt-4">Other</h3>
              <table className="min-w-full divide-y divide-gray-200 mb-4 border bg-white">
                <thead className="bg-[#111827] text-gray-400 text-xs">
                  <tr>
                    <th className="border font-mono">Reason</th>
                    <th className="border font-mono">Amount</th>
                  </tr>
                </thead>
                <tbody className="text-xs text-gray-600">
                  {requestDetails.OtherCosts &&
                    requestDetails.OtherCosts.map((other) => (
                      <tr key={other.otherID}>
                        <td className="border  text-center">{other.reason}</td>
                        <td className="border  text-center">
                          {formatTotalCost(other.amount)}
                        </td>
                      </tr>
                    ))}
                  <tr>
                    <td></td>
                    <td>
                      {requestDetails.OtherCosts &&
                        requestDetails.OtherCosts.map((other) => (
                          <div
                            className="text-xs mt-5 text-end mb-5 mr-5"
                            key={other.otherID}
                          >
                            {/* <hr className="w-[25%] border-black ml-[75%] " /> */}
                            <p className="">Total</p>{" "}
                            {formatTotalCost(other.amount)}
                            {/* <hr className="w-[25%] border-black ml-[75%]" />
                          <hr className="w-[25%] border-black ml-[75%] mt-1" /> */}
                          </div>
                        ))}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="p-4 flex-1">
              <p className="text-sm font-semibold ">Transaction Details</p>
              {transactions.length > 0 && (
                <table className="min-w-[100%] divide-y divide-gray-200 mb-4 mt-3 shadow-xl border border-t-4 border-t-[#111827] bg-gray-50">
                  <thead></thead>
                  <tbody className=" text-xs">
                    <tr className="">
                      <td className=" w-[30%] p-1 pl-3 font-semibold">
                        Request ID
                      </td>
                      <td className=" p-1 text-gray-600">
                        {transaction.requestID}
                      </td>
                    </tr>
                    <tr className="">
                      <td className=" w-[30%] p-1 pl-3 font-semibold">
                        Head ID
                      </td>
                      <td className=" p-1 text-gray-600">
                        {transaction.headID}
                      </td>
                    </tr>
                    <tr className="">
                      <td className=" w-[30%] p-1 pl-3 font-semibold">
                        Project Name
                      </td>
                      <td className=" p-1 text-gray-600">
                        {transactions[0].projectName}
                      </td>
                    </tr>
                    <tr className="">
                      <td className=" w-[30%] p-1 pl-3 font-semibold">
                        Officer Name
                      </td>
                      <td className=" p-1 text-gray-600">
                        {transaction.officerName}
                      </td>
                    </tr>
                    <tr>
                      <td className=" w-[30%] p-1 pl-3 font-semibold">
                        Recorded Date
                      </td>
                      <td className=" p-1">
                        {formatDate(transactions[0].createdAt)}
                      </td>
                    </tr>
                    <tr>
                      <td className=" w-[30%] p-1 pl-3 font-semibold">
                        Reference No
                      </td>
                      <td className=" p-1 text-gray-600">
                        {transaction.refferenceNo}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
              <div className="items-center justify-between mt-5">
                <table className="min-w-full divide-y divide-gray-200 mb-4 border bg-white shadow-md">
                  <thead className="bg-[#111827] text-gray-400 text-xs p-2">
                    <tr>
                      <th className="font-mono">Transaction ID</th>
                      <th className="text-right font-mono">Amount</th>
                      <th className="font-mono">Cost Type Name</th>
                      <th className="font-mono">Documents</th>
                    </tr>
                  </thead>
                  <tbody className="text-xs text-gray-600">
                    {transactions.map((transaction) => (
                      <tr key={transaction.transactionID} className="border">
                        <td className="p-1 text-center">
                          {transaction.transactionID}
                        </td>
                        <td className="p-1 text-right">
                          {formatTotalCost(transaction.amount)}
                        </td>

                        <td className="p-1 text-center">
                          <div className="">{transaction.costTypeName}</div>
                        </td>
                        <td className="p-1">
                          {transaction.receipts !== undefined && (
                            <div className="flex items-center justify-center p-1 text-2xl">
                              <Tooltip title={"Transaction documents"}>
                                <Button
                                  startIcon={<InsertDriveFileIcon />}
                                  variant="outlined"
                                  onClick={() =>
                                    handleViewFileOpenPopup(
                                      transaction.transactionID
                                    )
                                  }
                                  style={{ height: "28px" }}
                                >
                                  {transaction.receipts.length}
                                </Button>
                              </Tooltip>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="m-5 ">
                <hr className="w-[70%] border-black ml-[35%]" />

                <p className="flex items-center justify-end p-2 text-sm text-gray-600">
                  TOTAL {formatTotalCost(calculateTotalAmount())}
                </p>
                <hr className="w-[70%] border-black ml-[35%]" />

                <hr className="w-[70%] border-black mt-1 ml-[35%]" />
              </div>
            </div>
          </>
        )}
        <ToastContainer />
        <FileViewPopup
          open={openViewPopup}
          setOpen={setOpenViewPopup}
          title="Transaction Documents"
        >
          <FileView
            onClose={handleViewFileClosePopup}
            transaction={selectedFileView}
          />
        </FileViewPopup>
      </div>
    </div>
  );
};

export default ApprovedTransactionView;
