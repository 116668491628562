import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Tooltip, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import FileViewPopup from "../popup/viewPopup";
import FileView from "../../pages/view/fileView";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { useUser } from "../../userContext";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import ErrorIcon from "@mui/icons-material/Error";

const TransactionTable = (props) => {
  const { transaction } = props;
  const { user } = useUser();
  const { headID } = useParams();
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState([]);
  const [openViewPopup, setOpenViewPopup] = useState(false);
  const [selectedFileView, setSelectedFileView] = useState(null);
  const [isApproveButtonDisabled, setApproveButtonDisabled] = useState(false);
  const [isRejectButtonDisabled, setRejectButtonDisabled] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [permissionToApprove, setPermissionToApprove] = useState(false);
  const [permissionToReject, setPermissionToReject] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const transactionsData = await axios.get(
          `${process.env.REACT_APP_API}/transaction/getTransactionsById/${transaction.headID}`
        );

        const projectsData = await axios.get(
          `${process.env.REACT_APP_API}/project/getAllProjects`
        );
        const projectNamesMap = {};
        projectsData.data.forEach((project) => {
          projectNamesMap[project.projectID] = project.projectName;
        });

        const costTypesData = await axios.get(
          `${process.env.REACT_APP_API}/cost/getAllCostTypes`
        );
        const costTypeNamesMap = {};
        costTypesData.data.forEach((costType) => {
          costTypeNamesMap[costType.costID] = costType.costType;
        });

        const userResponse = await axios.get(
          `${process.env.REACT_APP_API}/user/allUsers`
        );
        const usersMap = {};
        userResponse.data.forEach((user) => {
          usersMap[user.username] = user.officerName;
        });

        const updatedTransactions = transactionsData.data.map(
          (transaction) => ({
            ...transaction,
            projectName: projectNamesMap[transaction.projectID] || "",
            costTypeName: costTypeNamesMap[transaction.costTypesID] || "",
            officerUsername: usersMap[transaction.user] || "",
          })
        );

        setTransactions(updatedTransactions);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [headID]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  const handleViewFileOpenPopup = (transactionID) => {
    const selectedTransaction = transactions.find(
      (transaction) => transaction.transactionID === transactionID
    );

    setOpenViewPopup(true);
    setSelectedFileView(selectedTransaction);
  };

  const handleViewFileClosePopup = () => {
    setOpenViewPopup(false);
  };

  const formatTotalCost = (cost) => {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(cost);
  };

  const showConfirmation = () => {
    Swal.fire({
      title: "Do you want approve this transation?",
      //text: message,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#32cd32",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, approve it!",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        approveRequest();
      }
    });
  };
  const approveRequest = async () => {
    setApproveButtonDisabled(true);
    try {
      await axios.put(
        `${process.env.REACT_APP_API}/transaction/updateRequestStatus/${transaction.headID}`,
        { newStatus: "Approval" }
      );

      setApproveButtonDisabled(false);

      Swal.fire({
        title: "Approved!",
        text: "The transaction has been approved successfully.",
        icon: "success",
        timer: 3000,
        showConfirmButton: false,
      }).then(() => {
        navigate("/transactions/approval");
      });
    } catch (error) {
      console.error("Error approving request:", error);
      setApproveButtonDisabled(false);
    }
    setPermissionToApprove(false);
  };

  const confirmRejectRequest = async () => {
    setRejectButtonDisabled(true);

    try {
      if (!rejectReason) {
        setError("You need to provide a reason!");
        setRejectButtonDisabled(false);
        return;
      }

      setError("");

      await axios.put(
        `${process.env.REACT_APP_API}/transaction/updateRequestStatus/${transaction.headID}`,
        {
          newStatus: "Rejected",
          reason: rejectReason,
          officerName: user.officerName,
        }
      );

      Swal.fire({
        icon: "success",
        title: "Transaction Rejected!",
        text: "The transaction has been rejected successfully.",
        timer: 3000,
        showConfirmButton: false,
      });
      setRejectReason("");

      setTimeout(() => {
        navigate("/transactions/rejected");
      }, 1000);
      setPermissionToReject(false);
    } catch (error) {
      console.error("Error rejecting transaction:", error);
      setError("Error rejecting transaction. transaction try again.");
      Swal.fire({
        icon: "error",
        title: "Error to reject transaction",
      });
    } finally {
      setRejectButtonDisabled(false);
    }
  };

  const rejectRequest = async () => {
    setPermissionToReject(true);
  };

  const cancelRejection = () => {
    setPermissionToReject(false);
  };

  const calculateTotalAmount = () => {
    let totalAmount = 0;
    transactions.forEach((transaction) => {
      totalAmount += parseFloat(transaction.amount);
    });
    return totalAmount;
  };

  const approveButtonAccess = user.permissions.some(
    (permission) => permission === 1 || permission === 30
  );
  const regectButtonAccess = user.permissions.some(
    (permission) => permission === 1 || permission === 31
  );

  const officerName = transactions.length > 0 ? transactions[0].user : null;

  const isCurrentUserRequestedUser = user.username === officerName;

  return (
    <div className=" bg-gray-200 h-full ">
      {permissionToReject && (
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-4 rounded-md shadow-md text-center">
            <p className="text-3xl mb-2 text-center text-gray-600 mt-3 ml-16 mr-16 font-semibold">
              Do you want to reject this
              <br /> transaction?
            </p>

            <p className="text-center text-gray-500 mt-5 mb-4">
              Please provide a reason for rejection:
            </p>
            <div className="mr-2 ml-2 items-center text-center">
              <textarea
                placeholder="Reason for rejection"
                value={rejectReason}
                onChange={(e) => setRejectReason(e.target.value)}
                className="w-full h-28 p-2 border mb-2"
              />
            </div>
            {error && (
              <div className="bg-gray-100 h-10 w-full text-sm mt-3 mb-3">
                <p className="text-gray-600 p-2">
                  <ErrorIcon className="text-red-400 mr-1" />
                  {error}
                </p>
              </div>
            )}
            <button
              onClick={confirmRejectRequest}
              className=" text-white px-4 py-2 mr-2 rounded focus:outline-none mt-2 mb-2"
              style={{
                backgroundColor: isRejectButtonDisabled
                  ? "#808080"
                  : "rgb(239 68 68 / var(--tw-bg-opacity))",
                cursor: isRejectButtonDisabled ? "not-allowed" : "pointer",
              }}
            >
              Yes
            </button>
            <button
              onClick={cancelRejection}
              className="bg-[#3085d6] text-white px-4 py-2 rounded focus:outline-none  mt-2 mb-2"
            >
              No
            </button>
          </div>
        </div>
      )}
      <div className="p-4">
        <table className="min-w-[100%] divide-y divide-gray-200 mb-4 mt-5 shadow-xl border border-t-4 border-t-[#111827] bg-gray-50">
          <thead></thead>
          <tbody className=" text-sm">
            {transactions.length > 0 && (
              <tr className="">
                <td className=" w-[20%] p-1 pl-3 font-semibold">
                  Project Name
                </td>
                <td className=" p-1 text-gray-600">
                  {transactions[0].projectName}
                </td>
              </tr>
            )}
            <tr className="">
              <td className=" w-[20%] p-1 pl-3 font-semibold">Officer Name</td>
              <td className=" p-1 text-gray-600">{transaction.officerName}</td>
            </tr>
            <tr>
              <td className=" w-[20%] p-1 pl-3 font-semibold">Recorded Date</td>
              <td className=" p-1 text-gray-600">
                {formatDate(transaction.createdAt)}
              </td>
            </tr>
            <tr>
              <td className=" w-[20%] p-1 pl-3 font-semibold">Reference No</td>
              <td className=" p-1 text-gray-600">{transaction.refferenceNo}</td>
            </tr>
          </tbody>
        </table>

        <div className="items-center justify-between mt-5">
          <table className="min-w-full divide-y divide-gray-200 mb-4 border bg-white shadow-md">
            <thead className="bg-[#111827] text-gray-400 text-sm p-2">
              <tr>
                <th className="font-mono">Transaction ID</th>
                <th className="text-right font-mono">Amount</th>
                <th className="font-mono">Cost Type Name</th>
                <th className="font-mono">Documents</th>
              </tr>
            </thead>
            <tbody className="text-sm text-gray-600">
              {transactions.map((transaction) => (
                <tr key={transaction.transactionID} className="border">
                  <td className="p-1 text-center">
                    {transaction.transactionID}
                  </td>
                  <td className="p-1 text-right">
                    {formatTotalCost(transaction.amount)}
                  </td>

                  <td className="p-1 text-center">
                    <div className="">{transaction.costTypeName}</div>
                  </td>
                  <td className="p-1">
                    {transaction.receipts !== undefined && (
                      <div className="flex items-center justify-center p-1 text-2xl">
                        <Tooltip title={"Transaction documents"}>
                          <Button
                            startIcon={<InsertDriveFileIcon />}
                            variant="outlined"
                            onClick={() =>
                              handleViewFileOpenPopup(transaction.transactionID)
                            }
                            style={{ height: "28px" }}
                          >
                            {transaction.receipts.length}
                          </Button>
                        </Tooltip>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="m-5 ">
          <hr className="w-[25%] border-black ml-[76.5%]" />

          <p className="flex items-center justify-end p-3 text-2xl text-gray-600">
            TOTAL {formatTotalCost(calculateTotalAmount())}
          </p>
          <hr className="w-[25%] border-black ml-[76.5%]" />

          <hr className="w-[25%] border-black mt-1 ml-[76.5%]" />
        </div>
        <div className="mt-10 flex space-x-4 justify-end items-center mb-5">
          {approveButtonAccess && (
            <button
              className={` text-white px-4 rounded focus:outline-none focus:shadow-outline text-sm  ${
                isApproveButtonDisabled || isCurrentUserRequestedUser
                  ? "bg-gray-500 cursor-not-allowed"
                  : "bg-[#00cc00] hover:bg-green-700 hover:shadow-md"
              }`}
              onClick={showConfirmation}
              disabled={isApproveButtonDisabled || isCurrentUserRequestedUser}
              style={{
                height: "30px",
                width: "100px",
              }}
            >
              {isApproveButtonDisabled ? "APPROVING..." : "APPROVE"}
            </button>
          )}
          {regectButtonAccess && (
            <button
              className={`  text-white px-4 rounded focus:outline-none focus:shadow-outline text-sm ${
                isRejectButtonDisabled || isCurrentUserRequestedUser
                  ? "bg-gray-500 cursor-not-allowed"
                  : "bg-[red] hover:bg-red-700 hover:shadow-md"
              }`}
              onClick={rejectRequest}
              disabled={isRejectButtonDisabled || isCurrentUserRequestedUser}
              style={{
                height: "30px",
                width: "100px",
              }}
            >
              REJECT
            </button>
          )}
        </div>
      </div>
      <ToastContainer />
      <FileViewPopup
        open={openViewPopup}
        setOpen={setOpenViewPopup}
        title="Transaction Documents"
      >
        <FileView
          onClose={handleViewFileClosePopup}
          transaction={selectedFileView}
        />
      </FileViewPopup>
    </div>
  );
};

export default TransactionTable;
