import Home from "./pages/home/home";
import Login from "./pages/login/login";
import List from "./pages/list/list";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Transactions from "./pages/list/transactions";
import NewTransaction from "./pages/newTransaction/newTransaction";
import Clients from "./pages/list/clients";
import Officers from "./pages/list/officer";
import User from "./pages/user/user";
import CostType from "./pages/list/costType";
import MainLayout from "./Mainlayout/MainLayout";
import UserProvider from "./userContext";
import TransactionView from "./component/transaction/pendingTransactionView";
import CashAdvance from "./pages/list/cashAdvance";
import PendingCashAdvance from "./pages/list/pendingCashAdvance";
import RejectedCashAdvance from "./pages/list/rejectedCashAdvance";
import CashAdvanceRequestForm from "./pages/requestForm/cashAdvanceRequestForm";
import RequestView from "./pages/cashAdvance/projectManagerView";
import AprroalView from "./pages/cashAdvance/approvedView";
import PendingTransactions from "./pages/list/pendingTransactions";
import RejectedTransactions from "./pages/list/rejectedTransactions";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <UserProvider>
          <Routes>
            <Route path="/" Component={Login} />
            <Route
              path="/dashboard"
              element={
                <MainLayout>
                  <Home />
                </MainLayout>
              }
            />
            <Route
              path="/projects"
              element={
                <MainLayout>
                  <List />
                </MainLayout>
              }
            />
            <Route
              path="/transactions/approval"
              element={
                <MainLayout>
                  <Transactions />
                </MainLayout>
              }
            />
            <Route
              path="/transactions/pending"
              element={
                <MainLayout>
                  <PendingTransactions />
                </MainLayout>
              }
            />
            <Route
              path="/transactions/rejected"
              element={
                <MainLayout>
                  <RejectedTransactions />
                </MainLayout>
              }
            />
            <Route
              path="/newTransaction/:projectID/:requestID"
              element={
                <MainLayout>
                  <NewTransaction />
                </MainLayout>
              }
            />
            <Route
              path="transactions/pending/transactionView/:headID"
              element={
                <MainLayout>
                  <TransactionView />
                </MainLayout>
              }
            />
            <Route
              path="/clients"
              element={
                <MainLayout>
                  <Clients />
                </MainLayout>
              }
            />

            <Route
              path="/officers"
              element={
                <MainLayout>
                  <Officers />
                </MainLayout>
              }
            />
            <Route
              path="/costType"
              element={
                <MainLayout>
                  <CostType />
                </MainLayout>
              }
            />
            <Route
              path="/user"
              element={
                <MainLayout>
                  <User />
                </MainLayout>
              }
            />
            <Route
            path="/cashAdvance/approval"
            element={
              <MainLayout>
                <CashAdvance/>
              </MainLayout>
            }
            />
            <Route
            path="/cashAdvance/pending"
            element={
              <MainLayout>
                <PendingCashAdvance/>
              </MainLayout>
            }
            />
            <Route
            path="/cashAdvance/rejected"
            element={
              <MainLayout>
                <RejectedCashAdvance/>
              </MainLayout>
            }
            />
            <Route
            path="/newCashAdvanceRequest"
            element={
              <MainLayout>
                <CashAdvanceRequestForm/>
              </MainLayout>
            }
            />
            <Route
            path="/cashAdvance/pending/viewRequest/:requestID"
            element={
              <MainLayout>
                <RequestView/>
              </MainLayout>
            }
            />
            <Route
            path="/cashAdvance/approval/viewRequest/:requestID"
            element={
              <MainLayout>
                <AprroalView/>
              </MainLayout>
            }
            />
          </Routes>
        </UserProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
