import React from "react";
import { useUser } from "./userContext";
import { Navigate } from "react-router-dom";

export default function UserRestriction({ children }) {
  const { user } = useUser();
  if (!user) {
    return <Navigate to={"/"} />;
  }
  return <>{children}</>;
}
