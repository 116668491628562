import React, { useEffect, useState } from "react";
import "./featured.scss";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import axios from "axios";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

const Featured = () => {
  const [totalCost, setTotalCost] = useState(0);
  const [totalMaxBudget, setTotalMaxBudget] = useState(0);
  const [totalCostLastMonth, setTotalCostLastMonth] = useState(0);
  const [totalCostCurrentMonth, setTotalCostCurrentMonth] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const lastMonthResponse = await axios.get(
          `${process.env.REACT_APP_API}/transaction/totalCostLastMonth`
        );
        setTotalCostLastMonth(lastMonthResponse.data.totalCostLastMonth);

        const currentMonthResponse = await axios.get(
          `${process.env.REACT_APP_API}/transaction/totalCostCurrentMonth`
        );
        setTotalCostCurrentMonth(
          currentMonthResponse.data.totalCostCurrentMonth
        );

        setTimeout(() => {
          setLoading(false);
        }, 1500);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchAllTransactionAmounts = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/transaction/getAllApprovalTransactions`
        );

        const transactions = response.data;

        const totalTransactionAmount = transactions.reduce(
          (sum, transaction) => {
            const transactionSum = transaction.transactions.reduce(
              (sum, t) => sum + t.amount,
              0
            );
            return sum + transactionSum;
          },
          0
        );

        setTotalCost(totalTransactionAmount);
      } catch (error) {
        console.error("Error fetching all transactions:", error);
      }
    };

    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/project/getAllProjects`
        );

        const projects = response.data;
        const maxBudgetSum = projects.reduce(
          (sum, project) => sum + project.maxBudget,
          0
        );
        setTotalMaxBudget(maxBudgetSum);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchAllTransactionAmounts();
    fetchProjects();
  }, []);

  const percentage = (totalCost / totalMaxBudget) * 100;

  const formatTotalAmount = (amount) => {
    return amount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  return (
    <div className="featured bg-white rounded-lg">
      {loading && (
        <div
          style={{
            position: "absolute",
            top: "32%",
            left: "34%",
            transform: "translate(-50%, -50%)",
          }}
          className="z-50 bg-transparent"
        >
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
          <div className="mt-2">Loading...</div>
        </div>
      )}
      
      <div>
      <div className="top">
        <h1 className="text-sm font-bold text-gray-400 font-mono">TOTAL TRANSACTIONS</h1>
      </div>
      {!loading && (
      <div className="bottom">
        <div className="featuredChart">
          <CircularProgressbar
            value={percentage}
            text={`${percentage.toFixed(2)}%`}
            strokeWidth={5}
          />
        </div>
        <p className="title">Total Transactions</p>
        <p className="text-lg font-light">LKR {formatTotalAmount(totalCost)}</p>
        <p className="description text-xs text-gray-500">
          Previous transactions processing. Last transaction{" "}
          <p className="text-center">may not be included.</p>
        </p>
        <div className="summary flex justify-between items-end">
          <div className="item">
            <div className="itemTitle">This Month</div>
            <div
              className={`itemResult ${
                totalCostCurrentMonth > totalCostLastMonth
                  ? "negative"
                  : "positive"
              }`}
            >
              <ArrowDropUpOutlinedIcon fontSize="small" />
              <div className="itemAmount">
                LKR {formatTotalAmount(totalCostCurrentMonth)}{" "}
              </div>
            </div>
          </div>
          <div className="item">
            <div className="itemTitle">Last Month</div>
            <div
              className={`itemResult ${
                totalCostLastMonth > totalCostCurrentMonth
                  ? "negative"
                  : "positive"
              }`}
            >
              <ArrowDropUpOutlinedIcon fontSize="small" />
              <div className="itemAmount">
                LKR {formatTotalAmount(totalCostLastMonth)}
              </div>
            </div>
          </div>
        </div>
      </div>
      )}
      </div>
       
    </div>
  );
};
export default Featured;
