import React, { useState, useEffect } from "react";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { Link } from "react-router-dom";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";

const Widget = ({ type }) => {
  const [dataValue, setDataValue] = useState(0);

  
  useEffect(() => {
    switch (type) {
      case "projects":
        
        fetch(`${process.env.REACT_APP_API}/project/getAllProjects`)
          .then((response) => response.json())
          .then((data) => {
            const numberOfProjects = data.length; 
            setDataValue(numberOfProjects);
          })
          .catch((error) => {
            console.error("Error fetching project data:", error);
          });
        break;

        case "transactions":
          fetch(`${process.env.REACT_APP_API}/transaction/getAllApprovalTransactions`)
            .then((response) => response.json())
            .then((data) => {
              const totalTransactionAmount = data.reduce(
                (sum, transaction) => {
                  const transactionSum = transaction.transactions.reduce(
                    (sum, t) => sum + t.amount,
                    0
                  );
                  return sum + transactionSum;
                },
                0
              );
    
              setDataValue(totalTransactionAmount.toFixed(2));
            })
            .catch((error) => {
              console.error("Error fetching transaction data:", error);
            });
          break;
        
        fetch(`${process.env.REACT_APP_API}/transaction/totalTransactions`)
          .then((response) => response.json())
          .then((data) => {
            
            const transactionSum = data.reduce(
              (sum, transaction) => sum + transaction.amount,
              0
            );
            setDataValue(transactionSum.toFixed(2)); 
          })
          .catch((error) => {
            console.error("Error fetching transaction data:", error);
          });
        break;

      case "clients":
        
        fetch(`${process.env.REACT_APP_API}/client/getAllClients`)
          .then((response) => response.json())
          .then((data) => {
            
            const numberOfClients = data.length;
            setDataValue(numberOfClients);
          })
          .catch((error) => {
            console.error("Error fetching client data:", error);
          });
        break;

      case "officers":
        
        fetch(`${process.env.REACT_APP_API}/officer/getAllOfficers`)
          .then((response) => response.json())
          .then((data) => {
            
            const numberOfOfficers = data.length;
            setDataValue(numberOfOfficers);
          })
          .catch((error) => {
            console.error("Error fetching officer data:", error);
          });
        break;

      default:
        break;
    }
  }, [type]);

  
  const diff = 10;
  let data;
  switch (type) {
    case "projects":
      data = {
        title: "PROJECTS",
        isCount: true,
        link: "/projects",
        icon: <AccountTreeIcon className="w-4 h-4 icon text-emerald-500 " />,
      };
      break;
    case "transactions":
      data = {
        title: "TRANSACTIONS",
        isCount: true,
        link: "/transactions",
        icon: <AccountBalanceIcon className="w-4 h-4 text-pink-500 icon " />,
      };
      break;
    case "clients":
      data = {
        title: "CLIENTS",
        isCount: true,
        link: "/clients",
        icon: <GroupIcon className="w-4 h-4 text-orange-500 icon " />,
      };
      break;
    case "officers":
      data = {
        title: "OFFICERS",
        isCount: true,
        link: "/officers",
        icon: <PersonIcon className="w-4 h-4 icon text-sky-500" />,
      };
      break;
    default:
      break;
  }
//text-gray-400
  return (
    <div className="widget bg-gray-900 shadow-lg p-4 rounded-lg flex justify-between w-[100%]">
      <div className="left">
        <div>
          <span className="text-sm font-bold text-white title font-mono">
            {data.title}
          </span>
        </div>
        <div>
          <span className="text-lg font-light counter text-white">
            {data.isCount && type === "transactions"
              ? `LKR ${parseFloat(dataValue)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
              : dataValue}
          </span>
        </div>
        <div className="mt-2 ">
          <Link className=" text-xs text-blue-600 link" to={data.link}>
            see all
          </Link>
        </div>
      </div>
      <div className="right">{data.icon}</div>
    </div>
  );
};

export default Widget;
