import React from "react";
import "./liststyle.scss";
import Sidebar from "../../component/sidebar/sidebar";
import Project from "../../component/project/project";

const List = () => {
  return (
    <div className="list">
      <Sidebar className="sidebar" />
      <div className="listContainer">
        <Project />
      </div>
    </div>
  );
};
export default List;
