import React from "react";
import "./officerstyle.scss";
import Sidebar from "../../component/sidebar/sidebar";
import Officer from "../../component/officer/officerTable";

const Officers = () => {
  return (
    <div className="officers">
      <Sidebar />
      <div className="officersContainer">
        <Officer />
      </div>
    </div>
  );
};
export default Officers;
