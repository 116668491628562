import React, { useState } from "react";
//import "./newclientstyle.scss";
import TextField from "@mui/material/TextField";
import AsyncSelect from "react-select/async";
import Button from "@mui/material/Button";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import axios from "axios";


const provinceOptions = [
  { value: "western", label: "Western Province" },
  { value: "central", label: "Central Province" },
  { value: "south", label: "South Province" },
  { value: "sabaragamuwa", label: "Sabaragamuwa Province" },
  { value: "northwest", label: "North-West Province" },
  { value: "uva", label: "Uva Province" },
  { value: "eastern", label: "Eastern Province" },
  { value: "northcentral", label: "North-Central Province" },
  { value: "north", label: "North Province" },
];

const districtOptions = [
  { value: "colombo", label: "Colombo" },
  { value: "gampaha", label: "Gampaha" },
  { value: "kaluthara", label: "Kaluthara" },
  { value: "kandy", label: "Kandy" },
  { value: "nuwaraeliya", label: "Nuwaraeliya" },
  { value: "mathale", label: "Mathale" },
  { value: "galle", label: "Galle" },
  { value: "mathara", label: "Mathara" },
  { value: "hambanthota", label: "Hambanthota" },
  { value: "rathnapura", label: "Rathnapura" },
  { value: "kegalle", label: "Kegalle" },
  { value: "kurunegala", label: "Kurunegala" },
  { value: "puththalama", label: "Puththalama" },
  { value: "badulla", label: "Badulla" },
  { value: "monaragala", label: "Monaragala" },
  { value: "anuradapura", label: "Anuradapura" },
  { value: "polonnaruwa", label: "Polonnaruwa" },
  { value: "trincomalee", label: "Trincomalee" },
  { value: "batticaloa", label: "Batticaloa" },
  { value: "ampara", label: "Ampara" },
  { value: "jaffna", label: "Jaffna" },
  { value: "vavuniya", label: "Vavuniya" },
  { value: "manner", label: "Manner" },
  { value: "kilinochchi", label: "Kilinochchi" },
  { value: "mulathivu", label: "Mulathivu" },
];

const filterProvince = (inputValue) => {
  return provinceOptions.filter((i) =>
    i.label.toLowerCase().includes(inputValue.toLowerCase())
  );
};
const filterDistric = (inputValue) => {
  return districtOptions.filter((i) =>
    i.label.toLowerCase().includes(inputValue.toLowerCase())
  );
};

const loadProvinceOptions = (inputValue, callback) => {
  setTimeout(() => {
    callback(filterProvince(inputValue));
  }, 1000);
};
const loadDistricOptions = (inputValue, callback) => {
  setTimeout(() => {
    callback(filterDistric(inputValue));
  }, 1000);
};

const NewClient = (props) => {
  const navigate = useNavigate();
  const { onClose,setSuccess,client} = props;
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const[clientNameError,setClientNameError]=useState("");
  const [formData, setFormData] = useState(()=>{
    if(client){
        return{
            clientID:client.clientID||"",
            clientName:client.clientName||"",
            district:client.district||null,
            province:client.province||null,
        }
    }
    return{
        clientID:"",
        clientName:"",
        district:null,
        province:null,
    }
  });

  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "clientName") {
      const allowedCharactersRegex = /^[a-zA-Z0-9., ]*$/;
      if (!allowedCharactersRegex.test(value)) {
        toast.error("Invalid characters.");
        return;
      }
      if (name === "clientName") {
        if (value.length < 4) {
          setFormData({
            ...formData,
            [name]: value,
          });
          setClientNameError(
            `Client name must be at least ${4} characters long.`
          );
          return;
        } else {
          setClientNameError("");
        }
      }
  
      if (value.length > 30) {
        toast.error("Maximum character count exceeded.");
        return;
      }
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  
  const handleProvinceChange = (selectedOption) => {
    setFormData({
      ...formData,
      province: selectedOption,
    });
  };

  
  const handleDistrictChange = (selectedOption) => {
    setFormData({
      ...formData,
      district: selectedOption,
    });
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      setButtonDisabled(true);
      if (formData.clientName.length < 4) {
        toast.warning("Client name must be at least 4 characters long.");
        setButtonDisabled(false);
        return;
      }
      const response = await axios.put(
        `${process.env.REACT_APP_API}/client/updateClient/${client.clientID}`,
        formData
      );
  
      if (response.status === 200) {
        setSuccess(true);
        Swal.fire({
          icon: 'success',
          title:"Success!",
          text: "Client updated successfully",
        });
        onClose(); 
      } else {
        Swal.fire({
          icon: 'error',
          title:"Error!",
          text: "Failed to update client",
        });
        
      }
    } catch (error) {
      console.error("Error updating client:", error);
      Swal.fire({
        icon: 'error',
        title:"Error!",
        text: "An error occurred while updating the client",
      });
    } finally {
      setButtonDisabled(false);
    }
  };
  

  
  return (
    <div className="flex w-full ">
      <div className="w-full p-4">
        <div className="flex">
          <div className="w-1/4"></div>
          <div className="w-3/4">
            <form onSubmit={handleSubmit} className="flex flex-wrap gap-4">
              <div className="w-2/3">
                <TextField
                  id="clientName"
                  label="Client name"
                  variant="outlined"
                  type="text"
                  name="clientName"
                  required
                  fullWidth
                  value={formData.clientName}
                  onChange={handleInputChange}
                />
                {clientNameError && (
                  <span className="errorText text-xs">{clientNameError}</span>
                )}
              </div>

              <div className="w-2/3">
                <AsyncSelect
                  required
                  defaultOptions
                  className="w-full selectOption"
                  loadOptions={loadProvinceOptions}
                  placeholder={formData.province}
                  onChange={handleProvinceChange}
                  value={formData.province}
                />
              </div>
              <div className="w-2/3">
                <AsyncSelect
                  required
                  defaultOptions
                  className="w-full selectOption"
                  loadOptions={loadDistricOptions}
                  placeholder={formData.district}
                  onChange={handleDistrictChange}
                  value={formData.district}
                />
              </div>
              <div className="w-2/6">
                <button
                  type="submit"
                  variant="contained"
                  disabled={isButtonDisabled}
                  className="rounded focus:outline-none hover:shadow-md"
                  style={{
                  
                    backgroundColor: isButtonDisabled ? "#808080" : "#111827",
                    color: "white",
                    cursor: isButtonDisabled ? "not-allowed" : "pointer",
                    height:"30px",
                    fontSize:"12px",
                    width:"120px"
                  }}
                >
                  {isButtonDisabled ? "UPDATING..." : "UPDATE"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default NewClient;
