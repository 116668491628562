import React, { useState, useEffect } from "react";
import { Button, Typography } from "@mui/material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

const TransactionReceipts = (props) => {
  const { transaction } = props;
  const [currentReceiptIndex, setCurrentReceiptIndex] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(1);

  const totalReceipts = transaction.receipts.length;

  const nextReceipt = () => {
    setCurrentReceiptIndex((prevIndex) =>
      prevIndex < totalReceipts - 1 ? prevIndex + 1 : prevIndex
    );
  };

  const previousReceipt = () => {
    setCurrentReceiptIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : prevIndex
    );
  };

  const zoomIn = () => {
    setZoomLevel((prevZoom) => prevZoom + 0.1);
  };

  const zoomOut = () => {
    setZoomLevel((prevZoom) => (prevZoom > 0.1 ? prevZoom - 0.1 : prevZoom));
  };

  useEffect(() => {
    setCurrentReceiptIndex(0);
  }, [transaction]);

  return (
    <div>
      <div className="mt-5 justify-center items-center flex mb-5 relative z-10">
        <Button
          onClick={previousReceipt}
          disabled={currentReceiptIndex === 0}
          variant="outlined"
          className="w-7 h-7"
        >
          <KeyboardDoubleArrowLeftIcon />
        </Button>
        <span style={{ margin: "0 10px" }}>
          {totalReceipts > 0 && (
            <>
              {currentReceiptIndex + 1} of {totalReceipts}
            </>
          )}
        </span>
        <Button
          onClick={nextReceipt}
          disabled={currentReceiptIndex === totalReceipts - 1}
          variant="outlined"
          className="w-7 h-7"
        >
          <KeyboardDoubleArrowRightIcon />
        </Button>
        <div className="absolute right-[5%] z-20">
          <ZoomInIcon onClick={zoomIn} className="ml-2" style={{ color: "#111827", width: "30px", height: "30px" }} />
          <ZoomOutIcon onClick={zoomOut} className="ml-4" style={{ color: "#111827", width: "30px", height: "30px" }} />
        </div>
      </div>

      {totalReceipts > 0 ? (
        <div>
          <iframe
            title={`Receipt Document ${currentReceiptIndex + 1}`}
            src={transaction.receipts[currentReceiptIndex].blobUrl}
            width="100%"
            height="600px"
            style={{
              border: "1px solid #ccc",
              transform: `scale(${zoomLevel})`,
              transition: "transform 0.2s ease-in-out",
              position: "relative", 
              zIndex: "1", 
            }}
            className="p-0 overflow-x-hidden object-cover overflow-hidden"
          >
            This browser does not support PDFs. Please download the PDF to view
            it.
          </iframe>
        </div>
      ) : (
        <Typography variant="h6" style={{ marginTop: 20 }}>
          <div className="flex text-center justify-center text-red-500 text-sm">
            No transaction document available.{" "}
          </div>
        </Typography>
      )}
    </div>
  );
};

export default TransactionReceipts;
