import React, { useEffect, useState } from "react";
import {
  Paper,
  TextField,
} from "@mui/material";
import ReactPaginate from "react-paginate";
import { Button, Box, LinearProgress, Tooltip } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import { DataGrid } from "@mui/x-data-grid";  
import { useLocation } from "react-router-dom";
import { useUser } from "../../userContext";
import AddNewClientPopup from "../popup/addNewPopup";
import UpdateClientPopup from "../popup/updatePopup";
import NewClient from "../../pages/new/newclient";
import UpdateClient from "../../pages/update/clientUpdate";
import { EditRounded } from "@mui/icons-material";
import "./clienttablestyle.scss";

const ClientTable = () => {
  const columns = [
    { field: "clientName", headerName: "Client Name", flex: 1 },
    { field: "district", headerName: "District", flex: 1 },
    { field: "province", headerName: "Province", flex: 1 },
    {
      field: "update",
      headerName: "",
      flex: 0.5,
      align:"center",
      renderCell: (params) => (
        <div>
          {updateAccess && (
            <Tooltip title={"Update client"}>
              <EditRounded
                style={{
                  cursor: "pointer",
                  color: "orange",
                  width: 12,
                  height: 12,
                }}
                onClick={() => handleUpdateClientPopup(params.row.clientID)}
              />
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  const [rows, rowchange] = useState([]);
  const [page, pagechange] = useState(0);
  const [rowperpage, rowperpagechange] = useState(10);
  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchNotFound, setSearchNotFound] = useState(false);
  const [openAddNewPopup, setOpenAddNewPopup] = useState(false);
  const [openUpdatePopup, setOpenUpdatePopup] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const successMessage = searchParams.get("success");
  const { user, setUser } = useUser();
  const [success, setSuccess] = useState("");
  const [selectedClient, setSelectedClient] = useState(null);

  const handleAddClient = () => {
    setOpenAddNewPopup(true);
  };

  const handleClosePopup = () => {
    setOpenAddNewPopup(false);
  };

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
    }
  }, [successMessage]);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API}/client/getAllClients`,
          { params: { page, pageSize: rowperpage } }
        );
        const clients = await response.json();
        rowchange(clients);
        setTimeout(() => {
          setLoading(false);
        }, 1500);
        setSuccess("Client details submitted successfully");
      } catch (error) {
        console.error("Error fetching clients:", error);
        setLoading(false);
      }
    };

    fetchClients();
  }, [success, page, rowperpage]);

  useEffect(() => {
    if (searchQuery === "") {
      setSearchNotFound(false);
    }
  }, [searchQuery]);

  const handlechangepage = (selected) => {
    setLoadingText("Loading...");
    setLoadingPage(true);
    setTimeout(() => {
      pagechange(selected.selected);
      setLoadingPage(false);
      setLoadingText("");
    }, 1500);
  };

  const handleRowsPerPage = (event) => {
    rowperpagechange(+event.target.value);
    pagechange(0);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    pagechange(0);
    setSearchNotFound(false);
    if (event.target.value === "") {
      //window.location.reload();
    }
  };

  const filteredRows = rows.filter((row) => {
    const values = Object.values(row).map((value) =>
      String(value).toLowerCase()
    );
    return values.some((value) => value.includes(searchQuery.toLowerCase()));
  });

  const offset = page * rowperpage;
  const rowPerPage = rowperpage;

  const displayRows = filteredRows
    .slice(offset, offset + rowPerPage)
    .map((row, index) => ({
      ...row,
      id: index + 1,
    }));

  const handleUpdateClientPopup = (clientID) => {
    const clientToUpdate = rows.find((row) => row.clientID === clientID);
    setSelectedClient(clientToUpdate);
    setOpenUpdatePopup(true);
  };

  const handleUpdateClientClosePopup = () => {
    setOpenUpdatePopup(false);
  };

  const clientButtonAccess = user.permissions.some(
    (permission) => permission === 1 || permission === 5
  );

  const updateAccess = user.permissions.some(
    (permission) => permission === 1 || permission === 7
  );

  const getRowId = (row) => row.id;
  const getRowHeight = () => 39;

  return (
    <div className="clientTable h-[calc(100vh-50px)] text-center overflow-y-auto">
      <div className="flex flex-col items-center justify-between text-xl font-bold text-gray-500 border-b-4 shadow-md tableTitle lg:flex-row rounded-5 mb-3">
        <span className="p-1 font-serif">All Clients</span>
      </div>
      <div className="w-full max-w-screen-xl overscroll-x-none mx-auto">
        {loading && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "60%",
              transform: "translate(-50%, -50%)",
            }}
            className="bg-transparent z-50"
          >
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
            <div className="mt-2">Loading...</div>
          </div>
        )}
        {loadingPage && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "60%",
              transform: "translate(-50%, -50%)",
            }}
            className="bg-transparent z-50"
          >
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
            <div>Loading...</div>
          </div>
        )}
        <Paper sx={{ width: "100%" }}>
          <div>
            <div className="flex items-center justify-between p-2">
              <TextField
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={handleSearch}
                className="border border-gray-300 rounded px-3 py-1 w-[50%]"
                inputProps={{ style: { padding: "5px", fontSize: "13px" } }}
              />
              {clientButtonAccess && (
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={handleAddClient}
                  style={{ height: "30px", fontSize: "12px" }}
                >
                  Add Client
                </Button>
              )}
            </div>
          </div>
          <div style={{ height: "100%", width: "100%" }}>
            <DataGrid
              rows={displayRows}
              columns={columns.map((column) => ({
                ...column,
                headerClassName: "custom-header",
                cellClassName: "custom-cell",
              }))}
              pageSize={rowperpage}
              page={page}
              pagination={false}
              pageSizeOptions={[]}
              disablePrevButton={true}
              disableNextButton={true}
              hideFooter
              getRowId={getRowId}
              getRowHeight={getRowHeight}
            />
          </div>
        </Paper>
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={Math.ceil(filteredRows.length / rowperpage)}
          pageRangeDisplayed={2}
          marginPagesDisplayed={1}
          onPageChange={handlechangepage}
          containerClassName="pagination"
          activeClassName="active"
        />
      </div>
      <ToastContainer />
      <AddNewClientPopup
        open={openAddNewPopup}
        setOpen={setOpenAddNewPopup}
        title="New Client"
      >
        <NewClient onClose={handleClosePopup} setSuccess={setSuccess} />
      </AddNewClientPopup>
      <UpdateClientPopup
        open={openUpdatePopup}
        setOpen={setOpenUpdatePopup}
        title="Update Client"
      >
        <UpdateClient
          onClose={handleUpdateClientClosePopup}
          client={selectedClient}
          setSuccess={setSuccess}
        />
      </UpdateClientPopup>
    </div>
  );
};

export default ClientTable;
