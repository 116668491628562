import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useUser } from "../../userContext";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import WarningIcon from "@mui/icons-material/Warning";

const RequestDetails = () => {
  const { requestID } = useParams();
  const [requestDetails, setRequestDetails] = useState(null);
  const [projectName, setProjectName] = useState(null);
  const [permissionToApprove, setPermissionToApprove] = useState(false);
  const [permissionToReject, setPermissionToReject] = useState(false);
  const navigate = useNavigate();
  const [rejectReason, setRejectReason] = useState("");
  const { user } = useUser();
  const [isApproveButtonDisabled, setApproveButtonDisabled] = useState(false);
  const [isRejectButtonDisabled, setRejectButtonDisabled] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [maxBudget, setMaxBudget] = useState(0);
  const [minBudget, setMinBudget] = useState(0);

  useEffect(() => {
    const fetchRequestDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API}/cashRequest/getCashAdvanceRequest/${requestID}`
        );
        const data = await response.json();
        setRequestDetails(data);
        const projectResponse = await fetch(
          `${process.env.REACT_APP_API}/project/getAllProjects`
        );
        const projectData = await projectResponse.json();
        const project = projectData.find(
          (project) => project.projectID === data.projectID
        );
        setProjectName(project ? project.projectName : null);
      } catch (error) {
        console.error("Error fetching request details:", error);
      }
    };

    fetchRequestDetails();
  }, [requestID]);

  const showConfirmation = () => {
    Swal.fire({
      title: "Do you want approve this request",
      //text: message,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#32cd32",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, approve it!",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        approveRequest();
      }
    });
  };

  const approveRequest = async () => {
    setApproveButtonDisabled(true);
    try {
      await axios.put(
        `${process.env.REACT_APP_API}/cashRequest/updateCashAdvanceRequestStatus/${requestID}`,
        { newStatus: "Approval" }
      );

      setApproveButtonDisabled(false);

      Swal.fire({
        title: "Approved!",
        text: "The request has been approved successfully.",
        icon: "success",
        timer: 3000,
        showConfirmButton: false,
      }).then(() => {
        navigate("/cashAdvance/approval");
      });
    } catch (error) {
      console.error("Error approving request:", error);
      setApproveButtonDisabled(false);
      Swal.fire({
        icon: "error",
        title: "Error to approve request",
      });
    }
    setPermissionToApprove(false);
  };

  const cancelApproval = () => {
    setPermissionToApprove(false);
  };

  const confirmRejectRequest = async () => {
    try {
      const confirmationResult = await Swal.fire({
        title: "Do you want to reject this request?",
        text: "Please provide a reason for rejection:",
        input: "textarea",
        inputPlaceholder: "Reason for rejection",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        inputValidator: (value) => {
          if (!value) {
            return "You need to provide a reason!";
          }
        },
      });

      if (confirmationResult.isConfirmed) {
        await handleRejectRequest(confirmationResult.value);
      }
    } catch (error) {
      console.error("Error confirming rejection:", error);
    }
  };

  useEffect(() => {
    if (requestDetails) {
      fetch(
        `${process.env.REACT_APP_API}/transaction/getAllApprovalTransactionsByProjectID/${requestDetails.projectID}`
      )
        .then((response) => response.json())
        .then((transactions) => {
          const sumAmount = transactions.reduce(
            (total, transaction) => total + transaction.totalCost,
            0
          );

          setTotalAmount(sumAmount);
        })
        .catch((error) => {
          console.error("Error fetching transactions:", error);
        });
    }
  }, [requestDetails]);

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API}/project/getAllProjects`
        );
        const projects = await response.json();
        const project = projects.find(
          (project) => project.projectID === requestDetails.projectID
        );
        if (project) {
          setMaxBudget(project.maxBudget);
          setMinBudget(project.minBudget);
        }
      } catch (error) {
        console.error("Error fetching project details:", error);
      }
    };

    if (requestDetails && requestDetails.projectID) {
      fetchProjectDetails();
    }
  }, [requestDetails]);

  const isWarning = totalAmount > minBudget;
  const isDanger = totalAmount > maxBudget && totalAmount > minBudget;

  const handleRejectRequest = async (reason) => {
    setRejectButtonDisabled(true);

    try {
      await axios.put(
        `${process.env.REACT_APP_API}/cashRequest/updateCashAdvanceRequestStatus/${requestID}`,
        {
          newStatus: "Rejected",
          reason: reason,
          officerName: user.officerName,
        }
      );

      Swal.fire({
        icon: "success",
        title: "Request Rejected!",
        text: "The request has been rejected successfully.",
        timer: 3000,
        showConfirmButton: false,
      });

      setRejectButtonDisabled(false);
      setRejectReason("");
      setTimeout(() => {
        navigate("/cashAdvance/rejected");
      }, 3000);
    } catch (error) {
      console.error("Error rejecting request:", error);
      setRejectButtonDisabled(false);
      Swal.fire({
        icon: "error",
        title: "Error to reject request",
      });
    }
  };

  const rejectRequest = async () => {
    if (!rejectReason) {
      toast.error("Please provide a reason for rejection");
      return;
    }

    confirmRejectRequest();
  };

  const approveButtonAccess = user.permissions.some(
    (permission) => permission === 1 || permission === 22
  );
  const regectButtonAccess = user.permissions.some(
    (permission) => permission === 1 || permission === 23
  );

  const officerName = requestDetails ? requestDetails.officerName : null;

  const isCurrentUserRequestedUser = user.officerName === officerName;

  const formatTotalCost = (cost) => {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(cost);
  };

  return (
    <div className=" bg-gray-200">
      <div className="bg-[#111827] text-white p-4 h-14 fixed top-0 w-full z-10 flex">
        <Link to={"/cashAdvance/pending"}>
          <Tooltip title={"Back"}>
            <ArrowBackIcon className="ml-5 " />
          </Tooltip>
        </Link>
        <div className="ml-5 ">
          <h1 className="m-0 text-lg text-white font-serif">
            Pending Request Informations
          </h1>
        </div>
      </div>
      {requestDetails && (
        <>
          <div className="p-4">
            <table className="min-w-[100%] divide-y divide-gray-200 mb-4 mt-20 shadow-xl border border-t-4 border-t-[#111827] bg-gray-50 ">
              <thead>
                {/* <tr className="bg-[#111827] text-gray-400 text-sm p-2">
                <th>Request Details</th>
                <th></th>
              </tr> */}
              </thead>
              <tbody className=" text-sm">
                <tr className="">
                  <td className=" w-[25%] p-1 font-semibold">Request ID</td>
                  <td className=" p-1 text-gray-600">
                    {requestDetails.requestID}
                  </td>
                </tr>
                <tr className="">
                  <td className=" w-[25%] p-1 font-semibold">Project Name</td>
                  <td className=" p-1 text-gray-600">{projectName}</td>
                </tr>
                <tr className="">
                  <td className=" w-[25%] p-1 font-semibold">
                    Project Max Budget
                  </td>
                  <td className=" p-1 text-gray-600">
                    {formatTotalCost(maxBudget)}
                  </td>
                </tr>
                <tr className="">
                  <td className=" w-[25%] p-1 font-semibold">
                    Project Min Budget
                  </td>
                  <td className=" p-1 text-gray-600">
                    {formatTotalCost(minBudget)}
                  </td>
                </tr>
                <tr className="">
                  <td className=" w-[25%] p-1 font-semibold">
                    Project Total Expenses
                  </td>
                  <td className=" p-1 text-gray-600">
                    {formatTotalCost(totalAmount)}
                  </td>
                </tr>
                <tr>
                  <td className=" w-[25%] p-1 font-semibold">Officer Name</td>
                  <td className=" p-1 text-gray-600">
                    {requestDetails.officerName}
                  </td>
                </tr>
                <tr>
                  <td className=" w-[25%] p-1 font-semibold">
                    Projected Date for Expenses
                  </td>
                  <td className=" p-1 text-gray-600">
                    {requestDetails.startDate} to {requestDetails.endDate}
                  </td>
                </tr>
                <tr>
                  <td className=" w-[25%] p-1 font-semibold">Required Date</td>
                  <td className=" p-1 text-gray-600">
                    {requestDetails.requiredDate}
                  </td>
                </tr>
                <tr className="">
                  <td className=" w-[25%] p-1 font-semibold">
                    Reason for Request
                  </td>
                  <td className=" p-1 text-gray-600">
                    {requestDetails.reason}
                  </td>
                </tr>

                <tr className="">
                  <td className=" w-[25%] p-1 font-semibold">Total Amount</td>
                  <td className=" p-1 text-gray-600">
                    {formatTotalCost(requestDetails.totalAmount / 100)} LKR
                  </td>
                </tr>
                <tr className="">
                  <td className=" w-[25%] p-1 font-semibold">Request Status</td>
                  <td className=" text-orange-500 p-1">
                    {requestDetails.requestStatus}
                  </td>
                </tr>
                <tr className="">
                  <td className=" w-[25%] p-1 font-semibold">Payment Status</td>
                  <td className=" p-1 text-red-600">
                    {requestDetails.paymentStatus}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td className="p-1 row-span-2">
                    {isWarning && !isDanger && (
                      <div className="flex items-center text-yellow-600 mt-4 mb-3">
                        <WarningIcon />
                        <span className="ml-2">
                          Total transaction amount exceeds the minimum budget of {projectName} project.
                        </span>
                      </div>
                    )}
                    {isDanger && (
                      <div className="flex items-center text-red-600 mt-4 mb-3">
                        <WarningIcon />
                        <span className="ml-2">
                          Total transaction amount exceeds the maximum budget of {projectName} project.
                        </span>
                      </div>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>

            <h3 className="text-sm font-semibold mt-4">Transport </h3>
            <table className="min-w-full divide-y divide-gray-200 mb-4 border bg-white">
              <thead className="bg-[#111827] text-gray-400 text-sm p-2">
                <tr className="border">
                  <th className="border font-mono">Transport type</th>
                  <th className="border font-mono">Km</th>
                  <th className="border font-mono">Total</th>
                </tr>
              </thead>
              <tbody className="text-sm text-gray-600">
                {requestDetails.TransportCosts &&
                  requestDetails.TransportCosts.map((transport) => (
                    <tr key={transport.transportId}>
                      <td className="border text-center">{transport.type}</td>
                      <td className="border text-center">{transport.miles}</td>
                      <td className="border  text-center">
                        {formatTotalCost(transport.amount / 100)}
                      </td>
                    </tr>
                  ))}
                <tr>
                  <td></td>
                  <td></td>
                  <td>
                    <div className="text-sm mt-5 text-end mb-5 mr-5">
                      <hr className="w-[30%] border-black ml-[70%] " />
                      <p className="">Total</p>{" "}
                      {formatTotalCost(requestDetails.transportTotal / 100)}
                      <hr className="w-[30%] border-black ml-[70%]" />
                      <hr className="w-[30%] border-black ml-[70%] mt-1" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <h3 className="text-sm font-semibold mt-4">Accommodation </h3>
            <table className="min-w-full divide-y divide-gray-200 mb-4 border bg-white">
              <thead className="bg-[#111827] text-gray-400 text-sm">
                <tr>
                  <th className="border font-mono">Date</th>
                  <th className="border font-mono">No of Heads</th>
                  <th className="border font-mono">Amount Per Night</th>
                  <th className="border font-mono">Total Per Day</th>
                </tr>
              </thead>
              <tbody className="text-sm text-gray-600">
                {requestDetails.AccomadationCosts &&
                  requestDetails.AccomadationCosts.map((accommodation) => (
                    <tr key={accommodation.accomadationID}>
                      <td className="border  text-center">
                        {accommodation.date}
                      </td>
                      <td className="border  text-center">
                        {accommodation.noOfHeads}
                      </td>
                      <td className="border  text-center">
                        {formatTotalCost(accommodation.amountPerNight / 100)}
                      </td>
                      <td className="border  text-center">
                        {formatTotalCost(accommodation.amount / 100)}
                      </td>
                    </tr>
                  ))}
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <div className="text-sm mt-5 text-end mb-5 mr-5">
                      <hr className="w-[50%] border-black ml-[50%] " />
                      <p className="">Total</p>{" "}
                      {formatTotalCost(requestDetails.accomadationTotal / 100)}
                      <hr className="w-[50%] border-black ml-[50%]" />
                      <hr className="w-[50%] border-black ml-[50%] mt-1" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <h3 className="text-sm font-semibold mt-4">Meal</h3>
            <table className="min-w-full divide-y divide-gray-200 mb-4 border bg-white">
              <thead className="bg-[#111827] text-gray-400 text-sm">
                <tr>
                  <th className="border font-mono">Date</th>
                  <th className="border font-mono">No of Heads</th>
                  <th className="border font-mono">Total Per Day</th>
                </tr>
              </thead>
              <tbody className="text-sm text-gray-600">
                {requestDetails.MealCosts &&
                  requestDetails.MealCosts.map((meal) => (
                    <tr key={meal.mealID}>
                      <td className="border  text-center">{meal.date}</td>
                      <td className="border  text-center">{meal.noOfHeads}</td>
                      <td className="border  text-center">
                        {formatTotalCost(meal.amount / 100)}
                      </td>
                    </tr>
                  ))}
                <tr>
                  <td></td>
                  <td></td>
                  <td>
                    <div className="text-sm mt-5 text-end mb-5 mr-5">
                      <hr className="w-[35%] border-black ml-[65%] " />
                      <p className="">Total</p>{" "}
                      {formatTotalCost(requestDetails.mealTotal / 100)}
                      <hr className="w-[35%] border-black ml-[65%]" />
                      <hr className="w-[35%] border-black ml-[65%] mt-1" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <h3 className="text-sm font-semibold mt-4">Other</h3>
            <table className="min-w-full divide-y divide-gray-200 mb-4 border bg-white">
              <thead className="bg-[#111827] text-gray-400 text-sm">
                <tr>
                  <th className="border font-mono">Reason</th>
                  <th className="border font-mono">Amount</th>
                </tr>
              </thead>
              <tbody className="text-sm text-gray-600">
                {requestDetails.OtherCosts &&
                  requestDetails.OtherCosts.map((other) => (
                    <tr key={other.otherID}>
                      <td className="border  text-center">{other.reason}</td>
                      <td className="border  text-center">
                        {formatTotalCost(other.amount)}
                      </td>
                    </tr>
                  ))}
                <tr>
                  <td></td>
                  <td>
                    {requestDetails.OtherCosts &&
                      requestDetails.OtherCosts.map((other) => (
                        <div
                          className="text-sm mt-5 text-end mb-5 mr-5"
                          key={other.otherID}
                        >
                          <hr className="w-[25%] border-black ml-[75%] " />
                          <p className="">Total</p>{" "}
                          {formatTotalCost(other.amount)}
                          <hr className="w-[25%] border-black ml-[75%]" />
                          <hr className="w-[25%] border-black ml-[75%] mt-1" />
                        </div>
                      ))}
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="mt-10 flex space-x-4 justify-end items-center mb-5">
              {approveButtonAccess && (
                <button
                  className={` text-white px-4 rounded focus:outline-none focus:shadow-outline text-sm  ${
                    isApproveButtonDisabled || isCurrentUserRequestedUser
                      ? "bg-gray-500 cursor-not-allowed"
                      : "bg-[#00cc00] hover:bg-green-700 hover:shadow-md"
                  }`}
                  onClick={showConfirmation}
                  disabled={
                    isApproveButtonDisabled || isCurrentUserRequestedUser
                  }
                  style={{
                    height: "30px",
                    width: "100px",
                  }}
                >
                  {isApproveButtonDisabled ? "APPROVING..." : "APPROVE"}
                </button>
              )}
              {regectButtonAccess && (
                <button
                  className={`  text-white px-4 rounded focus:outline-none focus:shadow-outline text-sm ${
                    isRejectButtonDisabled || isCurrentUserRequestedUser
                      ? "bg-gray-500 cursor-not-allowed"
                      : "bg-[red] hover:bg-red-700 hover:shadow-md"
                  }`}
                  onClick={confirmRejectRequest}
                  disabled={
                    isRejectButtonDisabled || isCurrentUserRequestedUser
                  }
                  style={{
                    height: "30px",
                    width: "100px",
                  }}
                >
                  REJECT
                </button>
              )}
            </div>
          </div>
        </>
      )}
      <ToastContainer />
    </div>
  );
};

export default RequestDetails;
