import React from "react";
import "./transactionstyle.scss";
import Sidebar from "../../component/sidebar/sidebar";
import TransactionTable from "../../component/transaction/approvedTransactionTable";

const Transactions = () => {
  return (
    <div className="transactions">
      <Sidebar />
      <div className="transactionContainer">
        <TransactionTable />
      </div>
    </div>
  );
};
export default Transactions;
