import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Tooltip, Button, Checkbox } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useUser } from "../../userContext";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


const CashAdvanceRequestForm = () => {
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [transportTypes, setTransportTypes] = useState([]);
  const [distance, setDistance] = useState("");
  const [total, setTotal] = useState(0);
  const [accommodationData, setAccommodationData] = useState([]);
  const [startDateAccommodation, setStartDateAccommodation] = useState(null);
  const [endDateAccommodation, setEndDateAccommodation] = useState(null);
  const [accommodationColumns, setAccommodationColumns] = useState([]);
  const [validationError, setValidationError] = useState("");
  const [requiredDate, setRequiredDate] = useState(null);
  const [validationRequiredError, setValidationRequiredError] = useState("");
  const [mealColumns, setMealColumns] = useState([]);
  const [projects, setProjects] = useState([]);
  const [mealValues, setMealValues] = useState([]);
  const [mealPrices, setMealPrices] = useState([]);
  const [totalForAllDays, setTotalForAllDays] = useState(0);
  const [selectedProject, setSelectedProject] = useState(null);
  const [columnTotals, setColumnTotals] = useState(0);
  const [reasonForRequest, setReasonForRequest] = useState("");
  const [fullTotal, setFullTotal] = useState(0);
  const [agreed, setAgreed] = useState(false);
  const [agreeError, setAgreeError] = useState("");
  const [totalAccommodationForAllDays, setTotalAccommodationForAllDays] =
    useState(0);
  const [others, setOthers] = useState({
    input: "",
    amount: "",
  });
  const { user } = useUser();
  const [isSaveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [isStartDateSelected, setIsStartDateSelected] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/cost/getAllTransportTypes`)
      .then((response) => {
        const transportTypesData = response.data;
        setTransportTypes(transportTypesData);
      })
      .catch((error) => {
        console.error("Error fetching transport types:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/cost/getAllMealTypes`)
      .then((response) => {
        const mealValuesData = response.data;
        setMealValues(mealValuesData);

        const prices = mealValuesData.map((meal) => ({
          typeID: meal.typeID,
          price: meal.price / 100,
        }));
        setMealPrices(prices);
      })
      .catch((error) => {
        console.error("Error fetching meal values:", error);
      });
  }, []);

  const validateDateRange = (start, end) => {
    const maxAllowedDays = 6;
    const dayDifference = Math.round((end - start) / (24 * 60 * 60 * 1000));

    if (dayDifference >= maxAllowedDays) {
      setValidationError(`Date range should not exceed ${maxAllowedDays} days`);
    } else {
      setValidationError("");
    }
  };

  const handleCheckboxChange = (selectedType) => {
    if (selectedVehicle === selectedType) {
      setSelectedVehicle(null);
      setDistance("");
      setTotal(0);
    } else {
      setSelectedVehicle(selectedType);
      setDistance("");
      setTotal(0);
    }
    if (selectedType === "bus_fare") {
      setDistance(0);
      setTotal(0);
    }
  };

  const handleDistanceChange = (event) => {
    const inputValue = event.target.value;

    if (selectedVehicle === "bus_fare") {
      setDistance(0);
      setTotal(0);
    } else {
      const decimalCount = (inputValue.split(".")[1] || "").length;

    if (decimalCount > 2) {
      toast.warning("Maximum of 2 decimal points allowed", { autoClose: 2000 });
      return;
    }

      if (inputValue < 0) {
        toast.warning("Distance cannot be negative", { autoClose: 2000 });
      } else {
        setDistance(inputValue);
        setTotal(calculateTotal(selectedVehicle, inputValue));
      }
    }
  };

  const calculateTotal = (selectedType, distance) => {
    const selectedTransport = transportTypes.find(
      (type) => type.type === selectedType
    );

    if (selectedTransport) {
      return (
        (parseFloat(distance) * selectedTransport.pricePerMile) /
        100
      ).toFixed(2);
    }

    return 0;
  };

  const calculateMaxAllowedEndDate = (startDate) => {
    return new Date(new Date(startDate).getTime() + 5 * 24 * 60 * 60 * 1000);
  };

  const handleStartDateAccommodationChange = (date) => {
    setStartDateAccommodation(date);
    //setEndDateAccommodation(calculateMaxAllowedEndDate(date));
    validateDateRange(startDateAccommodation, date);
    generateAccommodationColumns(date, endDateAccommodation);
    generateMealColumns(date, endDateAccommodation);
    setIsStartDateSelected(true);
  };

  const handleEndDateAccommodationChange = (date) => {
    setEndDateAccommodation(date);
    validateDateRange(startDateAccommodation, date);
    generateAccommodationColumns(startDateAccommodation, date);
    generateMealColumns(startDateAccommodation, date);
  };

  const generateAccommodationColumns = (startDate, endDate) => {
    const columns = [];
    let currentDate = new Date(startDate);
    const data = [];
    const maxDays = 6;

    while (currentDate <= endDate && columns.length < maxDays) {
      const columnDate = currentDate.toISOString();

      const handleNoOfHeadsChange = (event, columnDate) => {
        const { value } = event.target;

        if (!Number.isInteger(parseFloat(value))) {
          toast.warning("No of heads must be an integer", { autoClose: 2000 });

          const integerPart = parseInt(value.split(".")[0], 10);

          event.target.value = isNaN(integerPart) ? 0 : integerPart;
        } else {
          const noOfHeads = parseInt(value, 10);

          if (noOfHeads < 0) {
            event.target.value = 0;
            toast.warning("No of heads cannot be negative", {
              autoClose: 2000,
            });
          } else {
            const amountPerNight = document.getElementById(
              `amountPerNight_${columnDate}`
            ).value;
            const total = (noOfHeads * amountPerNight).toFixed(2);

            document.getElementById(
              `total_${columnDate}`
            ).textContent = `Total: ${total}`;

            setAccommodationData((prevData) =>
              prevData.map((item) =>
                item.date === new Date(columnDate).toDateString()
                  ? {
                      ...item,
                      noOfHeads: parseInt(noOfHeads, 10),
                      total: parseFloat(total),
                    }
                  : item
              )
            );

            calculateAccommodationTotalForAllDays();
          }
        }
      };

      const handleAmountPerNightChange = (event, columnDate) => {
        const amountPerNight = event.target.value;

        const decimalCount = (amountPerNight.split(".")[1] || "").length;
        if (decimalCount > 2) {
          toast.warning("Maximum of 2 decimal points allowed", {
            autoClose: 2000,
          });
          return;
        }

        if (amountPerNight < 0) {
          event.target.value = 0;
          toast.warning("Amount per night cannot be negative", {
            autoClose: 2000,
          });
        } else {
          const noOfHeads = document.getElementById(
            `noOfHeads_${columnDate}`
          ).value;
          const total = (noOfHeads * amountPerNight).toFixed(2);

          document.getElementById(
            `total_${columnDate}`
          ).textContent = `Total: ${total}`;

          setAccommodationData((prevData) =>
            prevData.map((item) =>
              item.date === new Date(columnDate).toDateString()
                ? {
                    ...item,
                    amountPerNight: parseFloat(amountPerNight),
                    total: parseFloat(total),
                  }
                : item
            )
          );

          calculateAccommodationTotalForAllDays();
        }
      };

      const calculateAccommodationTotalForAllDays = () => {
        setAccommodationData((prevData) => {
          const totalForAllDays = prevData.reduce(
            (acc, item) => acc + item.total,
            0
          );
          setTotalAccommodationForAllDays(
            parseFloat(totalForAllDays.toFixed(2))
          );
          return prevData;
        });
      };

      columns.push(
        <div key={columnDate} className="flex-3">
          <p className="mb-2 md:text-sm sm:text-xs">
            {currentDate.toDateString()}
          </p>
          <div>
            <input
              id={`noOfHeads_${columnDate}`}
              placeholder="No of heads"
              type="number"
              className="w-[100%] h-8 text-xs text-black text-right mb-2 "
              onChange={(event) => handleNoOfHeadsChange(event, columnDate)}
            />
          </div>
          <div>
            <input
              id={`amountPerNight_${columnDate}`}
              type="number"
              placeholder="Amount per night"
              className="w-[100%] h-8 text-black text-right mb-2 text-xs"
              onChange={(event) =>
                handleAmountPerNightChange(event, columnDate)
              }
            />
          </div>
          <div
            id={`total_${columnDate}`}
            className="text-sm text-black mt-2 text-right absolute "
          ></div>
        </div>
      );

      data.push({
        date: currentDate.toDateString(),
        noOfHeads: 0,
        amountPerNight: 0,
        total: 0,
      });

      currentDate.setDate(currentDate.getDate() + 1);
    }

    setAccommodationData(data);
    setAccommodationColumns(columns);
  };

  const handleRequiredDateChange = (date) => {
    if (date <= startDateAccommodation) {
      setRequiredDate(date);
      setValidationRequiredError("");
    } else {
      setValidationRequiredError(
        "Required date must be less than or equal to start date"
      );
    }
  };
  const MAX_DECIMAL_POINTS = 0;

  const generateMealColumns = (startDate, endDate) => {
    const columns = [];
    let currentDate = new Date(startDate);
    let totalForAllDays = 0;
    const maxDays = 6;

    while (currentDate <= endDate && columns.length < maxDays) {
      const columnDate = currentDate.toISOString();
      const isFirstDay = currentDate.getDate() === startDate.getDate();

      const handleNoOfHeadsChange = (event) => {
        const noOfHeads = event.target.value;

        if (!Number.isInteger(parseFloat(noOfHeads))) {
          toast.warning("No of heads must be an integer", { autoClose: 2000 });

          const integerPart = parseInt(noOfHeads.split(".")[0], 10);

          event.target.value = isNaN(integerPart) ? 0 : integerPart;
        } else {
          if (noOfHeads < 0) {
            event.target.value = 0;
            toast.warning("No of heads cannot be negative", {
              autoClose: 2000,
            });
          } else {
            const total = calculateMealTotal(isFirstDay, noOfHeads);
            document.getElementById(
              `total_meal_${columnDate}`
            ).textContent = `Total: ${total}`;

            updateTotalForAllDays();
          }
        }
      };

      columns.push(
        <div key={columnDate} className="flex-3">
          <p className="mb-2 md:text-sm sm:text-xs">
            {currentDate.toDateString()}
          </p>
          <div>
            {mealValues.map((meal) => (
              <React.Fragment key={meal.typeID}>
                <div>
                  <label className=" md:text-xs sm:text-xs">
                    {isFirstDay &&
                    (meal.type === "Breakfast" || meal.type === "Lunch")
                      ? "Not Allowed"
                      : meal.type}{" "}
                    -{" "}
                    {isFirstDay &&
                    (meal.type === "Breakfast" || meal.type === "Lunch")
                      ? "N/A"
                      : (meal.price / 100).toFixed(2)}
                  </label>
                </div>
              </React.Fragment>
            ))}
            <div className="mt-2">
              <input
                id={`noOfHeads_meal_${columnDate}`}
                placeholder="No of heads"
                type="number"
                className="w-[100%] h-8 text-xs text-black text-right mb-2 "
                onChange={handleNoOfHeadsChange}
              />
            </div>
          </div>
          <div
            id={`total_meal_${columnDate}`}
            className="text-sm text-black mt-2 text-right absolute "
          ></div>
        </div>
      );

      currentDate.setDate(currentDate.getDate() + 1);
    }

    const updateTotalForAllDays = () => {
      let newTotalForAllDays = 0;
      columns.forEach((column) => {
        const columnDate = column.key;
        const total = parseFloat(
          document
            .getElementById(`total_meal_${columnDate}`)
            .textContent.split(": ")[1]
        );
        newTotalForAllDays += isNaN(total) ? 0 : total;
      });
      setTotalForAllDays(newTotalForAllDays);
    };

    setMealColumns(columns);
  };

  const calculateMealTotal = (isDinnerAllowed, noOfHeads) => {
    let total = 0;

    if (isDinnerAllowed) {
      const dinnerPrice =
        mealPrices.find((meal) => meal.typeID === 3)?.price || 0;
      total = (noOfHeads * dinnerPrice).toFixed(2);
    } else {
      total = mealPrices
        .reduce((acc, meal) => {
          return acc + noOfHeads * meal.price;
        }, 0)
        .toFixed(2);
    }

    return total;
  };

  const handleOthersChange = (event) => {
    const { name, value } = event.target;

    if (name === "input") {
      const allowedCharactersRegex = /^[a-zA-Z0-9./, ]*$/;
      if (!allowedCharactersRegex.test(value)) {
        toast.error("Invalid characters.");
        return;
      }
    }
    if (name === "amount") {
      const regex = /^\d*\.?\d{0,2}$/;
      if (!regex.test(value)) {
        toast.warning(
          "Only allow positive number with up to 2 decimal places."
        );
        return;
      }
    }
    setOthers((prevOthers) => ({
      ...prevOthers,
      [name]: value,
    }));
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/project/getAllProjects`)
      .then((response) => {
        const projects = response.data.map((project) => ({
          value: project.projectID,
          label: project.projectName,
        }));
        setProjects(projects);
      })
      .catch((error) => {
        console.error("Error fetching projects:", error);
      });
  }, []);



  const filterProject = (inputValue, projects) => {
    return projects.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const handleReasonForRequestChange = (event) => {
    const { value } = event.target;

    const allowedCharactersRegex = /^[a-zA-Z0-9,./ ]*$/;

    if (allowedCharactersRegex.test(value)) {
      setReasonForRequest(value);
    } else {
      toast.error("Invalid characters.");
    }
  };

  useEffect(() => {
    const transportTotal = parseFloat(total);
    const accommodationTotal = isNaN(totalAccommodationForAllDays)
      ? 0
      : parseFloat(totalAccommodationForAllDays.toFixed(2));
    const mealTotal = totalForAllDays.toFixed(2);
    const otherTotal = parseFloat(others.amount) || 0;

    const calculatedFullTotal =
      transportTotal + accommodationTotal + parseFloat(mealTotal) + otherTotal;

    setFullTotal(calculatedFullTotal);
  }, [total, totalAccommodationForAllDays, totalForAllDays, others.amount]);

  const resetForm = () => {
    setSelectedVehicle(null);
    setTransportTypes([]);
    setDistance("");
    setTotal(0);
    setAccommodationData([]);
    setStartDateAccommodation(null);
    setEndDateAccommodation(null);
    setAccommodationColumns([]);
    setValidationError("");
    setRequiredDate(null);
    setValidationRequiredError("");
    setMealColumns([]);
    setProjects([]);
    setMealValues([]);
    setMealPrices([]);
    setTotalForAllDays(0);
    setSelectedProject(null);
    setColumnTotals(0);
    setReasonForRequest("");
    setFullTotal(0);
    setAgreed(false);
    setAgreeError("");
    setTotalAccommodationForAllDays(0);
    setOthers({
      input: "",
      amount: "",
    });
  };

  const handleAgreeCheckboxChange = () => {
    setAgreed(!agreed);
    setAgreeError("");
  };
  const handleCancelClick = () => {
    resetForm();
  };

  const handleSendButtonClick = () => {
    setSaveButtonDisabled(true);
    if (!selectedProject) {
      toast.warning("Please select a project");
      setSaveButtonDisabled(false);
      return;
    }
    if (!startDateAccommodation || !endDateAccommodation) {
      toast.warning("Please select start and end date");
      setSaveButtonDisabled(false);
      return;
    }
    if (!requiredDate) {
      toast.warning("Please select required date");
      setSaveButtonDisabled(false);
      return;
    }
    if (!reasonForRequest.trim()) {
      toast.warning("Please provide a reason for the request");
      setSaveButtonDisabled(false);
      return;
    }

    if (agreed) {
      const transportData = {
        selectedVehicle,
        distance: parseFloat(distance) || 0,
        total: parseFloat(total) || 0,
      };

      const accommodationDatas = {
        dates: accommodationData.map((item) => item.date),
        heads: accommodationData.map((item) => item.noOfHeads),
        amountPerNight: accommodationData.map((item) => item.amountPerNight),
        total: accommodationData.map((item) => item.total),
      };

      const mealData = {
        date: mealColumns.map((column) => column.key),
        total: mealColumns.map((column) =>
          parseFloat(
            document
              .getElementById(`total_meal_${column.key}`)
              .textContent?.split(": ")[1]
              ?.replace(/[^0-9.]/g, "") || 0
          )
        ),
        noOfHeads: mealColumns.map((column) => {
          const noOfHeadsValue = document.getElementById(
            `noOfHeads_meal_${column.key}`
          ).value;
          return noOfHeadsValue !== "" ? parseInt(noOfHeadsValue, 10) : 0;
        }),
      };

      const transformedAccommodationData = accommodationDatas.heads.map(
        (head, index) => [
          accommodationDatas.dates[index],
          head,
          accommodationDatas.amountPerNight[index],
          accommodationDatas.total[index],
        ]
      );

      const transformedMealData = mealData.date.map((date, index) => [
        new Date(date).toDateString(),
        mealData.noOfHeads[index],
        mealData.total[index],
      ]);

      const otherData = {
        otherReason: others.input,
        amount: parseFloat(others.amount),
      };

      const dataToSend = {
        userID: user.id,
        officerName: user.officerName,
        selectedProject,
        startDate: new Date(startDateAccommodation).toDateString(),
        endDate: new Date(endDateAccommodation).toDateString(),
        requiredDate: new Date(requiredDate).toDateString(),
        fullTotal:parseFloat(fullTotal.toFixed(2)),
        reasonForRequest,
        transportData,
        accommodationDatas: transformedAccommodationData,
        mealData: transformedMealData,
        otherData,
        transportTotal: parseFloat(total),
        totalMealForallDays: totalForAllDays,
        totalAccommodationForAllDays,
        otherTotal: parseFloat(others.amount),
      };
      axios
        .post(
          `${process.env.REACT_APP_API}/cashRequest/saveCashAdvanceRequest`,
          dataToSend
        )
        .then((response) => {
          Swal.fire({
            title: 'Success!',
            text: 'The request has been send successfully.',
            icon: 'success',
            timer: 3000,
            showConfirmButton: false
          })
          setSaveButtonDisabled(false);
          resetForm();
          setTimeout(() => {
            navigate("/cashAdvance/pending");
          }, 3000);
          
        })
        .catch((error) => {
          console.error("Error sending cash advance request:", error);
          Swal.fire({
            title: 'Error!',
            text: 'Error sending cash advance request.',
            icon: 'error',
            timer: 3000,
            showConfirmButton: false
          })
          
          setSaveButtonDisabled(false);
        });
    } else {
      setAgreeError("Please agree to continue before submitting.");
    }
  };

  return (
    <div className="bg-gray-300">
      <div className="bg-[#111827] text-white p-4 h-14 fixed top-0 w-full z-10 flex">
        <Link to={"/cashAdvance/pending"}>
          <Tooltip title={"Back"}>
            <ArrowBackIcon className="ml-5 " />
          </Tooltip>
        </Link>
        <div className="ml-5 ">
          <h1 className="m-0 text-lg  text-white font-serif">
            Cash Advance Request
          </h1>
        </div>
      </div>
      {/* <div className="w-[95%] bg-gray-300 absolute mt-20 h-auto p-4 ml-9  border-r-8 border-r-[#111827]"></div> */}
      <div className="p-8 mt-12">
        <div className="bg-[#111827] p-1 mb-7 rounded-md">
          <h3 className="text-center text-lg  text-[white] font-serif">
            Employee Advance Estimate/Reconsilation
          </h3>
        </div>
        <div className="flex flex-col lg:flex-row md:flex-row sm:flex-col">
          <div className="flex-3 mb-4 lg:mb-0 md:mb-0 sm:mb-4 lg:mr-4 ml-5">
            <p className=" mb-2 text-sm  font-semibold mt-3">
              Project Name{" "}
              <Tooltip title={"Required"}>
                <span className="text-red-500">*</span>
              </Tooltip>
            </p>
            <p className="mb-2 mt-6 text-sm   font-semibold">
              Projected Date for Expenses{" "}
              <Tooltip title={"Required"}>
                <span className="text-red-500">*</span>
              </Tooltip>
            </p>
            <p className="mb-2 text-sm  mt-5 font-semibold">
              Required Date{" "}
              <Tooltip title={"Required"}>
                <span className="text-red-500">*</span>
              </Tooltip>
            </p>
          </div>
          <div className="flex-2  ">
            <Select
              options={projects}
              onChange={(selectedOption) => setSelectedProject(selectedOption)}
              value={selectedProject}
              className="shadow-md text-sm"
              placeholder="Select project"
            />
            <div className="flex mb-2 mt-3">
              <DatePicker
                selected={startDateAccommodation}
                onChange={handleStartDateAccommodationChange}
                className="w-28 h-8 text-center  shadow-md border-none rounded-sm text-sm"
                required
                minDate={new Date()}
                maxDate={
                  new Date(new Date().getTime() + 5 * 24 * 60 * 60 * 1000)
                }
                placeholderText="start date*"
              />
              <span className="self-center ml-2 text-sm  ">to</span>
              <DatePicker
                selected={endDateAccommodation}
                onChange={handleEndDateAccommodationChange}
                className="w-28 ml-2 h-8 text-center  shadow-md border-none rounded-sm text-sm"
                maxDate={calculateMaxAllowedEndDate(startDateAccommodation)}
                required
                minDate={new Date()}
                disabled={!isStartDateSelected}
                placeholderText="end date*"
              />
              {validationError && (
                <div className="text-red-500 mt-2 ml-[20%] text-xs absolute ">
                  {validationError}
                </div>
              )}
            </div>

            <DatePicker
              className="w-28 h-8 text-center  shadow-md border-none rounded-sm text-sm"
              selected={requiredDate}
              onChange={handleRequiredDateChange}
              required
              minDate={new Date()}
              placeholderText="required date*"
            />
            {validationRequiredError && (
              <div className="text-red-500 mt-2  text-xs absolute ">
                {validationRequiredError}
              </div>
            )}
          </div>
        </div>

        <fieldset className="  shadow-md bg-fuchsia-50 mt-5 rounded-md">
          <legend className="ml-5 text-sm font-bold  ">Transport</legend>
          <div className="flex-6 space-x-5 p-2 flex flex-wrap items-center justify-between">
            <div className="flex items-center space-x-2">
              <Checkbox
                onChange={() => handleCheckboxChange("bus_fare")}
                checked={selectedVehicle === "bus_fare"}
              />
              <label className=" text-sm">Bus Fare</label>
            </div>

            {transportTypes.map((type) => (
              <React.Fragment key={type.typeID}>
                <div className="flex items-center space-x-2">
                  <Checkbox
                    onChange={() => handleCheckboxChange(type.type)}
                    checked={selectedVehicle === type.type}
                  />
                  <label className=" text-sm">{type.type}</label>
                </div>
              </React.Fragment>
            ))}

            <div className="flex items-center space-x-2">
              <input
                type="number"
                value={distance}
                placeholder="Distance"
                onChange={handleDistanceChange}
                disabled={selectedVehicle === "bus_fare"}
                className="w-28 h-8 text-sm text-black text-right "
              />
              <label className="text-sm">Km</label>
            </div>

            <div className="flex items-center space-x-2">
              <label className=" text-lg">Total</label>
              <input
                type="number"
                value={total}
                onChange={(event) => {
                  const enteredValue = event.target.value;
      
                  const isValidNumber = /^\d+(\.\d{0,2})?$/.test(enteredValue);
        
                  if (isValidNumber || enteredValue === "") {
                    setTotal(enteredValue);
                  }
                }}
                disabled={selectedVehicle !== "bus_fare"}
                className="h-8 text-right text-black "
                min={0.00}
              />
            </div>
          </div>
        </fieldset>

        <fieldset className=" bg-fuchsia-50 mt-5 shadow-md rounded-md ">
          <legend className="ml-5 text-sm font-bold">Accomadation</legend>
          <div className="flex space-x-5 p-2">{accommodationColumns}</div>
          <div className="text-lg mt-5 text-end mb-5 mr-5">
            <hr className="w-[15%] border-black ml-[85%] " />
            <p className="">Total</p>{" "}
            {isNaN(totalAccommodationForAllDays)
              ? 0
              : totalAccommodationForAllDays.toFixed(2)}
            <hr className="w-[15%] border-black ml-[85%]" />
            <hr className="w-[15%] border-black ml-[85%] mt-1" />
          </div>
        </fieldset>
        <fieldset className=" bg-fuchsia-50 mt-5 shadow-md rounded-md">
          <legend className="ml-5 text-sm font-bold  ">Meals</legend>
          <div className="flex space-x-5 p-2">{mealColumns}</div>
          <div className="text-lg  mt-5  text-end mb-5 mr-5 ">
            <hr className="w-[15%] border-black ml-[85%] " />
            <p className="">Total</p>
            {totalForAllDays.toFixed(2)}
            <hr className="w-[15%] border-black ml-[85%]" />
            <hr className="w-[15%] border-black mt-1 ml-[85%]" />
          </div>
        </fieldset>
        <fieldset className=" bg-fuchsia-50 mt-5 shadow-md rounded-md">
          <legend className="ml-5 text-sm font-bold  ">Others</legend>
          <div className="flex justify-between p-2">
            <textarea
              type="text"
              name="input"
              value={others.input}
              onChange={handleOthersChange}
              className="w-[70%] h-20  text-black bg-fuchsia-50 border border-black p-1 text-sm"
            />
            <label className="mt-12 ml-28  text-lg">Total</label>
            <input
              type="number"
              name="amount"
              value={others.amount}
              onChange={handleOthersChange}
              className="h-8 mt-12 text-black text-right text-sm"
            />
          </div>
        </fieldset>

        <fieldset className=" mt-5 shadow-md rounded-md">
          <div className="p-3">
            <hr className="w-[35%] border-black ml-[64%]" />

            <p className="flex items-center justify-end p-5 text-3xl ">
              TOTAL {fullTotal.toFixed(2)}
            </p>
            <hr className="w-[35%] border-black ml-[64%]" />

            <hr className="w-[35%] border-black mt-1 ml-[64%]" />
          </div>
        </fieldset>
        <fieldset className=" bg-fuchsia-50 shadow-md mt-5 rounded-md">
          <legend className="ml-5 text-sm font-bold  ">
            Reason for request{" "}
            <Tooltip title={"Required"}>
              <span className="text-red-500">*</span>
            </Tooltip>
          </legend>
          <div className="p-2">
            <textarea
              type="text"
              name=""
              id=""
              required
              value={reasonForRequest}
              className="w-full h-28 border text-black  bg-fuchsia-50 border-black p-2 text-sm"
              onChange={handleReasonForRequestChange}
            />
          </div>
        </fieldset>
        <fieldset className="mt-5">
          <div>
            <p className="text-xs ">
              By signing below, I agree to account for this advance within three
              working days of the projected dates as indicated above, either
              with adequate receipts or cash for the balance. I understand that
              my failure to account for the advanced funds in full within 14
              days will result in a payroll deduction for the balance due. By
              signing below, I agree to allow Nanosoft Global (Pvt) Limited to
              make any such deductions  from my pay.
            </p>
          </div>
          <div className="flex items-center justify-between mt-10 mb-5">
            <div className="">
              <Checkbox onChange={handleAgreeCheckboxChange} checked={agreed} />{" "}
              <span className="text-xs  ">Agree and Continue <Tooltip title={"required"}><span className="text-red-500 text-sm">*</span></Tooltip></span>
              {agreeError && (
                <div className="text-red-500 mt-2 text-xs absolute">
                  {agreeError}
                </div>
              )}
            </div>

            <div className=" items-center justify-end m-5 mr-8 ">
              <button
                onClick={handleSendButtonClick}
                disabled={!agreed || isSaveButtonDisabled || fullTotal === 0}
                style={{
                  backgroundColor:
                    !agreed || isSaveButtonDisabled || fullTotal === 0 ? "#808080" : "#111827",
                  cursor:
                    !agreed || isSaveButtonDisabled || fullTotal === 0 ? "not-allowed" : "pointer",
                  color: "white",
                  cursor: "pointer",
                  marginRight: "10px",
                  height: "30px",
                  fontSize: "12px",
                  width: "100px",
                }}
                className="rounded focus:outline-none hover:shadow-md"
              >
                {isSaveButtonDisabled ? "SENDING..." : "SEND"}
              </button>
              <Button
                variant="outlined"
                onClick={handleCancelClick}
                style={{ height: "30px", fontSize: "12px" }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </fieldset>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CashAdvanceRequestForm;
