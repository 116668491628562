// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.active {
  background-color: #000036;
  color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/component/navBar/navBar.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,cAAA;AACJ","sourcesContent":[".active {\n    background-color: #000036; \n    color: #ffffff; \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
