// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart {
  flex: 4 1;
  box-shadow: 10px 10px 5px -2px rgba(207, 203, 203, 0.75);
  -webkit-box-shadow: 10px 10px 5px -2px rgba(207, 203, 203, 0.75);
  -moz-box-shadow: 10px 10px 5px -2px rgba(207, 203, 203, 0.75);
  padding: 10px;
  color: gray;
}
.chart .title {
  margin-bottom: 20px;
}
.chart .chartGrid {
  stroke: rgb(233, 230, 230);
}`, "",{"version":3,"sources":["webpack://./src/component/chart/chart.scss"],"names":[],"mappings":"AAAA;EAEI,SAAA;EACA,wDAAA;EACA,gEAAA;EACA,6DAAA;EACA,aAAA;EACA,WAAA;AAAJ;AACI;EACI,mBAAA;AACR;AACI;EACI,0BAAA;AACR","sourcesContent":[".chart{\n    \n    flex: 4;\n    box-shadow: 10px 10px 5px -2px rgba(207,203,203,0.75);\n    -webkit-box-shadow: 10px 10px 5px -2px rgba(207,203,203,0.75);\n    -moz-box-shadow: 10px 10px 5px -2px rgba(207,203,203,0.75);\n    padding: 10px;\n    color: gray;\n    .title{\n        margin-bottom: 20px;\n    }\n    .chartGrid{\n        stroke: rgb(233, 230, 230);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
