import React, { useState, useEffect } from "react";
import Navbar from "../../component/navBar/navBar";
import { TextField } from "@mui/material";
import ReactPaginate from "react-paginate";
import RuleIcon from "@mui/icons-material/Rule";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../userContext";
import { Box, LinearProgress } from "@mui/material";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import FilterListIcon from "@mui/icons-material/FilterList";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid } from "@mui/x-data-grid"; 
import "./style.scss";

const PendingTable = () => {
  const navigate = useNavigate();
  const [pendingRequests, setPendingRequests] = useState([]);
  const [projects, setProjects] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingPageChange, setLoadingPageChange] = useState(false);
  const { user } = useUser();
  const [searchTerm, setSearchTerm] = useState("");
  const rowPerPage = 10;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    fetchPendingRequests();
  }, [currentPage, user]);

  const fetchPendingRequests = async () => {
    try {
      setLoading(true);

      let apiEndpoint;

      const hasPermission20 = user.permissions.includes(20);
      const hasPermission1 = user.permissions.includes(1);

      if (hasPermission20 || hasPermission1) {
        apiEndpoint = `${process.env.REACT_APP_API}/cashRequest/getAllPendingCashAdvanceRequests`;
      } else {
        apiEndpoint = `${process.env.REACT_APP_API}/cashRequest/getAllPendingCashAdvanceRequests/${user.id}`;
      }

      const response = await fetch(apiEndpoint);
      const data = await response.json();

      if (Array.isArray(data)) {
        setPendingRequests(data);
      } else {
        console.error("Invalid data received:", data);
      }
    } catch (error) {
      console.error("Error fetching pending requests:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchProjects = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API}/project/getAllProjects`
      );
      const data = await response.json();

      if (Array.isArray(data)) {
        setProjects(data);
      } else {
        console.error("Invalid data received:", data);
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const getProjectNameById = (projectId) => {
    const project = projects.find((project) => project.projectID === projectId);
    return project ? project.projectName : "";
  };

  const formatTotalCost = (cost) => {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(cost);
  };

  const handlePageChange = async ({ selected }) => {
    try {
      setLoadingPageChange(true);

      await new Promise((resolve) => setTimeout(resolve, 1000));

      setCurrentPage(selected);
    } finally {
      setLoadingPageChange(false);
    }
  };

  const handleViewClick = (requestID) => {
    navigate(`/cashAdvance/pending/viewRequest/${requestID}`);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredRequests = pendingRequests.filter((request) => {
    const isMatchingProject = (
      getProjectNameById(request.projectID) &&
      getProjectNameById(request.projectID)
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
  
    const isMatchingOfficer = (
      request.officerName &&
      request.officerName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  
    const isMatchingReason = (
      request.reason &&
      request.reason.toLowerCase().includes(searchTerm.toLowerCase())
    );
  
    const isMatchingTotalAmount = (
      request.totalAmount &&
      request.totalAmount
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
  
    const isMatchingRequestStatus = (
      request.requestStatus &&
      request.requestStatus.toLowerCase().includes(searchTerm.toLowerCase())
    );
  
    const isMatchingRequestID = (
      request.requestID &&
      request.requestID
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
  
    const isMatchingRequiredDate = (
      request.requiredDate &&
      request.requiredDate
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
  
    const isWithinDateRange = (
      (!startDate || new Date(request.requiredDate) >= startDate) &&
      (!endDate || new Date(request.requiredDate) <= endDate)
    );
  
    return (
      (isMatchingProject ||
        isMatchingOfficer ||
        isMatchingReason ||
        isMatchingTotalAmount ||
        isMatchingRequestStatus ||
        isMatchingRequiredDate ||
        isMatchingRequestID) && isWithinDateRange
    );
  });
  
  

  const handleDateFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDateFilter = () => {
    setAnchorEl(null);
  };

  const columns = [
    { field: "requestID", headerName: "Request ID", flex: 1 },
    {
      field: "projectID",
      headerName: "Project Name",
      flex: 2,
      valueGetter: (params) => getProjectNameById(params.row.projectID),
    },
    { field: "officerName", headerName: "Officer Name", flex: 2 },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      flex: 1.5,
      align: 'right',
      renderCell: (params) => (
        <><Tooltip title={formatTotalCost(params.row.totalAmount / 100)}>{formatTotalCost(params.row.totalAmount / 100)}</Tooltip></>
      ),
    },
    { field: "requestStatus", headerName: "Request Status", flex: 1 },
    { field: "requiredDate", headerName: "Required Date", flex: 1.1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={"Pending info"}>
          <RuleIcon
            className="text-[orange] p-1 cursor-pointer"
            onClick={() => handleViewClick(params.row.requestID)}
          />
        </Tooltip>
      ),
    },
  ];

  const getRowId = (row) => row.id;
  const getRowHeight = () => 39;

  const offset = currentPage * rowPerPage;
  const paginatedRequests = filteredRequests
  .slice(offset, offset + rowPerPage)
  .map((request) => ({
    id: request.requestID, 
    ...request,
  }));

  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div className="userTableContainer w-[95%] mt-5 ml-5 ">
        {(loading || loadingPageChange) && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "60%",
              transform: "translate(-50%, -50%)",
            }}
            className="z-50 bg-transparent"
          >
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
            <div className="mt-2">Loading...</div>
          </div>
        )}
        <div className=" shadow-md border-b-2">
          <div className="flex items-center justify-between p-2">
            <TextField
              type="text"
              placeholder="Search..."
              className="border border-gray-300 rounded px-3 py-1 w-[50%]"
              inputProps={{
                style: {
                  height: "23px",
                  padding: "5px",
                  fontSize: "13px",
                },
              }}
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <Tooltip title={"Date filter"}>
              <FilterListIcon
                onClick={handleDateFilterClick}
                className="mb-4 lg:mb-0 relative ml-[35%] cursor-pointer items-end justify-between"
              />
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseDateFilter}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem>
                <div className="p-4 w-72">
                  <div className="mb-4">
                    <label className="block text-xs font-semibold text-gray-700 mb-1">
                      Start Date
                    </label>
                    <input
                      type="text"
                      placeholder="Start Date"
                      className="border border-gray-300 rounded px-3 py-2 w-full focus:outline-none focus:border-blue-500 h-7 text-xs"
                      ref={(startDateInput) => {
                        if (startDateInput) {
                          flatpickr(startDateInput, {
                            onChange: (selectedDates) => {
                              setStartDate(selectedDates[0]);
                            },
                          });
                        }
                      }}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-xs font-semibold text-gray-700 mb-1">
                      End Date
                    </label>
                    <input
                      type="text"
                      placeholder="End Date"
                      className="border border-gray-300 rounded px-3 py-2 w-full focus:outline-none focus:border-blue-500 h-7 text-xs"
                      ref={(endDateInput) => {
                        if (endDateInput) {
                          flatpickr(endDateInput, {
                            onChange: (selectedDates) => {
                              setEndDate(selectedDates[0]);
                            },
                          });
                        }
                      }}
                    />
                  </div>
                  {/* Additional elements or instructions */}
                  <p className=" text-gray-600 text-xs">
                    Select the start and end dates for filtering.
                  </p>
                </div>
              </MenuItem>
            </Menu>
          </div>
          <div style={{ height: "100%", width: "100%" }}>
            <DataGrid
              rows={paginatedRequests}
              columns={columns.map((column) => ({
                ...column,
                headerClassName: 'custom-header',
                cellClassName: 'custom-cell',
              }))}
              pageSize={rowPerPage}
              page={currentPage}
              pagination={false}
              pageSizeOptions={[]}
              disablePrevButton={true}
              disableNextButton={true}
              hideFooter
              getRowId={getRowId}
              getRowHeight={getRowHeight}
            />
          </div>
        </div>
        <div className="pagination-container">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={Math.ceil(filteredRequests.length / rowPerPage)}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
            onPageChange={handlePageChange}
            containerClassName="pagination"
            activeClassName="active"
          />
        </div>
      </div>
    </div>
  );
};

export default PendingTable;
