// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination li.active a {
  background-color: #00ff00;
  color: #fff;
}

.pagination li.disabled a {
  color: #999;
  pointer-events: none;
}

.pagination {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 20px;
}

.pagination li {
  list-style: none;
  margin-right: 5px;
}

.pagination li a {
  display: block;
  padding: 5px 10px;
  border-radius: 3px;
  background-color: #f5f5f5;
  color: #333;
}

.pagination li.active a {
  background-color: #007bff;
  color: #fff;
}

.pagination li.disabled a {
  color: #999;
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/component/table/table.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,WAAA;AACJ;;AACA;EACI,WAAA;EACA,oBAAA;AAEJ;;AAAE;EACE,aAAA;EACA,oBAAA;EACA,mBAAA;EACA,gBAAA;AAGJ;;AAAE;EACE,gBAAA;EACA,iBAAA;AAGJ;;AAAE;EACE,cAAA;EACA,iBAAA;EACA,kBAAA;EACA,yBAAA;EACA,WAAA;AAGJ;;AAAE;EACE,yBAAA;EACA,WAAA;AAGJ;;AAAE;EACE,WAAA;EACA,oBAAA;AAGJ","sourcesContent":[".pagination li.active a {\n    background-color: #00ff00;\n    color: #fff;\n  }\n.pagination li.disabled a {\n    color: #999;\n    pointer-events: none;\n  }\n  .pagination {\n    display: flex;\n    justify-content: end;\n    align-items: center;\n    margin-top: 20px;\n  }\n  \n  .pagination li {\n    list-style: none;\n    margin-right: 5px;\n  }\n  \n  .pagination li a {\n    display: block;\n    padding: 5px 10px;\n    border-radius: 3px;\n    background-color: #f5f5f5;\n    color: #333;\n  }\n  \n  .pagination li.active a {\n    background-color: #007bff;\n    color: #fff;\n  }\n  \n  .pagination li.disabled a {\n    color: #999;\n    pointer-events: none;\n  }\n     "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
