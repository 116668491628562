import React, { useState, useEffect } from "react";
import axios from "axios";

const RejectedRequestInfo = (props) => {
  const { request } = props;
  const [additionalInfo, setAdditionalInfo] = useState(null);
  const [projectName, setProjectName] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [maxBudget, setMaxBudget] = useState(0);
  const [minBudget, setMinBudget] = useState(0);

  useEffect(() => {
    const fetchAdditionalInfo = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/cashRequest/getRejectReasonByRequestID/${request.requestID}`
        );

        setAdditionalInfo(response.data);

        const projectResponse = await axios.get(
            `${process.env.REACT_APP_API}/project/getAllProjects`
          );
          const projects = projectResponse.data.reduce(
            (acc, project) => ({
              ...acc,
              [project.projectID]: project.projectName,
            }),
            {}
          );
          setProjectName(projects[request.projectID]);
      } catch (error) {
        console.error("Error fetching additional info:", error);
      }
    };

    fetchAdditionalInfo();
  }, [request.requestID]);

  useEffect(() => {
    if (request) {
      fetch(
        `${process.env.REACT_APP_API}/transaction/getAllApprovalTransactionsByProjectID/${request.projectID}`
      )
        .then((response) => response.json())
        .then((transactions) => {
          const sumAmount = transactions.reduce(
            (total, transaction) => total + transaction.totalCost,
            0
          );

          setTotalAmount(sumAmount);
        })
        .catch((error) => {
          console.error("Error fetching transactions:", error);
        });
    }
  }, [request]);

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API}/project/getAllProjects`
        );
        const projects = await response.json();
        const project = projects.find(
          (project) => project.projectID === request.projectID
        );
        if (project) {
          setMaxBudget(project.maxBudget);
          setMinBudget(project.minBudget);
        }
      } catch (error) {
        console.error("Error fetching project details:", error);
      }
    };

    if (request && request.projectID) {
      fetchProjectDetails();
    }
  }, [request]);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  const formatTotalCost = (cost) => {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(cost);
  };

  return (
    <div className="border p-4 m-4 bg-white rounded-md shadow-lg h-auto">
      <table className="mb-5 w-full border-collapse text-sm border">
        <tbody>
        <tr>
          <td className=" p-2 text-start text-xs bg-[#111827] text-white w-[200px]">Cash Advance Request ID</td>
          <td><span className=" p-2 text-xs text-gray-600">{request.requestID}</span></td>
        </tr>
        <tr>
          <td className=" p-2 text-start text-xs bg-[#111827] text-white w-[200px]">Total Amount</td>
          <td><span className=" p-2 text-xs text-gray-600">{formatTotalCost(request.totalAmount/100)} LKR </span></td>
        </tr>
        <tr>
          <td className=" p-2 text-start text-xs bg-[#111827] text-white w-[200px]">Project Name</td>
          <td> <span className=" p-2 text-xs text-gray-600">{projectName}</span> </td>
        </tr>
        <tr className="">
                  <td className="p-2 text-start text-xs bg-[#111827] text-white w-[200px]">
                    Project Max Budget
                  </td>
                  <td className="p-2 text-xs text-gray-600">
                    {formatTotalCost(maxBudget)}
                  </td>
                </tr>
                <tr className="">
                  <td className="p-2 text-start text-xs bg-[#111827] text-white w-[200px]">
                    Project Min Budget
                  </td>
                  <td className="p-2 text-xs text-gray-600">
                    {formatTotalCost(minBudget)}
                  </td>
                </tr>
                <tr className="">
                  <td className="p-2 text-start text-xs bg-[#111827] text-white w-[200px]">
                    Project Total Expenses
                  </td>
                  <td className="p-2 text-xs text-gray-600">
                    {formatTotalCost(totalAmount)}
                  </td>
                </tr>
        <tr>
          <td className=" p-2 text-start text-xs bg-[#111827] text-white w-[200px]">Reason for Request </td>
          <td><span className=" p-2 whitespace-pre-line row-span-2 text-xs text-gray-600 ">{request.reason}</span> </td>
        </tr>
        </tbody>
      </table>
      {additionalInfo && (
        <table className="w-full border-collapse text-xs border">
          <tbody>
            <tr>
              <td className=" p-2 text-start text-xs bg-[#111827] text-white w-[200px]">
                Request Reject Officer Name
              </td>
              <td className=" p-2 text-xs text-gray-600">{additionalInfo[0].officerName}</td>
            </tr>
            <tr>
              <td className=" p-2 text-start text-xs bg-[#111827] text-white">Rejected Date</td>
              <td className=" p-2 text-xs text-gray-600">
                {formatDate(additionalInfo[0].createdAt)}
              </td>
            </tr>
            <tr>
              <td className=" p-2 text-start text-xs bg-[#111827] text-white">Rejected Reason</td>
              <td className=" p-2 whitespace-pre-line row-span-2 text-xs text-gray-600">
                {additionalInfo[0].reason}
              </td>
            </tr>
          </tbody>
        </table>
      )}
      <h3 className="text-sm font-semibold mt-4">Transport </h3>
            <table className="min-w-full divide-y divide-gray-200 mb-4 border bg-white">
              <thead className="bg-[#111827] text-gray-400 text-sm p-2">
                <tr className="border">
                  <th className="border font-mono">Transport type</th>
                  <th className="border font-mono">Km</th>
                  <th className="border font-mono">Total</th>
                </tr>
              </thead>
              <tbody className="text-sm text-gray-600">
                {request.TransportCosts &&
                  request.TransportCosts.map((transport) => (
                    <tr key={transport.transportId}>
                      <td className="border text-center">{transport.type}</td>
                      <td className="border text-center">{transport.miles}</td>
                      <td className="border  text-center">
                        {formatTotalCost(transport.amount / 100)}
                      </td>
                    </tr>
                  ))}
                <tr>
                  <td></td>
                  <td></td>
                  <td>
                    <div className="text-sm mt-5 text-end mb-5 mr-5">
                      <hr className="w-[30%] border-black ml-[70%] " />
                      <p className="">Total</p>{" "}
                      {formatTotalCost(request.transportTotal / 100)}
                      <hr className="w-[30%] border-black ml-[70%]" />
                      <hr className="w-[30%] border-black ml-[70%] mt-1" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <h3 className="text-sm font-semibold mt-4">Accommodation </h3>
            <table className="min-w-full divide-y divide-gray-200 mb-4 border bg-white">
              <thead className="bg-[#111827] text-gray-400 text-sm">
                <tr>
                  <th className="border font-mono">Date</th>
                  <th className="border font-mono">No of Heads</th>
                  <th className="border font-mono">Amount Per Night</th>
                  <th className="border font-mono">Total Per Day</th>
                </tr>
              </thead>
              <tbody className="text-sm text-gray-600">
                {request.AccomadationCosts &&
                  request.AccomadationCosts.map((accommodation) => (
                    <tr key={accommodation.accomadationID}>
                      <td className="border  text-center">
                        {accommodation.date}
                      </td>
                      <td className="border  text-center">
                        {accommodation.noOfHeads}
                      </td>
                      <td className="border  text-center">
                        {formatTotalCost(accommodation.amountPerNight / 100)}
                      </td>
                      <td className="border  text-center">
                        {formatTotalCost(accommodation.amount / 100)}
                      </td>
                    </tr>
                  ))}
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <div className="text-sm mt-5 text-end mb-5 mr-5">
                      <hr className="w-[50%] border-black ml-[50%] " />
                      <p className="">Total</p>{" "}
                      {formatTotalCost(request.accomadationTotal / 100)}
                      <hr className="w-[50%] border-black ml-[50%]" />
                      <hr className="w-[50%] border-black ml-[50%] mt-1" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <h3 className="text-sm font-semibold mt-4">Meal</h3>
            <table className="min-w-full divide-y divide-gray-200 mb-4 border bg-white">
              <thead className="bg-[#111827] text-gray-400 text-sm">
                <tr>
                  <th className="border font-mono">Date</th>
                  <th className="border font-mono">No of Heads</th>
                  <th className="border font-mono">Total Per Day</th>
                </tr>
              </thead>
              <tbody className="text-sm text-gray-600">
                {request.MealCosts &&
                  request.MealCosts.map((meal) => (
                    <tr key={meal.mealID}>
                      <td className="border  text-center">{meal.date}</td>
                      <td className="border  text-center">{meal.noOfHeads}</td>
                      <td className="border  text-center">
                        {formatTotalCost(meal.amount / 100)}
                      </td>
                    </tr>
                  ))}
                <tr>
                  <td></td>
                  <td></td>
                  <td>
                    <div className="text-sm mt-5 text-end mb-5 mr-5">
                      <hr className="w-[35%] border-black ml-[65%] " />
                      <p className="">Total</p>{" "}
                      {formatTotalCost(request.mealTotal / 100)}
                      <hr className="w-[35%] border-black ml-[65%]" />
                      <hr className="w-[35%] border-black ml-[65%] mt-1" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <h3 className="text-sm font-semibold mt-4">Other</h3>
            <table className="min-w-full divide-y divide-gray-200 mb-4 border bg-white">
              <thead className="bg-[#111827] text-gray-400 text-sm">
                <tr>
                  <th className="border font-mono">Reason</th>
                  <th className="border font-mono">Amount</th>
                </tr>
              </thead>
              <tbody className="text-sm text-gray-600">
                {request.OtherCosts &&
                  request.OtherCosts.map((other) => (
                    <tr key={other.otherID}>
                      <td className="border  text-center">{other.reason}</td>
                      <td className="border  text-center">
                        {formatTotalCost(other.amount)}
                      </td>
                    </tr>
                  ))}
                <tr>
                  <td></td>
                  <td>
                    {request.OtherCosts &&
                      request.OtherCosts.map((other) => (
                        <div
                          className="text-sm mt-5 text-end mb-5 mr-5"
                          key={other.otherID}
                        >
                          <hr className="w-[25%] border-black ml-[75%] " />
                          <p className="">Total</p>{" "}
                          {formatTotalCost(other.amount)}
                          <hr className="w-[25%] border-black ml-[75%]" />
                          <hr className="w-[25%] border-black ml-[75%] mt-1" />
                        </div>
                      ))}
                  </td>
                </tr>
              </tbody>
            </table>
    </div>
  );
};

export default RejectedRequestInfo;
