import React from "react";
import { Link, NavLink } from "react-router-dom";

import "./navBar.scss";

const Navbar = () => {
  return (
    <nav className="bg-white pt-[13px] pb-[13px] pl-[20px] pr-[35px]  lg:h-14 shadow-md">
      <div className="container mx-auto flex justify-between items-center">
        <div className="sm:flex space-x-5">
          <NavLink
            to="/transactions/approval"
            className="text-[#111827] capitalize transition-all duration-300 p-2  hover:text-gray-400 text-xs rounded-md"
            activeClassName="active"
          >
            Approved Transactions
          </NavLink>

          <NavLink
            to="/transactions/pending"
            className="text-[#111827] capitalize transition-all duration-300 p-2  hover:text-gray-400 text-xs rounded-md"
            activeClassName="active"
          >
            Pending Transactions
          </NavLink>

          <NavLink
            to="/transactions/rejected"
            className="text-[#111827] capitalize transition-all duration-300 p-2  hover:text-gray-400 text-xs rounded-md"
            activeClassName="active"
          >
            Rejected Transactions
          </NavLink>
        </div>
        
      </div>
    </nav>
  );
};

export default Navbar;
