import React, { useState } from "react";
//import "./newOfficerStyle.scss";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


const New = (props) => {
  const navigate = useNavigate();
  const[officerNameError,setOfficerNameError]=useState("");
  const { onClose, setSuccess, officer } = props;
  const [formData, setFormData] = useState(() => {
    if (officer) {
      return {
        officerID: officer.officerID || "",
        officerName: officer.officerName || "",
      };
    }
    return {
      officerID: "",
      officerName: "",
    };
  });

  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "officerName") {
      const allowedCharactersRegex = /^[a-zA-Z0-9., ]*$/;
      if (!allowedCharactersRegex.test(value)) {
        toast.error("Invalid characters.");
        return;
      }
      if (name === "officerName") {
        if (value.length < 4) {
          setFormData({
            ...formData,
            [name]: value,
          });
        setOfficerNameError(
            `Officer name must be at least ${4} characters long.`
          );
          return;
        } else {
          setOfficerNameError("");
        }
      }

      if (value.length > 30) {
        toast.error("Maximum character count exceeded.");
        return;
      }
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setButtonDisabled(true);
      if (formData.officerName.length < 4) {
        toast.warning("Officer name must be at least 4 characters long.");
        setButtonDisabled(false);
        return;
      }
      const response = await axios.put(
        `${process.env.REACT_APP_API}/officer/updateOfficer/${officer.officerID}`,
        formData
      );

      if (response.status === 200) {
        setSuccess(true);
        Swal.fire({
          icon: 'success',
          title:"Success!",
          text: "Officer updated successfully",
        });
        onClose();
      } else {
        Swal.fire({
          icon: 'error',
          title:"Error!",
          text: "Failed to update officer",
        });
        
      }
    } catch (error) {
      console.error("Error updating officer:", error);
      Swal.fire({
        icon: 'error',
        title:"Error!",
        text: "An error occurred while updating the officer",
      });
    } finally {
      setButtonDisabled(false);
    }
  };

  return (
    <div className="flex w-full ">
      <div className="w-full p-4">
        <div className="flex">
          <div className="w-1/4"></div>
          <div className="w-3/4">
            <form onSubmit={handleSubmit} className="flex flex-wrap gap-4">
              <div className="w-2/3">
                <TextField
                  id="officerName"
                  label="Officer name"
                  variant="outlined"
                  name="officerName"
                  required
                  fullWidth
                  value={formData.officerName}
                  onChange={handleInputChange}
                />
                {officerNameError && (
                  <span className="errorText text-xs">{officerNameError}</span>
                )}
              </div>
              <div className="w-2/6">
              <button
                  type="submit"
                  variant="contained"
                  disabled={isButtonDisabled}
                  className="rounded focus:outline-none hover:shadow-md"
                  style={{
                  
                    backgroundColor: isButtonDisabled ? "#808080" : "#111827",
                    color: "white",
                    cursor: isButtonDisabled ? "not-allowed" : "pointer",
                    height:"30px",
                    fontSize:"12px",
                    width:"120px"
                  }}
                >
                  {isButtonDisabled ? "UPDATING..." : "UPDATE"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default New;
