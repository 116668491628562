import React from "react";
import { Link, NavLink } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import "./navBar.scss";
import { useUser } from "../../userContext";

const Navbar = () => {
  const {user}=useUser();

  const createRequestAccess = user.permissions.some(
    (permission) => permission === 1 || permission === 18
  );

  return (
    <nav className="bg-white pt-[13px] pb-[13px] pl-[20px] pr-[35px]  lg:h-14 shadow-md">
      <div className="container mx-auto flex justify-between items-center">
        <div className="sm:flex space-x-5">
          <NavLink
            to="/cashAdvance/approval"
            className="text-[#111827] capitalize transition-all duration-300 p-2  hover:text-gray-400 text-xs rounded-md"
            activeClassName="active"
          >
            Approved Requests
          </NavLink>

          <NavLink
            to="/cashAdvance/pending"
            className="text-[#111827] capitalize transition-all duration-300 p-2  hover:text-gray-400 text-xs rounded-md"
            activeClassName="active"
          >
            Pending Requests
          </NavLink>

          <NavLink
            to="/cashAdvance/rejected"
            className="text-[#111827] capitalize transition-all duration-300 p-2  hover:text-gray-400 text-xs rounded-md"
            activeClassName="active"
          >
            Rejected Requests
          </NavLink>
        </div>
        <div className="flex items-center">
          {createRequestAccess && (
            <Link to={"/newCashAdvanceRequest"}>
            <Button
            variant="outlined"
            style={{ height: "30px", fontSize: "12px" }}
            startIcon={<AddIcon />}
            
          >
            Make request
          </Button>
            </Link>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
