import React, { useState, useEffect } from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import { ToastContainer, toast } from "react-toastify";
import Checkbox from "@mui/material/Checkbox";
import AsyncSelect from "react-select/async";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

const AddNewUser = (props) => {
  const { onClose, setSuccess, rowsData } = props;

  const [userData, setUserData] = useState({
    username: "",
    password: "",
    confirmPassword: "",
    user: null,
  });
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [usernameError, setUsernameError] = useState("");
  const [selectedActions, setSelectedActions] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [hiddenPermissionId] = useState(1);
  const [officerOptions, setOfficerOptions] = useState([]);
  const [selectedOfficer, setSelectedOfficer] = useState("");

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/permissions/getAllPermissions`
        );
        setPermissions(response.data);
      } catch (error) {
        console.error("Error fetching permissions:", error.message);
      }
    };

    fetchPermissions();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "username") {
      if (e.nativeEvent.inputType === "deleteContentBackward") {
        setUserData({
          ...userData,
          [name]: value,
        });
        return;
      }

      const firstThreeChars = value.slice(0, 3);
      if (!/^[a-zA-Z]+$/.test(firstThreeChars)) {
        toast.warning("First three characters must be letters.");
        return;
      }
    }
    if (name === "username" && value.length > 20) {
      const truncatedValue = value.slice(0, 20);
      setUserData({
        ...userData,
        [name]: truncatedValue,
      });
      toast.warning("Username must be at most 20 characters long.");
      return;
    }
    if (name === "username") {
      if (value.length < 5) {
        setUserData({
          ...userData,
          [name]: value,
        });
        setUsernameError(`Username must be at least ${5} characters long.`);
        return;
      } else {
        setUsernameError("");
      }
    }
    if (value === "") {
      setUserData({
        ...userData,
        [name]: value,
      });
      return;
    }
    if (name === "username" && !/^[a-zA-Z0-9@_.]+$/.test(value)) {
      toast.error("Invalid characters.");
      const truncatedValue = value.replace(/[^a-zA-Z0-9@_.]/g, "");
      setUserData({
        ...userData,
        [name]: truncatedValue,
      });
      return;
    }

    if (
      (name === "password" || name === "confirmPassword") &&
      value.length > 16
    ) {
      const truncatedValue = value.slice(0, 16);
      setUserData({
        ...userData,
        [name]: truncatedValue,
      });

      if (name === "password") {
        toast.warning("Password must be at most 16 characters long.");
      } else {
        toast.warning("Confirm password must be at most 16 characters long.");
      }
      return;
    }

    if (
      (name === "password" || name === "confirmPassword") &&
      !/^[a-zA-Z0-9!@#,$.\\]+$/.test(value)
    ) {
      toast.error("This character not allowed for password.");
      return;
    }

    if (name === "password") {
      setPasswordError("");
    } else if (name === "confirmPassword") {
      setConfirmPasswordError("");
    }
    if (name === "confirmPassword") {
      setConfirmPasswordError("");

      const hasLowerCase = /[a-z]/.test(userData.password);
      const hasUpperCase = /[A-Z]/.test(userData.password);
      const hasNumber = /[0-9]/.test(userData.password);
      const hasSpecialChar = /[!@#,$.]/.test(userData.password);

      if (!(hasLowerCase && hasUpperCase && hasNumber && hasSpecialChar)) {
        setConfirmPasswordError(
          "Password must contain at least one lowercase letter, one uppercase letter, one numeric number, and one special character (@#$,.!)"
        );
        return;
      }
    }

    setUserData({
      ...userData,
      [name]: value,
    });
  };
  const handleActionCheckboxChange = (action) => {
    if (action.id === hiddenPermissionId) {
      return null;
    }
    if (selectedActions.includes(action.id)) {
      setSelectedActions((prevActions) =>
        prevActions.filter((selectedAction) => selectedAction !== action.id)
      );
    } else {
      setSelectedActions((prevActions) => [...prevActions, action.id]);
    }
  };
  const renderActionCheckbox = (action) => {
    if (action.id === hiddenPermissionId) {
      return null;
    }

    return (
      <div key={action.id} className="flex items-center mb-2">
        <Checkbox
          type="checkbox"
          id={action.id}
          checked={selectedActions.includes(action.id)}
          onChange={() => handleActionCheckboxChange(action)}
          style={{
            width: "10px",
            height: "10px",
            marginRight: "6px",
            position: "relative",
          }}
        />
        <label htmlFor={action.id} className="ml-2 text-left text-xs">
          {action.label}
        </label>
      </div>
    );
  };
  const loadOfficerOptions = (inputValue, callback) => {
    axios
      .get(`${process.env.REACT_APP_API}/officer/getAllOfficers`)
      .then((response) => {
        const officers = response.data.map((officer) => ({
          value: officer.officerID,
          label: officer.officerName,
        }));
        callback(filterOfficer(inputValue, officers));
      })
      .catch((error) => {});
  };
  const filterOfficer = (inputValue, officers) => {
    return officers.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const handleOfficerChange = async (selectedOption) => {
    setSelectedOfficer(selectedOption);

    if (selectedOption) {
      const selectedOfficerName = selectedOption.label;

      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/user/allUsers`);
        const allUsers = response.data;

        const officerExists = allUsers.some(user => user.officerName === selectedOfficerName);
        const officerIsActive = allUsers.some(user => user.officerName === selectedOfficerName && user.status === "Active");

        if (officerExists) {
          if (officerIsActive) {
            Swal.fire({
              icon: 'warning',
              title: 'Warning',
              text: 'This officer already has an active account!',
            });
            setSelectedOfficer(null);
          } else {

          }
        }
      } catch (error) {
        console.error('Error fetching users:', error);
        toast.error('Error fetching users');
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isButtonDisabled) {
      return;
    }
    setButtonDisabled(true);
    if (userData.username.length < 5) {
      toast.warning("Username must be at least 5 characters long.");
      setButtonDisabled(false);
      return;
    }

    if (userData.password.length < 8) {
      toast.warning("Password must be at least 8 characters long.");
      setButtonDisabled(false);
      return;
    } else {
      setPasswordError("");
    }

    if (userData.password !== userData.confirmPassword) {
      toast.warning("Passwords do not match.");
      setButtonDisabled(false);
      return;
    } else {
      setConfirmPasswordError("");
    }
    if(!selectedOfficer){
      toast.warning("Please select officer name.")
    }

    try {
      const existingUser = rowsData.find(
        (user) =>
          user.userName.toLowerCase() === userData.username.toLowerCase()
      );

      if (existingUser) {
        toast.warning("Username already exists.");
        setButtonDisabled(false);
        return;
      }
      const userToSave = {
        username: userData.username,
        officerName: selectedOfficer.label,
        password: userData.password,
        permissions: selectedActions,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API}/user/saveUser`,
        userToSave
      );

      if (!response || !response.data) {
        throw new Error("Invalid response from the server");
      }

      Swal.fire({
        icon: 'success',
        title: "Success!",
        text: "User added successfully.",
      });
      onClose();
      setSuccess();

      setUserData({
        username: "",
        password: "",
        confirmPassword: "",
        user: null,
        selectedOfficer: "",
      });
      setButtonDisabled(false);
    } catch (error) {
      console.error(error.response?.data?.error || error.message);
      Swal.fire({
        icon: 'error',
        title: "Error!",
        text: error.response?.data?.error || "An error occurred while submitting the user",
      });
      setButtonDisabled(false);
    }
  };
  // const handleOfficerChange = (selectedOption) => {
  //   setSelectedOfficer(selectedOption);
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (isButtonDisabled) {
  //     return;
  //   }
  //   setButtonDisabled(true);
  //   if (userData.username.length < 5) {
  //     toast.warning("Username must be at least 5 characters long.");
  //     setButtonDisabled(false);
  //     return;
  //   }

  //   if (userData.password.length < 8) {
  //     toast.warning("Password must be at least 8 characters long.");
  //     setButtonDisabled(false);
  //     return;
  //   } else {
  //     setPasswordError("");
  //   }

  //   if (userData.password !== userData.confirmPassword) {
  //     toast.warning("Passwords do not match.");
  //     setButtonDisabled(false);

  //     return;
  //   } else {
  //     setConfirmPasswordError("");
  //   }
  //   if (!selectedOfficer) {
  //     toast.warning("Please select officer name.");
  //   }

  //   try {
  //     const existingUser = rowsData.find(
  //       (user) =>
  //         user.userName.toLowerCase() === userData.username.toLowerCase()
  //     );

  //     if (existingUser) {
  //       toast.warning("Username already exists.");
  //       setButtonDisabled(false);
  //       return;
  //     }
  //     const userToSave = {
  //       username: userData.username,
  //       officerName: selectedOfficer.label,
  //       password: userData.password,
  //       permissions: selectedActions,
  //     };

  //     const response = await axios.post(
  //       `${process.env.REACT_APP_API}/user/saveUser`,
  //       userToSave
  //     );
  //     Swal.fire({
  //       icon: "success",
  //       title: "Success!",
  //       text: "User added successfully.",
  //     });
  //     onClose();
  //     setSuccess();

  //     setUserData({
  //       username: "",
  //       password: "",
  //       confirmPassword: "",
  //       user: null,
  //       selectedOfficer: "",
  //     });
  //     setButtonDisabled(false);
  //   } catch (error) {
  //     console.error(error.response.data.error);
  //     Swal.fire({
  //       icon: "error",
  //       title: "Error!",
  //       text: error.response.data.error,
  //     });
  //     Swal.fire({
  //       icon: "error",
  //       title: "Error!",
  //       text: "An error occurred while submitting the user",
  //     });
  //     setButtonDisabled(false);
  //   }
  // };

  return (
    <div className="flex w-full ">
      <div className="w-full p-4">
        <div className="flex justify-end ">
          <div className="w-3/4 right ">
            <form onSubmit={handleSubmit} className="flex flex-wrap gap-4">
              <div className="w-2/3">
                <TextField
                  id="username"
                  label="User Name"
                  variant="outlined"
                  name="username"
                  required
                  className="textField"
                  type="text"
                  value={userData.username}
                  onChange={handleInputChange}
                  style={{ width: "100%" }}
                  size="small"
                />
                {usernameError && (
                  <span className="errorText text-xs">{usernameError}</span>
                )}
              </div>

              <div className="w-2/3">
                <TextField
                  id="password"
                  label="Password"
                  variant="outlined"
                  name="password"
                  required
                  className="textField"
                  type="password"
                  value={userData.password}
                  onChange={handleInputChange}
                  style={{ width: "100%" }}
                  size="small"
                />
                {confirmPasswordError && (
                  <span className="errorText text-xs">
                    {confirmPasswordError}
                  </span>
                )}
              </div>
              <div className="w-2/3">
                <TextField
                  id="confirmPassword"
                  label="Confirm password"
                  variant="outlined"
                  name="confirmPassword"
                  required
                  className="textField text-sm"
                  type="password"
                  value={userData.confirmPassword}
                  onChange={handleInputChange}
                  style={{ width: "100%" }}
                  size="small"
                />
              </div>
              <div className="w-2/3">
                <AsyncSelect
                  required
                  defaultOptions
                  className="selectOption"
                  placeholder="Select officer"
                  loadOptions={loadOfficerOptions}
                  onChange={(selectedOption) =>
                    handleOfficerChange(selectedOption, 0, "officerName")
                  }
                  value={officerOptions.find(
                    (option) => option.value === userData.user?.officerName
                  )}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderColor: "#EEEEEE",
                      width: "100%",
                      borderColor: "#c0c0c0",
                    }),
                  }}
                  // className="ml-5"
                />
              </div>
              <div className="w-2/3 ">
                <p className="text-lg sm:text-sm text-gray-500 font-bold mb-4">
                  User Permissions
                </p>
                <div className="grid grid-cols-3 gap-4 w-[130%]">
                  {permissions.map((action) =>
                    renderActionCheckbox({
                      id: action.permissionID,
                      label: action.permissionType,
                    })
                  )}
                </div>
              </div>
              <div className="w-2/6 ">
                <button
                  type="submit"
                  style={{
                    backgroundColor: isButtonDisabled ? "#808080" : "#111827",
                    color: "white",
                    cursor: isButtonDisabled ? "not-allowed" : "pointer",
                    height: "30px",
                    fontSize: "12px",
                    width: "120px",
                  }}
                  className="rounded focus:outline-none hover:shadow-md"
                >
                  {isButtonDisabled ? "SUBMITTING..." : "SUBMIT"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddNewUser;
