import React, { useEffect, useState } from "react";
import "./table.scss";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { LinearProgress, TextField } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@mui/x-data-grid";

const TableList = () => {
  const [projects, setProjects] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const rowsPerPage = 5;
  const [loadingPage, setLoadingPage] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const projectsData = await axios.get(
          `${process.env.REACT_APP_API}/project/getAllProjects`
        );
        setProjects(projectsData.data);

        const transactionsData = await axios.get(
          `${process.env.REACT_APP_API}/transaction/getAllApprovalTransactions`
        );
        setTransactions(transactionsData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const calculateTotalAmountForProject = (projectID) => {
    if (!transactions || transactions.length === 0) {
      return 0;
    }

    const allTransactions = transactions.flatMap((head) =>
      head.transactions.filter(
        (transaction) => transaction.projectID === projectID
      )
    );

    const totalAmount = allTransactions.reduce((total, transaction) => {
      return total + transaction.amount;
    }, 0);

    return totalAmount;
  };

  const prepareProjectsData = () => {
    if (projects.length > 0 && transactions.length > 0) {
      const updatedProjects = projects.map((project, index) => {
        const totalAmount = calculateTotalAmountForProject(project.projectID);
        return {
          ...project,
          id: index + 1,  
          totalAmount: totalAmount,
        };
      });
      return updatedProjects;
    }
    return [];
  };
  const sortedProjects = prepareProjectsData().sort(
    (a, b) => a.projectID - b.projectID
  );

  const updatedProjects = sortedProjects
    .filter((project) => {
      if (searchTerm === "") {
        return project;
      } else if (
        project.projectName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (project.maxBudget &&
          project.maxBudget
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase())) ||
        (project.totalAmount &&
          project.totalAmount
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase()))
      ) {
        return project;
      }
      return null;
    })
    .slice(pageNumber * rowsPerPage, (pageNumber + 1) * rowsPerPage);

  const formatTotalCost = (cost) => {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(cost);
  };

  const pageCount = Math.ceil(
    prepareProjectsData().filter(
      (project) =>
        project.projectName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (project.maxBudget &&
          project.maxBudget
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase())) ||
        (project.totalAmount &&
          project.totalAmount
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase()))
    ).length / rowsPerPage
  );

  const handlePageClick = ({ selected }) => {
    setLoadingPage(true);
    setTimeout(() => {
      setPageNumber(selected);
      setLoadingPage(false);
    }, 1500);
  };

  const columns = [
    {
      field: "projectName",
      headerName: "Project Name",
      flex:1,
    },
    {
      field: "maxBudget",
      headerName: "Budget (LKR)",
      flex:1,
      align:"right",
      valueGetter: (params) => formatTotalCost(params.row.maxBudget),
    },
    {
      field: "totalAmount",
      headerName: "Expenses (LKR)",
      flex: 1,
      align:"right",
      valueGetter: (params) => formatTotalCost(params.row.totalAmount),
    },
    {
      field: "status",
      headerName: "",
      flex: 0.5,
      align:"center",
      renderCell: (params) => (
        <div>
          <Tooltip title={"Expenses exceeds maximum budget."} arrow>
            {(params.row.totalAmount > params.row.maxBudget) && (
              <WarningIcon
                style={{
                  marginLeft: "5px",
                  color: "darkred",
                  width: "18px",
                  height: "18px",
                }}
              />
            )}
          </Tooltip>
          <Tooltip title={"Expenses exceeds minimum budget."} arrow>
            {(params.row.totalAmount > params.row.minBudget )&&
              (params.row.totalAmount <= params.row.maxBudget) && (
                <WarningIcon
                  style={{
                    marginLeft: "5px",
                    color: "darkorange",
                    width: "18px",
                    height: "18px",
                  }}
                />
              )}
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setPageNumber(0);
  };

  const getRowId = (row) => row.id;
  const getRowHeight = () => 39;

  return (
    <div className="bg-white ">
      <div className="flex items-center justify-between p-2">
        <TextField
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearch}
          className="border border-gray-300 rounded px-5 py-1 w-[40%] text-sm"
          inputProps={{
            style: {
              height: "25px",
              padding: "5px",
              fontSize:"13px"
            },
          }}
        />
      </div>

      {loadingPage && (
        <div
          style={{
            position: "absolute",
            top: "60%",
            left: "60%",
            transform: "translate(-50%, -50%)",
          }}
          className="bg-transparent z-50"
        >
          <LinearProgress />
          <div>Loading...</div>
        </div>
      )}
      <div style={{ height: "100%", width: "100%" }}>
            <DataGrid
              rows={updatedProjects}
              columns={columns.map((column) => ({
                ...column,
                headerClassName: "custom-header",
                cellClassName: "custom-cell",
              }))}
              pageSize={rowsPerPage}
              page={pageNumber}
              pagination={false}
              pageSizeOptions={[]}
              disablePrevButton={true}
              disableNextButton={true}
              hideFooter
              getRowId={getRowId}
              getRowHeight={getRowHeight}
            />
          </div>
      <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        breakLabel={"..."}
        pageCount={Math.ceil(projects.length / rowsPerPage)}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
      />
    </div>
  );
};

export default TableList;
