import React, { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import TextField from "@mui/material/TextField";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


const NewMealType = (props) => {
  const { onClose, setSuccess } = props;
  const [mealTypeError, setMealTypeError] = useState("");
  const [formData, setFormData] = useState({
    mealType: "",
    price: "",
  });
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const MAX_DECIMAL_POINTS = 2;

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "mealType") {
      const allowedCharactersRegex = /^[a-zA-Z0-9., ]*$/;
      if (!allowedCharactersRegex.test(value)) {
        toast.error("Invalid characters.");
        return;
      }
      if (name === "mealType") {
        if (value.length < 4) {
          setFormData({
            ...formData,
            [name]: value,
          });
          setMealTypeError(
            `Meal type must be at least ${4} characters long.`
          );
          return;
        } else {
            setMealTypeError("");
        }
      }

      if (value.length > 30) {
        toast.error("Maximum character count exceeded.");
        return;
      }
    }
    if (name === "price") {
      const floatValue = parseFloat(value);
      if (floatValue < 0) {
        toast.warning("Price values cannot be negative");
        return;
      }
      const decimalCount = (value.split(".")[1] || []).length;
      if (decimalCount > MAX_DECIMAL_POINTS) {
        toast.warning(`Maximum ${MAX_DECIMAL_POINTS} decimal points allowed`);
        return;
      }
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const submitForm = async (e) => {
    e.preventDefault();

    try {
      setButtonDisabled(true);
      const dataToSend = {
        type: formData.mealType,
        price: parseFloat(formData.price),
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API}/cost/saveMealType`,
        dataToSend
      );
      Swal.fire({
        icon: 'success',
        text: "Meal added successfull",
        title:"Success!",
      });
      onClose();
      setSuccess();

    } catch (error) {
      console.error("Error submitting form:", error);
      Swal.fire({
        icon: 'error',
        title:"Error!",
        text: (error.response.data.error),
      });
      Swal.fire({
        icon: 'error',
        title:"Error!",
        text: "An error occurred while submitting the meal",
      });
    } finally {
      setTimeout(() => {
        setButtonDisabled(false);
      }, 2000);
    }
  };
 
  return (
    <div className="items-center grid grid-cols-1">
      <form onSubmit={submitForm} className="ml-[30%] ">
        <div className="mt-5 w-2/3">
          <TextField
            id="mealType"
            type="text"
            label="Meal type"
            name="mealType"
            required
            value={formData.mealType}
            onChange={handleChange}
            style={{
              width: "300px",
            }}
          />
        </div>
        {mealTypeError && (
          <span className="errorText text-xs">{mealTypeError}</span>
        )}
        <div className="mt-5 w-2/3">
          <TextField
            id="price"
            type="number"
            name="price"
            label="Price per meal"
            required
            value={formData.price}
            onChange={handleChange}
            style={{
              width: "300px",
            }}
          />
        </div>
        <div className="mt-5">
          <button
            type="submit"
            style={{
              backgroundColor: isButtonDisabled ? "#808080" : "#111827",
              color: "white",
              cursor: isButtonDisabled ? "not-allowed" : "pointer",
              height: "30px",
              fontSize: "12px",
              width: "120px",
            }}
            className="rounded focus:outline-none hover:shadow-md"
          >
            {isButtonDisabled ? "SUBMITTING..." : "SUBMIT"}
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default NewMealType;
