import React from "react";
import "./costTypeStyle.scss";
import Sidebar from "../../component/sidebar/sidebar";
import CostType from "../../component/cost/costType";

const Clients = () => {
  return (
    <div className="costs">
      <Sidebar />
      <div className="costsContainer">
        <CostType />
      </div>
    </div>
  );
};
export default Clients;
