import React, { useEffect, useState } from "react";
import { Paper, TextField } from "@mui/material";
import ReactPaginate from "react-paginate";
import "./projectstyle.scss";
import { useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RemoveRedEyeRounded, EditRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import New from "../../pages/new/new";
import AddNewPopup from "../popup/addNewPopup";
import axios from "axios";
import UpdateProjectPopup from "../../component/popup/updatePopup";
import UpdateProject from "../../pages/update/projectUpdate";
import ViewProject from "../../pages/view/projectViewPopup";
import ViewProjectPopup from "../popup/viewPopup";
import { useUser } from "../../userContext";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@mui/x-data-grid";

const Projects = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const successMessage = searchParams.get("success");
  const navigate = useNavigate();
  const [openPopup, setOpenPopup] = useState(false);
  const [openUpdatePopup, setOpenUpdatePopup] = useState(false);
  const [openViewPopup, setOpenViewPopup] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [rows, rowchange] = useState([]);
  const [page, pagechange] = useState(0);
  const [rowperpage, rowperpagechange] = useState(10);
  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchNotFound, setSearchNotFound] = useState(false);
  const [clientData, setClientData] = useState({});
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedProjectView, setSelectedProjectView] = useState(null);
  const { user, setUser } = useUser();
  const [success, setSuccess] = useState("");
  const [approvalTransactions, setApprovalTransactions] = useState([]);

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
    }
  }, [successMessage]);

  const handleAddProject = () => {
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleUpdateProjectOpenPopup = (projectID) => {
    const projectToUpdate = rows.find((row) => row.projectID === projectID);

    setSelectedProject(projectToUpdate);
    setOpenUpdatePopup(true);
  };

  const handleOpdateProjectClosePopup = () => {
    setOpenUpdatePopup(false);
  };

  const handleViewProjectOpenPopup = (projectID) => {
    const projectToView = rows.find((row) => row.projectID === projectID);
    setSelectedProjectView(projectToView);
    setOpenViewPopup(true);
  };
  const handleViewProjectClosePopup = () => {
    setOpenViewPopup(false);
  };

  useEffect(() => {
    fetchProjects();
    fetchClients();
    fetchApprovalTransactions();
  }, [success, page, rowperpage]);

  const fetchProjects = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/project/getAllProjects`,
        { params: { page, pageSize: rowperpage } }
      );
      const formattedData = response.data.map((item, index) => ({
        id: index + 1,
        ...item,
        projectName: item.projectName,
        maxBudget: item.maxBudget,
        minBudget: item.minBudget,
        className: item.clientName,
      }));
      rowchange(formattedData);
      setLoading(false);
      setSuccess("Project submitted successfully");

      calculateTotalCostForProjects();
    } catch (error) {
      console.error("Error fetching projects:", error);
      setLoading(false);
    }
  };

  const fetchClients = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/client/getAllClients`
      );
      const clientDetails = response.data.reduce((acc, client) => {
        acc[client.clientID] = client.clientName;
        return acc;
      }, {});
      setClientData(clientDetails);
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  const fetchApprovalTransactions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/transaction/getAllApprovalTransactions`
      );
      const approvalTransactions = response.data.map((item, index) => ({
        id: index + 1,
        ...item,
      }));
      setApprovalTransactions(approvalTransactions);
    } catch (error) {
      console.error("Error fetching approval transactions:", error);
    }
  };

  const calculateTotalCostForProjects = () => {
    const totalCosts = rows.map((row) => ({
      projectID: row.projectID,
      totalCost: calculateTotalCostForProject(row.projectID),
    }));
  };

  const calculateTotalCostForProject = (projectId) => {
    const projectTransactions = approvalTransactions
      .flatMap((transaction) => transaction.transactions)
      .filter((transaction) => transaction.projectID === projectId);

    const totalCost = projectTransactions.reduce(
      (total, transaction) => total + parseFloat(transaction.amount),
      0
    );

    return totalCost;
  };

  useEffect(() => {
    if (searchQuery === "") {
      setSearchNotFound(false);
    }
  }, [searchQuery]);

  const handlechangepage = (selected) => {
    setLoadingText("Loading...");
    pagechange(selected);
    setPaginationLoading(true);
    setLoadingPage(true);
    setTimeout(() => {
      pagechange(selected.selected);
      setPaginationLoading(false);
      setLoadingPage(false);
      setLoadingText("");
    }, 1500);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    pagechange(0);
    setSearchNotFound(false);
    if (event.target.value === "") {
    }
  };

  const formatNumberWithCommasAnd2Decimals = (number) => {
    const formattedNumber = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
    return formattedNumber;
  };
  const reverseFormatNumberWithCommasAnd2Decimals = (formattedNumber) =>
    parseFloat(formattedNumber.replace(/,/g, ""));

  const columns = [
    { field: "projectName", headerName: "Project Name", flex: 2 },
    {
      field: "maxBudget",
      headerName: "Max Budget (LKR)",
      flex: 1.5,
      align: "right",
    },
    {
      field: "minBudget",
      headerName: "Min Budget (LKR)",
      flex: 1.5,
      align: "right",
    },
    { field: "clientName", headerName: "Client Name", flex: 2 },
    {
      field: "expenses",
      headerName: "Expenses (LKR)",
      flex: 1.5,
      align: "right",
    },
    {
      field: "view",
      headerName: "",
      flex: 0.5,
      align: "center",
      renderCell: (params) => (
        <div>
          {viewAccess && (
            <Tooltip title={"View project"}>
              <RemoveRedEyeRounded
                style={{
                  cursor: "pointer",
                  color: "blue",
                  width: 12,
                  height: 12,
                }}
                onClick={() => handleViewProjectOpenPopup(params.row.projectID)}
              />
            </Tooltip>
          )}
        </div>
      ),
    },
    user.role !== "" && {
      field: "update",
      headerName: "",
      flex: 0.5,
      align: "center",
      renderCell: (params) => (
        <div>
          {updateAccess && (
            <Tooltip title={"Update project"}>
              <EditRounded
                style={{
                  cursor: "pointer",
                  color: "orange",
                  width: 12,
                  height: 12,
                }}
                onClick={() =>
                  handleUpdateProjectOpenPopup(params.row.projectID)
                }
              />
            </Tooltip>
          )}
        </div>
      ),
    },
  ].filter(Boolean);

  const getRowClassName = (params) => {
    const totalCost = reverseFormatNumberWithCommasAnd2Decimals(
      params.row.expenses
    );
    const maxBudget = reverseFormatNumberWithCommasAnd2Decimals(
      params.row.maxBudget
    );
    const minBudget = reverseFormatNumberWithCommasAnd2Decimals(
      params.row.minBudget
    );

    if (totalCost > maxBudget) {
      return "exceed-max-budget-row";
    } else if (totalCost > minBudget) {
      return "exceed-min-budget-row";
    }
    return "";
  };

  const filteredRows = rows.filter((row) => {
    const searchLowerCase = searchQuery.toLowerCase();
    const foundClient = Object.keys(clientData).find(
      (key) => Number(key) === row.clientID
    );
    const clientName = foundClient ? clientData[foundClient].toLowerCase() : "";

    const values = Object.values(row).map((value) =>
      String(value).toLowerCase()
    );
    const rowWithTotalCost = {
      ...row,
      totalCost: calculateTotalCostForProject(row.projectID),
    };

    const valuesWithTotalCost = Object.values(rowWithTotalCost).map((value) =>
      String(value).toLowerCase()
    );

    return (
      valuesWithTotalCost.some((value) => value.includes(searchLowerCase)) ||
      clientName.includes(searchLowerCase)
    );
  });

  const displayRows = searchQuery
    ? filteredRows.map((row) => {
        const clientName = clientData[row.clientID] || row.clientName;
        const totalCost = calculateTotalCostForProject(row.projectID);
        const isExceedingMaxBudget = totalCost > parseFloat(row.maxBudget);
        const isExceedingMinBudget = totalCost > parseFloat(row.minBudget);

        return {
          ...row,
          clientName,
          maxBudget: formatNumberWithCommasAnd2Decimals(row.maxBudget || 0),
          minBudget: formatNumberWithCommasAnd2Decimals(row.minBudget || 0),
          expenses: formatNumberWithCommasAnd2Decimals(totalCost || 0),
          isExceedingMaxBudget,
          isExceedingMinBudget,
        };
      })
    : rows.map((row) => {
        const clientName = clientData[row.clientID] || row.clientName;
        const totalCost = calculateTotalCostForProject(row.projectID);
        const isExceedingMaxBudget = totalCost > parseFloat(row.maxBudget);
        const isExceedingMinBudget = totalCost > parseFloat(row.minBudget);

        return {
          ...row,
          clientName,
          maxBudget: formatNumberWithCommasAnd2Decimals(row.maxBudget || 0),
          minBudget: formatNumberWithCommasAnd2Decimals(row.minBudget || 0),
          expenses: formatNumberWithCommasAnd2Decimals(totalCost || 0),
          isExceedingMaxBudget,
          isExceedingMinBudget,
        };
      });

  const offset = page * rowperpage;
  const rowPerPage = rowperpage;

  const displayRow = displayRows
    .slice(offset, offset + rowPerPage)
    .map((row, index) => ({
      ...row,
      id: index + 1,
    }));

  const projectButtonAccess = user.permissions.some(
    (permission) => permission === 1 || permission === 2
  );
  const updateAccess = user.permissions.some(
    (permission) => permission === 1 || permission === 4
  );
  const viewAccess = user.permissions.some(
    (permission) => permission === 1 || permission === 3
  );

  const getRowId = (row) => row.id;
  const getRowHeight = () => 39;
  return (
    <div className="projects h-[calc(100vh-50px)] p-4 overflow-y-auto">
      <div className="flex flex-col items-center justify-between text-xl font-bold text-gray-500 border-b-4 shadow-md tableTitle lg:flex-row rounded-5">
        <span className="p-1 font-serif">All Projects</span>
      </div>
      <div>
        <div className="w-full max-w-screen-xl overscroll-x-none mx-auto  shadow-md rounded-md">
          {loading && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "60%",
                transform: "translate(-50%, -50%)",
              }}
              className="bg-transparent z-50"
            >
              <Box sx={{ width: "100%" }}>
                <LinearProgress />
              </Box>
              <div className="mt-2">Loading...</div>
            </div>
          )}
          {loadingPage && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "60%",
                transform: "translate(-50%, -50%)",
              }}
              className="bg-transparent z-50"
            >
              <Box sx={{ width: "100%" }}>
                <LinearProgress />
              </Box>
              <div>Loading...</div>
            </div>
          )}

          <div className="flex items-center justify-between p-2">
            <TextField
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearch}
              className="border border-gray-300 rounded px-3 py-1  w-[50%]"
              inputProps={{ style: { padding: "5px", fontSize: "13px" } }}
            />
            {projectButtonAccess && (
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={handleAddProject}
                style={{ height: "30px", fontSize: "12px" }}
              >
                Add Project
              </Button>
            )}
          </div>
          <div style={{ height: "100%", width: "100%" }}>
            <DataGrid
              rows={displayRow}
              columns={columns.map((column) => ({
                ...column,
                headerClassName: "custom-header",
                cellClassName: "custom-cell",
              }))}
              pageSize={rowperpage}
              page={page}
              pagination={false}
              pageSizeOptions={[]}
              disablePrevButton={true}
              disableNextButton={true}
              hideFooter
              getRowId={getRowId}
              getRowHeight={getRowHeight}
              getRowClassName={getRowClassName}
            />
          </div>
        </div>
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={Math.ceil(rows.length / rowperpage)}
          pageRangeDisplayed={2}
          marginPagesDisplayed={1}
          onPageChange={handlechangepage}
          containerClassName="pagination"
          activeClassName="active"
        />
      </div>
      <ToastContainer />

      <AddNewPopup open={openPopup} setOpen={setOpenPopup} title="New Project">
        <New onClose={handleClosePopup} setSuccess={setSuccess} rows={rows} />{" "}
      </AddNewPopup>
      <UpdateProjectPopup
        open={openUpdatePopup}
        setOpen={setOpenUpdatePopup}
        title="Update Project"
      >
        <UpdateProject
          onClose={handleOpdateProjectClosePopup}
          project={selectedProject}
          setSuccess={setSuccess}
        />
      </UpdateProjectPopup>
      <ViewProjectPopup
        open={openViewPopup}
        setOpen={setOpenViewPopup}
        title="Project Informations"
      >
        <ViewProject
          onClose={handleViewProjectClosePopup}
          project={selectedProjectView}
        />
      </ViewProjectPopup>
    </div>
  );
};

export default Projects;
