import React from "react";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Popup = ({ open, setOpen, title, children }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle className="bg-[#111827] text-white p-10 text-center text-2xl font-bold font-serif">
        <span className="font-serif">{title}</span>
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default Popup;
